import styled from 'styled-components';
import { CodeBlock } from '@rainbow-modules/content';
import { RecordPrimaryDetails } from '@rainbow-modules/record';
import { color } from 'theme';
import { GREY_300, WHITE } from '../../../../../constants';

export const LogDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow: hidden;
    padding: 1rem 0;
`;

export const RequestResponseContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
    overflow: auto;
`;

export const Title = styled.h3`
    margin: 0 -0.5rem;
    padding: 0 1rem 0.25rem;
    font-size: 1.25rem;
    position: sticky;
    top: 0;
    background: ${WHITE};
    color: ${color('text.main')};
    z-index: 10;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const CodeContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledCodeBlock = styled(CodeBlock)`
    margin: 10px 10px;
`;

export const HeaderContainer = styled.span`
    display: flex;
    max-width: 100%;
    align-self: stretch;
    justify-content: stretch;
    padding: 0 1.5rem 0.5rem;
    gap: 1rem;
`;

export const HeaderTitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    gap: 0.5rem;
    display: flex;
    flex: 1;
    align-items: center;

    b {
        color: ${GREY_300};
    }

    span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const StyledRecordPrimaryDetails = styled(RecordPrimaryDetails)`
    margin: 0.5rem 1.5rem 1rem;
    padding: 0 0rem 0.5rem;
    border-radius: 0.5rem;
`;
