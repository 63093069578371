import useHttpMutation from 'data/firestore/useHttpMutation';
import { Customer } from 'data/firestore/agent/customer/types';

interface Context {
    customer: Customer;
    company: {
        name: string;
        address: string;
    };
    language: {
        tag: 'en' | 'es';
        inEnglish: 'English' | 'Spanish';
        inSpanish: 'Inglés' | 'Español';
    };
}

interface ReqBody {
    template: string;
    partialName?: string;
    context?: Context;
    customerId?: string;
}

export default (
    agentId?: string,
    responderId?: string,
) => useHttpMutation<ReqBody, { result: string }>({
    pathname: `/agents/${agentId}/responders/${responderId}/system-message/validate`,
    method: 'POST',
    server: process.env.REACT_APP_BASE_URL,
});
