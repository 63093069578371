import { StyledBadge, BadgeContainer } from './styled';
import getBadgeVariant from './helpers/getBadgeVariant';

const statusMap: Record<string, string> = {
    timed_out: 'TIMEOUT',
};

const ResponseStatusBadge = ({ value, className }: { value: string; className?: string; }) => {
    const status = statusMap[value] || parseInt(value, 10);
    const badgeVariant = getBadgeVariant(status);

    return (
        <BadgeContainer>
            <StyledBadge
                className={className}
                label={status}
                variant={badgeVariant}
                borderRadius="semi-rounded"
            />
        </BadgeContainer>
    );
};

export default ResponseStatusBadge;
