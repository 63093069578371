import styled, { css } from 'styled-components';
import Card from 'components/Card';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: calc(100vh - 161px);
`;

export const StyledCard = styled(Card)<{ marginBottom?: string }>`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: auto;
    box-sizing: border-box;

    ${(props) => props.marginBottom && css`
        margin-bottom: ${props.marginBottom};
    `};
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
    height: 45px;
`;

export const CardHeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.5rem;
    justify-content: stretch;
    gap: 1rem;
`;
