import styled from 'styled-components';
import { Textarea } from 'react-rainbow-components';
import { UniversalForm } from '@rainbow-modules/forms';
import DatePicker from 'components/DatePicker';
import GoogleAddressLookup from 'components/GoogleAddressLookup';
import Input from 'components/Input';
import EntityTagSelect from 'components/EntityTagSelect';
import { GREY_800, DIVIDER } from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const Content = styled.div`
    padding: 0 1.5rem 1.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    position: relative;
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: flex-end;
    padding: 2rem 1.5rem 1.5rem;
`;

export const StyledUniversalForm = styled(UniversalForm)`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    
`;

export const FormContainer = styled.div`
    box-sizing: border-box;
`;

export const FieldsSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    div[id^="error-message"] {
        align-self: start;
    }
`;

export const FieldSectionTitle = styled.h2`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3125rem;
    top: 0;
    z-index: 2;
    sticky: top;
    position: sticky;
    background: white;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: -0.5rem;
`;

export const FormFields = styled.fieldset`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    div[id^="error-message"] {
        align-self: start;
        margin-left: 1rem;
    }
`;

export const StyledInput = styled(Input)`
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    input {
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};

        ::placeholder {
            font-weight: 300 !important;
        }
    }
`;

export const StyledTextarea = styled(Textarea)`
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    > div {
        border: none;
        box-shadow: none;
    }

    textarea {
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};

        :focus, :active {
            border: 2px solid ${DIVIDER};
        }

        ::placeholder {
            font-weight: 300 !important;
        }
    }
`;

export const StyledDatePicker = styled(DatePicker)`
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    svg {
        color: ${GREY_800};
    }
`;

export const StyledGoogleAddressLookup = styled(GoogleAddressLookup)`
    
`;

export const StyledEntityTagSelect = styled(EntityTagSelect)`
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;
