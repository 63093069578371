import { useMemo } from 'react';
import { isEmpty } from '@rainbow-modules/validation';
import useSearchFilters from 'hooks/useSearchFilters';

const useFilters = () => {
    const {
        values,
        setValue: setFilterValue,
    } = useSearchFilters({ filterNames: ['search', 'skill', 'type'] });

    const skillFilterValue = useMemo(
        () => {
            const { skill: responderSkill } = values;
            if (!isEmpty(responderSkill)) return String(responderSkill).split(',');
            return null;
        },
        [values],
    );

    const typeFilterValue = useMemo(
        () => {
            const { type: responderType } = values;
            if (!isEmpty(responderType)) return String(responderType).split(',');
            return null;
        },
        [values],
    );

    return useMemo(() => ({
        searchQuery: values.search as string,
        skillFilter: skillFilterValue,
        typeFilter: typeFilterValue,
        changeFilter: (filterName: 'skill' | 'type' | 'search', newFilterValue: string[] | string | null) => {
            if (filterName === 'search') {
                return setFilterValue(filterName, newFilterValue as string | null);
            }

            return setFilterValue(
                filterName,
                (
                    isEmpty(newFilterValue)
                        ? null
                        : (newFilterValue as string[])?.join(',')
                ),
            );
        },
    }), [setFilterValue, skillFilterValue, typeFilterValue, values.search]);
};

export default useFilters;
