import { useCallback, useMemo } from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import Trash from 'components/icons/trash';
import ModalBody from 'components/ModalBody';
import { Webhooks } from 'data/firestore/webhooks/types';
import useWebhooksHttpMutation from './useWebhooksHttpMutation';
import { ADD_EDIT_WEBHOOK_DRAWER } from '../../../constants';

const mapDataToFormValues = (data: Webhooks) => ({
    ...data,
});

const useManageWebhooks = ({
    agentId,
    onWebhookCreated = () => {},
    onWebhookDeleted = () => {},
} : {
    agentId: string;
    onWebhookCreated?: (id: string) => void
    onWebhookDeleted?: () => void;
}) => {
    const [openForm, closeForm] = useOpenModal(ADD_EDIT_WEBHOOK_DRAWER);

    const { mutateAsync: create } = useWebhooksHttpMutation({
        pathname: '/universalwebhook/webhooks',
        group: agentId,
        method: 'POST',
        onSuccess: closeForm,
    });

    const { mutateAsync: update } = useWebhooksHttpMutation({
        group: agentId,
        method: 'PUT',
        onSuccess: closeForm,
    });

    const { mutateAsync: remove } = useWebhooksHttpMutation({
        group: agentId,
        method: 'DELETE',
    });

    const enableWebhook = useCallback(async (webhookId: string) => {
        showAppSpinner();
        try {
            await update({
                pathname: `/universalwebhook/webhooks/${webhookId}`,
                body: {
                    status: true,
                },
            });
            showAppNotification({
                title: 'Success',
                description: 'Webhook was successfully enabled.',
                icon: 'success',
                timeout: 5000,
            });
        } catch (error) {
            const message = (error as any)?.message || 'Something went wrong. Please try again.';
            showAppNotification({
                title: 'Error',
                description: message,
                icon: 'error',
                timeout: 5000,
            });
        }
        hideAppSpinner();
    }, [update]);

    const disableWebhook = useCallback(async (webhookId: string) => {
        const result = await confirmModal({
            header: '',
            variant: 'destructive',
            question: (
                <ModalBody
                    variant="destructive"
                    icon={<Trash />}
                    title="Disable Webhook?"
                    description="Once this action is confirmed, the webhook will be disabled inmediately."
                />
            ),
            borderRadius: 'semi-square',
            okButtonLabel: 'Disable',
            cancelButtonLabel: 'Cancel',
        });

        if (!result) {
            return;
        }

        showAppSpinner();
        try {
            await update({
                pathname: `/universalwebhook/webhooks/${webhookId}`,
                body: {
                    status: false,
                },
            });
            showAppNotification({
                title: 'Success',
                description: 'Webhook was successfully disabled.',
                icon: 'success',
                timeout: 5000,
            });
        } catch (error) {
            const message = (error as any)?.message || 'Something went wrong. Please try again.';
            showAppNotification({
                title: 'Error',
                description: message,
                icon: 'error',
                timeout: 5000,
            });
        }
        hideAppSpinner();
    }, [update]);

    const removeWebhook = useCallback(async (webhookId: string) => {
        const result = await confirmModal({
            header: '',
            variant: 'destructive',
            question: (
                <ModalBody
                    variant="destructive"
                    icon={<Trash />}
                    title="Remove Webhook?"
                    description="Once this action is confirmed, it cannot be undone."
                />
            ),
            borderRadius: 'semi-square',
            okButtonLabel: 'Remove',
            cancelButtonLabel: 'Cancel',
        });

        if (!result) {
            return;
        }

        showAppSpinner();
        try {
            await remove({
                pathname: `/universalwebhook/webhooks/${webhookId}`,
            });
            onWebhookDeleted();
            showAppNotification({
                title: 'Success',
                description: 'Webhook removed successfully.',
                icon: 'success',
                timeout: 5000,
            });
        } catch (error) {
            const message = (error as any)?.message || 'Something went wrong. Please try again.';
            showAppNotification({
                title: 'Error',
                description: message,
                icon: 'error',
                timeout: 5000,
            });
        }
        hideAppSpinner();
    }, [onWebhookDeleted, remove]);

    const createWebhook = useCallback(
        (initialValues: Record<string, unknown> = {}) => openForm({
            header: 'Create Webhook',
            submitButtonLabel: 'Create',
            initialValues: {
                ...initialValues,
            },
            onSubmit: async (values: Record<string, unknown>) => {
                showAppSpinner();
                try {
                    const { id } = await create({
                        body: values,
                    }) as Webhooks;
                    showAppNotification({
                        title: 'Success',
                        description: 'Webhook added successfuly.',
                        icon: 'success',
                        timeout: 5000,
                    });
                    onWebhookCreated(id);
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [create, onWebhookCreated, openForm],
    );

    const editWebhook = useCallback((webhook: Webhooks) => openForm({
        header: 'Edit Webhook',
        mode: 'edit',
        submitButtonLabel: 'Update',
        initialValues: mapDataToFormValues(webhook),
        onSubmit: async (values: Record<string, unknown>) => {
            showAppSpinner();
            try {
                await update({
                    pathname: `/universalwebhook/webhooks/${webhook.id}`,
                    body: values,
                });
                showAppNotification({
                    title: 'Success',
                    description: 'Webhook updated successfully.',
                    icon: 'success',
                    timeout: 5000,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
    }), [openForm, update]);

    return useMemo(() => ({
        create: createWebhook,
        edit: editWebhook,
        enable: enableWebhook,
        disable: disableWebhook,
        remove: removeWebhook,
    }), [createWebhook, disableWebhook, editWebhook, enableWebhook, removeWebhook]);
};

export default useManageWebhooks;
