import { useParams } from 'react-router-dom';
import { EntityGet } from 'data/firestore/types';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import usePipeline from 'data/firestore/agent/pipeline/use';
import usePipelineStage from 'data/firestore/agent/pipeline/stage/use';
import { CellWrapper } from 'pages/Customers/CustomerDetails/CustomerFiles/columns/styled';
import getStageTypeColor from 'pages/Opportunities/helpers/getStageColor';
import { PipelineLabel, StyledBadge } from './styled';

interface Props {
    row?: EntityGet<Opportunity>;
}

const StageColumn = ({ row }: Props) => {
    const { agentId = '' } = useParams();
    const { pipelineId = '', stageId = '' } = row || {};
    const { data: pipeline } = usePipeline(agentId, pipelineId, {
        disabled: !agentId || !pipelineId,
    });
    const { data: stage } = usePipelineStage(agentId, pipelineId, stageId, {
        disabled: !agentId || !pipelineId || !stageId,
    });
    return (
        <CellWrapper>
            <div className="rainbow-flex__column">
                <PipelineLabel>{pipeline?.name}</PipelineLabel>
                <StyledBadge label={stage?.name} color={getStageTypeColor(stage?.type)} />
            </div>
        </CellWrapper>
    );
};

export default StageColumn;
