import { useMutation } from 'react-query';
import { signInWithCustomToken } from 'firebase/auth';
import { Method } from 'data/firestore/types';
import { auth } from 'data/firestore/firebase';
import { QueryError, getQueryErrorCode } from 'data/firestore/errors';
import { auth as webhooksAuth } from '../data/firebase';

export interface UseMutationParams<Res> {
    pathname?: string;
    method?: Method;
    server?: string;
    group: string;
    onSuccess?: (data: Res) => void;
    onError?: (error: Error) => void;
}

export interface MutationParams<Req, Res>
    extends Pick<UseMutationParams<Res>, 'pathname'> {
    body?: Req;
}

const getCustomToken = async ({
    idToken,
    group,
}: Record<string, string>) => {
    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/webhook/auth`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
            group,
        }),
    });
    if (res.ok) {
        try {
            const json = await res.json();
            return json.token;
        } catch (err) {
            // noop
        }
    }
    return undefined;
};

const createRequestHeaders = async (group: string) => {
    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
    };
    if (!webhooksAuth.currentUser) {
        if (auth.currentUser) {
            const idToken = await auth.currentUser.getIdToken(true);
            const customToken = await getCustomToken({ idToken, group });
            await signInWithCustomToken(webhooksAuth, customToken);
        }
    }
    if (webhooksAuth.currentUser) {
        const webhooksIdToken = await webhooksAuth.currentUser.getIdToken(true);
        headers.Authorization = `Bearer ${webhooksIdToken}`;
    }
    return headers;
};

const UNIVERSAL_WEBHOOK_FUNCTION_TRIGGER = process.env.REACT_APP_UNIVERSAL_WEBHOOK_FUNCTION_TRIGGER;

const useWebhooksHttpMutation = <Req, Res>(params: UseMutationParams<Res>) => {
    const {
        pathname: hookPathname,
        method = 'POST',
        server = UNIVERSAL_WEBHOOK_FUNCTION_TRIGGER,
        group,
        onSuccess = () => {},
        onError = () => {},
    } = params;
    return useMutation<Res, QueryError, MutationParams<Req, Res>, unknown>(
        async (mParams: MutationParams<Req, Res>): Promise<Res> => {
            const { body, pathname: mutationPathname } = mParams;
            const pathname = mutationPathname || hookPathname;
            if (!pathname) {
                throw new Error('No pathname provided');
            }
            const url = new URL(pathname, server).toString();
            const response = await fetch(url, {
                method,
                headers: await createRequestHeaders(group),
                body: body ? JSON.stringify(body) : null,
            });
            const contentType = response.headers.get('Content-Type');
            if (response.ok) {
                if (contentType?.includes('application/json')) return response.json() as Promise<Res>;
                return response.text() as Promise<Res>;
            }
            if (contentType?.includes('text/html')) {
                const result = await response.text();
                throw new QueryError(getQueryErrorCode(response.status), result);
            }
            const result = await response.json();
            throw result;
        },
        {
            onSuccess,
            onError,
        },
    );
};

export default useWebhooksHttpMutation;
