import {
  collection,
  onSnapshot,
  FirestoreError,
  query,
  Unsubscribe,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { EntityGet, EntityServerGet, ListServiceOpts } from "../../../types";
import { Templatepartial } from "./types";
import { transformMetadata, processTimestampFields } from "../../../utils";

export type TemplatepartialOnSnapshotCollectionService<T> = (
  agentId: string,
  responderId: string,
  opts: ListServiceOpts,
  callback: (docs: Array<T>) => void,
  onError?: (error: FirestoreError) => void
) => Unsubscribe;

const onSnapshotCollection: TemplatepartialOnSnapshotCollectionService<
  EntityGet<Templatepartial>
> = (
  agentId,
  responderId,
  opts: ListServiceOpts,
  callback,
  onError = () => {}
) => {
  const collectionRef = collection(
    db,
    `/agent/${agentId}/responder/${responderId}/templatepartial`
  );
  const { disableCache } = opts;
  const q = opts.listQueryFn
    ? opts.listQueryFn(collectionRef)
    : query(collectionRef);
  return onSnapshot(
    q,
    { includeMetadataChanges: disableCache },
    (querySnap) => {
      const { metadata } = querySnap;
      const { fromCache } = metadata;
      if (disableCache && fromCache) return;
      callback(
        querySnap.docs.map(
          (doc) =>
            processTimestampFields(
              transformMetadata(
                {
                  ...doc.data(),
                  id: doc.id,
                } as EntityServerGet<Templatepartial>,
                doc.metadata
              )
            ) as EntityGet<Templatepartial>
        )
      );
    },
    onError
  );
};

export default onSnapshotCollection;
