import LoadingShape from 'components/LoadingShape';
import { Content } from './styled';

const Loading = () => (
    <Content>
        <div className="rainbow-flex-column">
            <LoadingShape width="50%" height="1rem" />
            <LoadingShape width="30%" height="1rem" />
            <LoadingShape width="60%" height="1rem" />
        </div>
    </Content>
);

export default Loading;
