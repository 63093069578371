import styled from 'styled-components';

export const MemoriesWrapper = styled.div`
    width: 100%;
    overflow: auto;
    
`;
export const MemoryCard = styled.div`
  background-color: #ffffff;
  padding: 16px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border-top: 1px solid #e6e6e6;
  &:hover {
    background-color: #f7f9fc;
  }
`;

export const MemoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const TypeBadge = styled.span<{ color: string }>`
  background-color: ${(props) => props.color};
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 4px;
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 12px;
  border-radius: 10px;
`;

export const ImportanceBadge = styled.span`
  background-color:rgb(238, 238, 238);
  color: #333;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 10px;
  margin-left: 8px;
`;

export const MemoryContent = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 12px;
  color: #333;
`;

export const MemoryFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  font-size: 12px;
  color: #666;
`;

export const FooterItem = styled.div`
  display: flex;
  align-items: center;
`;

export const SegmentTag = styled.span`
  background-color: #e6f7ff;
  color: #1890ff;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
`;

export const TimeStamp = styled.span`
  color: #999;
  font-size: 12px;
`;

export const SourceBadge = styled.span<{ isExternal: boolean }>`
  background-color: ${(props) => (props.isExternal ? '#87d068' : '#b7eb8f')};
  color: ${(props) => (props.isExternal ? 'white' : '#333')};
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  text-transform: capitalize;
`;
