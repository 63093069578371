import styled from 'styled-components';
import { Spinner } from 'react-rainbow-components';
import Button from 'components/Button';
import { GREY_400 } from '../../../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    overflow: hidden;
    height: 100%;
`;

export const Instructions = styled.pre`
    padding: 0 1.5rem;
    margin: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_400};
    white-space: preserve-breaks;
`;

export const JsonView = styled.div`
    padding: 0 1.5rem;
    margin: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    align-self: stretch;
    overflow: auto;
    color: ${GREY_400};
    flex: 1;
`;

export const TopBar = styled.div`
    display: flex;
    position: relative;
    align-self: stretch;
    padding: 0.25rem 2rem 1rem;
    align-items: flex-end;
    justify-content: flex-end;
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    -moz-box-align: stretch;
    align-items: stretch;
    overflow: auto;
    height: 100%;
    gap: 1rem;
    padding: 0.75rem 2.25rem 0px;
`;

export const Footer = styled.div<{ align?: string; }>`
    display: flex;
    padding: 0.5rem 1.25rem 0.5rem 2.25rem;
    justify-content: ${(props) => props.align || 'flex-start'};
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
`;

export const StyledSpinner = styled(Spinner)`
    position: unset;
    z-index: unset;
    transform: unset;
    left: unset;
    top: unset;
    width: 1rem;
    height: 1rem;
`;

export const Row = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-start;
    gap: 1rem;
    margin: 0 0 1.5rem;
    label { margin: 0}
`;

export const CollapsedRow = styled(Row)`
    flex: 0;
`;

export const FieldLabel = styled.div`
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem;
    padding: 0;
`;

export const CenteredButton = styled(Button)`
    flex: 0;
    align-self: center;
    margin: auto;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0 1.5rem;
    align-self: stretch;
    flex: 1;
`;

export const LoadingContainer = styled.p`
    padding: 0.25rem 1.25rem 1.25rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
`;

export const ButtonsContainer = styled.div`
    padding: 0 2.25rem 0.75rem;
`;
