import styled from 'styled-components';
import { Caution } from '@rainbow-modules/icons';
import { AMERICAN_YELLOW } from '../../../../constants';

export const ModalContainer = styled.div`
    background-color: ${AMERICAN_YELLOW}12;
    color: ${AMERICAN_YELLOW};
    border-radius: 10px;
    padding: 0.625rem 1.25rem;
    display: flex;
    align-items: center;

    & > div p {
        text-align left;
    }
`;

export const Highlight = styled.span`
    font-family: 'Lato Black', Arial, Helvetica, sans-serif;
    padding: 0 4px;
`;

export const StyledWarningIcon = styled(Caution)`
    width: 50px;
    height: 50px;
    margin-right: 10px;
`;
