import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Stage } from 'data/firestore/agent/pipeline/stage/types';
import getStageTypeColor from '../../helpers/getStageColor';
import { ViewContextType } from '../../types';
import { Column, OpportunityCard } from './types';
import {
    StageCardsCount, StageColor, StageInfoContainer, StageTitle,
} from './styled';

const StageInfo = ({
    title,
    column,
    cards,
}: {
    title?: string;
    column: Column;
    cards: OpportunityCard[],
}) => {
    const { type } = column.data || {};

    const { activeFiltersCount, searchQuery } = useOutletContext<ViewContextType>();
    const isFilterMode = (activeFiltersCount && activeFiltersCount > 0) || Boolean(searchQuery);

    const color = useMemo(
        () => getStageTypeColor(type),
        [type],
    );

    const label = useMemo(() => {
        if (isFilterMode) {
            return cards.length < 100 ? cards.length : '99+';
        }
        const { totalOpportunities = 0 } = column?.data || {} as Stage;
        return Math.max(cards.length, totalOpportunities);
    }, [cards.length, column?.data, isFilterMode]);

    return (
        <StageInfoContainer>
            <StageColor color={color} />
            <StageTitle isBuiltin={!column.data?.removable}>{title}</StageTitle>
            <StageCardsCount label={label} />
        </StageInfoContainer>
    );
};

export default StageInfo;
