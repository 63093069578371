import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Badge from 'components/Badge';
import { Virtuoso } from 'react-virtuoso';
import { color } from 'theme';
import {
    GREY_400,
    WHITE,
    GREY_500,
    GREY_300,
    GREY_600,
    GREY_100,
    PURPLE_800,
    PURPLE_100,
    DIVIDER,
} from '../../constants';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 0 1rem 0;
    gap: 0;
    height: 100%;
    min-height: 300px;
`;

export const MessageContainer = styled.div<{ isCurrentUser: boolean }>`
    display: flex;
    padding-top: 0.75rem;
    padding-bottom: 2rem;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;

    ${(props) => props.role === 'agent' && css`
        gap: 0.6875rem;
    `}

    ${(props) => props.isCurrentUser === true && css`
        flex-direction: row-reverse;
        padding-right: 1.5rem;
    `}

    ${(props) => props.isCurrentUser === false && css`
        padding-left: 1.5rem;
    `}
`;

export const MessageBody = styled.div<{ isCurrentUser: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    gap: 0.5rem;
`;

export const EmptyMessageContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    gap: 0.5rem;
`;

export const EmptyMessage = styled.p`
    font-size: 0.9375rem;
    color: ${GREY_400};
    font-weight: 300;
`;

export const StyledLink = styled(Link)`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${color('brand.main')};

    &:hover {
        color: ${color('brand.dark')};
    }
`;

export const LabelIcon = styled.div<{ color?: string }>`
    display: flex;
    align-items: flex-end;

    svg {
        width: 1.35rem;
        height: auto;
        padding-left: 0.125rem;
    }

    ${(props) => props.color && css`
        color: ${props.color};
    `};
`;

export const Icon = styled.div`
    display: flex;

    svg {
        width: 1.5rem;
        height: 1.5rem;
        color: ${GREY_300};
    }
`;

export const Name = styled.span`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    white-space: nowrap;
    color: ${GREY_400};
`;

export const SecondaryText = styled.span`
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 1.5rem;
    display: flex;
    gap: 3px;
    align-items: center;
    color: ${GREY_400};
`;

export const ActivityTitle = styled.div<{ isCurrentUser: boolean }>`
    display: flex;
    gap: 0.5rem;
    color: ${GREY_500};
    width: 100%;

    ${(props) => props.isCurrentUser === true && css`
        justify-content: flex-end;
    `}
`;

export const ChatBubble = styled.div<{ isCurrentUser: boolean }>`
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0rem 0.75rem 0.75rem 0.75rem;
    background: ${GREY_100};
    position: relative;
    color: ${GREY_600};
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;

    ${(props) => props.role === 'agent' && css`
        background: ${PURPLE_100};
        color: ${PURPLE_800};
        margin-right: 5rem;

        ::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.75rem 0.785rem 0;
            border-color: ${PURPLE_100} transparent transparent transparent;
            left: 0;
            top: 0;
            transform: translateX(-50%);
    `}

    ${(props) => props.isCurrentUser === false && css`
        margin-right: 5rem;

        ::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.75rem 0.785rem 0;
            border-color: ${GREY_100} transparent transparent transparent;
            left: 0;
            top: 0;
            transform: translateX(-50%);
        `}

    ${(props) => props.isCurrentUser === true && css`
        border-radius: 0.75rem 0rem 0.75rem 0.75rem;
        margin-left: 5rem;

        ::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.75rem 0.785rem 0;
            border-color: ${GREY_100} transparent transparent transparent;
            top: 0;
            right: 0;
            transform: translateX(50%);
    `}
`;

export const EventContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
`;

export const MessageFooterContainer = styled.div<{ isCurrentUser: boolean }>`
    display: flex;
    align-items: center;
    margin-top: -0.5rem;
    
    ${(props) => props.isCurrentUser === false && css`
        margin-right: 5rem;
        align-self: flex-start;
    `}

    ${(props) => props.isCurrentUser === true && css`
        margin-left: 5rem;
        align-self: flex-end;
    `}
`;

export const StyledVirtuoso = styled(Virtuoso)`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
`;

export const MessageWithDateContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Space = styled.div`
    height: 12px;
`;

export const StyledBadge = styled(Badge)`
    background-color: ${WHITE};
    text-transform: none;
    align-self: center;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 0.25rem;
    border: 1px solid ${DIVIDER};
    display: flex;
    padding: 0.3125rem 0.625rem;
    align-items: center;
    gap: 0.625rem;
    font-family: "Public Sans";

    & > span {
        font-size: 0.8125rem;
        line-height: 0.875rem;
        font-weight: normal;
    }
`;
