import {
    forwardRef, ReactNode, Ref, useCallback, useMemo,
    useState,
} from 'react';
import { DateTime } from 'luxon';
import MessageLoadingShape from 'components/MessageLoadingShape';
import { EntityGet } from 'data/firestore/types';
import { Chat } from 'data/firestore/agent/customer/chat/types';
import { Components, ListRange, VirtuosoHandle } from 'react-virtuoso';
import { CUSTOMER_ACTIVITY_PAGE_SIZE } from '../../constants';
import useGroupMessages from './hooks/useGroupMessages';
import { DisplayContext } from './types';
import {
    Container, EmptyMessage, EmptyMessageContainer,
    MessageWithDateContainer,
    Space,
    StyledVirtuoso,
} from './styled';
import Message from './message';
import DateBadge, { StyledDateBadge } from './dateBadge';

const VirtuosoComponents: Components = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Scroller: forwardRef((props, ref) => (
        // eslint-disable-next-line react/forbid-dom-props
        <div ref={ref} {...props} style={{ height: '100%' }} />
    )),
};

function formatDate(dateStr?: string, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
    const today = DateTime.now().setZone(timezone, { keepLocalTime: true });
    const yesterday = today.minus({ day: 1 });
    const date = DateTime.fromISO(dateStr as string).setZone(timezone);

    if (date.hasSame(today, 'day')) return 'Today';

    if (date.hasSame(yesterday, 'day')) return 'Yesterday';

    if (date.hasSame(today, 'year')) return date.toFormat('cccc, MMMM d');

    return date.toFormat('cccc, MMMM d, kkkk');
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const virtuosoItemContent = (_, message) => {
    const { showGroupDate, createdAt } = message;
    if (showGroupDate) {
        return (
            <MessageWithDateContainer key={message.id}>
                <Space />
                <DateBadge label={formatDate(createdAt.toISOString())} />
                <Space />
                <Message
                    key={`message-${message.id}`}
                    message={message}
                />
            </MessageWithDateContainer>
        );
    }
    return (
        <Message
            key={`message-${message.id}`}
            message={message}
        />
    );
};

interface CustomerActivityProps {
    messages?: EntityGet<Chat>[],
    isLoading?: boolean;
    emptyMessage?: ReactNode | string;
    agentId?: string;
    context?: DisplayContext;
}

const CustomerActivity = forwardRef(
    ({
        messages = [],
        isLoading = false,
        emptyMessage = 'There is no activity',
        agentId = '',
        context = 'customer',
    }: CustomerActivityProps, ref: Ref<VirtuosoHandle> | undefined) => {
        const [isScrolling, setScrolling] = useState(false);
        const [firstItemDate, setFirstItemDate] = useState<Date | null>();
        const groupedMessages = useGroupMessages({ agentId, messages, context });

        const handleRangeChanged = useCallback(
            (range: ListRange) => setFirstItemDate(messages[range.startIndex]?.createdAt),
            [messages],
        );

        const listEmptyMessage = useMemo(() => {
            if (typeof emptyMessage === 'string') {
                return (
                    <EmptyMessageContainer>
                        <EmptyMessage>{emptyMessage}</EmptyMessage>
                    </EmptyMessageContainer>
                );
            }

            return emptyMessage;
        }, [emptyMessage]);

        if (isLoading) {
            return (
                <Container>
                    <MessageLoadingShape />
                    <MessageLoadingShape />
                </Container>
            );
        }

        if (messages.length === 0) {
            return (
                <Container>{listEmptyMessage}</Container>
            );
        }

        return (
            <Container>
                <StyledVirtuoso
                    ref={ref}
                    data={groupedMessages}
                    initialTopMostItemIndex={CUSTOMER_ACTIVITY_PAGE_SIZE - 1}
                    itemContent={virtuosoItemContent}
                    rangeChanged={handleRangeChanged}
                    isScrolling={setScrolling}
                    followOutput="smooth"
                    totalCount={messages.length}
                    components={VirtuosoComponents}
                />
                <StyledDateBadge
                    label={formatDate(firstItemDate?.toISOString())}
                    isScrolling={isScrolling}
                />
            </Container>
        );
    },
);

export default CustomerActivity;
