import { DateTime } from 'luxon';
import tryParseNumber from 'data/services/string/parseNumber';

const buildAlgoliaFilters = ({
    agentId,
    pipeline,
    stage,
    filters = {},
}: {
    agentId: string;
    pipeline: string;
    stage?: string;
    filters?: Record<string, string | string[] | null>;
}) => {
    const filtersApplied: string[] = [];

    filtersApplied.push(`agentId:${agentId}`);
    filtersApplied.push(`pipelineId:${pipeline}`);
    filtersApplied.push('removed:false');

    if (stage) {
        filtersApplied.push(`stageId:${stage}`);
    }

    if (filters.owner) {
        const ownerIds = (filters.owner as string).split(',').map((id) => id.trim());
        if (ownerIds.length) {
            const ownerFilter = ownerIds.map((ownerId) => `owner.uid:${ownerId}`).join(' OR ');
            filtersApplied.push(ownerIds.length > 1 ? `(${ownerFilter})` : `${ownerFilter}`);
        }
    }

    if (!stage && filters.stage) {
        const stagesIds = (filters.stage as string).split(',').map((id) => id.trim());
        if (stagesIds.length) {
            const stagesFilter = stagesIds.map((stageId) => `stageId:${stageId}`).join(' OR ');
            filtersApplied.push(stagesFilter.length > 1 ? `(${stagesFilter})` : `${stagesFilter}`);
        }
    }

    if (filters.createdAt) {
        const [start, end] = (filters.createdAt as string).split(',').map((date) => DateTime.fromISO(date).toMillis());
        filtersApplied.push(`createdAt:${start} TO ${end}`);
    }

    if (filters.amount) {
        const [min, max] = (filters.amount as string).split(',').map((numericValue) => tryParseNumber(numericValue));
        if (min) {
            filtersApplied.push(`amount>=${min}`);
        }
        if (max) {
            filtersApplied.push(`amount<=${max}`);
        }
    }
    if (filters.score) {
        const conditions: string[] = [];
        const scores = (filters.score as string).split(',').map((score) => score.trim());

        if (scores.includes('high')) {
            conditions.push('score>=70');
        }

        if (scores.includes('medium')) {
            conditions.push('score>=40 AND score<70');
        }

        if (scores.includes('low')) {
            conditions.push('score<40 AND score != null');
        }

        if (scores.includes('not-scored')) {
            conditions.push('score:null');
        }

        if (conditions.length > 0) {
            filtersApplied.push(`(${conditions.join(' OR ')})`);
        }
    }

    return filtersApplied.join(' AND ');
};

export default buildAlgoliaFilters;
