import { useOpenModal } from '@rainbow-modules/hooks';
import { RenderIf } from 'react-rainbow-components';
import { EntityGet } from 'data/firestore/types';
import { Logs } from 'data/firestore/webhooks/logs/types';
import { WEBHOOK_LOG_DETAILS_DRAWER } from '../../../../../constants';
import {
    CellWrapper, UrlContainer, UrlLink,
} from './styled';
import RedeliveredLabel from '../LogDetails/redeliveredLabel';

interface Props {
    row?: EntityGet<Logs>;
}

const EndpointColumn = ({ row }: Props) => {
    const [openLogDetailsDrawer] = useOpenModal(WEBHOOK_LOG_DETAILS_DRAWER);
    return (
        <CellWrapper>
            <UrlContainer>
                <UrlLink onClick={() => openLogDetailsDrawer({ logId: row?.id })}>
                    <b>POST</b>
                    {row?.endpoint}
                </UrlLink>
                <RenderIf isTrue={row?.redelivered}>
                    <RedeliveredLabel value="Redelivered" />
                </RenderIf>
            </UrlContainer>
        </CellWrapper>
    );
};
export default EndpointColumn;
