import {
  collection,
  onSnapshot,
  FirestoreError,
  query,
  Unsubscribe,
} from "firebase/firestore";
import { db } from "../firebase";
import { EntityGet, EntityServerGet, ListServiceOpts } from "../types";
import { Webhooks } from "./types";
import { transformMetadata, processTimestampFields } from "../utils";

export type WebhooksOnSnapshotCollectionService<T> = (
  opts: ListServiceOpts,
  callback: (docs: Array<T>) => void,
  onError?: (error: FirestoreError) => void
) => Unsubscribe;

const onSnapshotCollection: WebhooksOnSnapshotCollectionService<
  EntityGet<Webhooks>
> = (opts: ListServiceOpts, callback, onError = () => {}) => {
  const collectionRef = collection(db, `/webhooks`);
  const { disableCache } = opts;
  const q = opts.listQueryFn
    ? opts.listQueryFn(collectionRef)
    : query(collectionRef);
  return onSnapshot(
    q,
    { includeMetadataChanges: disableCache },
    (querySnap) => {
      const { metadata } = querySnap;
      const { fromCache } = metadata;
      if (disableCache && fromCache) return;
      callback(
        querySnap.docs.map(
          (doc) =>
            processTimestampFields(
              transformMetadata(
                {
                  ...doc.data(),
                  id: doc.id,
                } as EntityServerGet<Webhooks>,
                doc.metadata
              )
            ) as EntityGet<Webhooks>
        )
      );
    },
    onError
  );
};

export default onSnapshotCollection;
