import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import StatCard from 'components/StatCard';
import { Spinner } from 'react-rainbow-components';
import Memories from './Memories';
import {
    ContentArea as ContentContainer,
    StatsContainer,
    ChartsRow,
    ChartContainer,
    MemoryListContainer,
    SectionTitle,
    CenteredContent,
    EmptyStateContainer,
    EmptyStateIcon,
    EmptyStateTitle,
    EmptyStateMessage,
    StyledBrainIcon,
    MemoriesSectionTitle,
    LoadingText,
} from '../styled';
import {
    Memory, MemoryFilter,
    TYPE_COLORS, TYPE_LABELS,
} from '../types';

interface ContentAreaProps {
    memories: Memory[];
    isLoading: boolean;
    selectedCustomer: string | null;
    totalMemories?: number;
    filteredMemories?: number;
}

const ContentArea: React.FC<ContentAreaProps> = ({
    memories, isLoading, selectedCustomer,
    totalMemories = 0, filteredMemories = 0,
}) => {
    if (!selectedCustomer) {
        return (
            <ContentContainer>
                <CenteredContent>
                    <EmptyStateContainer>
                        <EmptyStateIcon>
                            <StyledBrainIcon />
                        </EmptyStateIcon>
                        <EmptyStateTitle>Welcome to Memory Management</EmptyStateTitle>
                        <EmptyStateMessage>
                            This tool helps you explore and analyze customer memory data.
                            Select a customer from the sidebar to view their memory records,
                            apply filters, and use semantic search to find relevant information.
                        </EmptyStateMessage>
                    </EmptyStateContainer>
                </CenteredContent>
            </ContentContainer>
        );
    }

    if (isLoading) {
        return (
            <ContentContainer>
                <CenteredContent>
                    <Spinner variant="brand" type="arc" size="large" />
                    <LoadingText>Fetching memories</LoadingText>
                </CenteredContent>
            </ContentContainer>
        );
    }

    // Compute stats from memories
    const stats = {
        totalMemories: totalMemories || memories.length,
        filteredMemories: filteredMemories || memories.length,
        filterRatio: totalMemories
            ? (filteredMemories / totalMemories)
            : 1,
    };

    // Compute type distribution from memories array
    const typeDistribution = memories.reduce<Record<string, number>>((acc, memory) => {
        const { type } = memory;
        acc[type] = (acc[type] || 0) + 1;
        return acc;
    }, {});

    // Convert to array format needed for the chart
    const typeData = Object.entries(typeDistribution).map(([type, count]) => ({
        type,
        count,
    }));

    // Compute segment distribution from memories array
    const segmentDistribution = memories.reduce<Record<string, number>>((acc, memory) => {
        const segment = memory.segment || 'Unknown';
        acc[segment] = (acc[segment] || 0) + 1;
        return acc;
    }, {});

    // Convert to array format needed for the chart
    const segmentData = Object.entries(segmentDistribution).map(([segment, count]) => ({
        segment,
        count,
    }));

    return (
        <ContentContainer>
            <StatsContainer>
                <StatCard
                    title="Total Memories"
                    subtitle="Total Memories"
                    value={`${stats.totalMemories.toLocaleString()}`}
                />
                <StatCard
                    title="Filtered Memories"
                    subtitle="Filtered Memories"
                    value={`${stats.filteredMemories.toLocaleString()}`}
                />
                <StatCard
                    title="Filter Ratio"
                    subtitle="Filter Ratio"
                    value={
                        stats.totalMemories > 0
                            ? `${Math.round((stats.filteredMemories / stats.totalMemories) * 100)}%`
                            : '0%'
                    }
                />
            </StatsContainer>

            <ChartsRow>
                <ChartContainer>
                    <SectionTitle>Memory Type Distribution</SectionTitle>
                    <ResponsiveBar
                        data={typeData}
                        keys={['count']}
                        indexBy="type"
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 55,
                            left: 20,
                        }}
                        padding={0.3}
                        colors={({ data }) => TYPE_COLORS[data.type as MemoryFilter['types'][number]]}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            format: (value) => TYPE_LABELS[value as MemoryFilter['types'][number]],
                            legend: '',
                        }}
                        axisLeft={null}
                        enableGridY
                        gridYValues={5}
                        enableTotals
                        enableLabel={false}
                        borderRadius={4}
                        animate
                    />
                </ChartContainer>
                <ChartContainer>
                    <SectionTitle>Memory Segment Distribution</SectionTitle>
                    <ResponsiveBar
                        data={segmentData}
                        keys={['count']}
                        indexBy="segment"
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 55,
                            left: 20,
                        }}
                        padding={0.3}
                        colors="#5856d6"
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisBottom={{
                            tickSize: 0,
                            tickPadding: 5,
                            tickRotation: -45,
                            legend: '',
                            format: () => '',
                        }}
                        axisLeft={null}
                        enableGridY
                        gridYValues={5}
                        enableTotals
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        borderRadius={4}
                        enableLabel={false}
                        animate
                    />
                </ChartContainer>
            </ChartsRow>

            <MemoryListContainer>
                <MemoriesSectionTitle>
                    Filtered Memories (
                    {memories.length}
                    )
                </MemoriesSectionTitle>
                <Memories memories={memories} />
            </MemoryListContainer>
        </ContentContainer>
    );
};

export default ContentArea;
