import { useCallback, useMemo } from 'react';
import { Outlet, useMatch, useParams } from 'react-router-dom';
import { ButtonOption } from 'react-rainbow-components';
import Button from 'components/Button';
import ButtonIcon from 'components/ButtonIcon';
import CreateEditOpportunityForm from 'components/CreateEditOpportunityForm';
import Insights from 'components/icons/insight';
import SearchIcon from 'components/icons/search';
import BoardIcon from 'components/icons/board';
import TableViewIcon from 'components/icons/tableView';
import Plus from 'components/icons/plus';
import SortByPicker from 'components/SortByPIcker';
import PipelineSwitcher from 'components/PipelineSwitcher';
import useStoredSort from 'hooks/useStoredSort';
import usePipelines from 'data/firestore/agent/pipeline/useCollection';
import usePipelineStages from 'data/firestore/agent/pipeline/stage/useCollection';
import { Stage } from 'data/firestore/agent/pipeline/stage/types';
import { EntityGet } from 'data/firestore/types';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import useSearchFilters from 'hooks/useSearchFilters';
import useUserRoles from 'hooks/useUserRoles';
import { ViewContextType } from '../types';
import { DEFAULT_SORT_OPPORTUNITIES } from './contants';
import {
    Container,
    StyledCard,
    SearchContainer,
    SearchInput,
    Content,
    StyledBadgeOverlay,
    StyledButtonGroupPicker,
    RightContent,
} from './styled';
import useManageOpportunities from '../../../data/hooks/useManageOpportunities';
import FilterButton from './filterButton';

const dataSearchFilters = ['search', 'owner', 'score', 'stage', 'createdAt', 'amount'];

const sortFields = [
    { name: 'score', label: 'Score' },
    { name: 'createdAt', label: 'Create Date' },
    { name: 'updatedAt', label: 'Last Updated' },
];

const OpportunitiesViewPage = () => {
    const { agentId = '', pipelineId: activePipelineId = '' } = useParams();
    const { isAdmin } = useUserRoles();
    const navigate = useNavigateWithQuery();
    const match = useMatch(':agentId/opportunities/:pipeline/view/:viewType');

    const [sort, setSort] = useStoredSort('opportunities', DEFAULT_SORT_OPPORTUNITIES);

    const {
        values: filterValues,
        getValue: getFilterValue,
        setValue: setFilterValue,
    } = useSearchFilters({
        filterNames: dataSearchFilters,
    });

    const activeFiltersCount = useMemo(
        () => dataSearchFilters.reduce(
            (count, key) => {
                if (key === 'search') return count;
                return (
                    getFilterValue(key) !== null
                        ? count + 1
                        : count
                );
            },
            0,
        ),
        [getFilterValue],
    );

    const { create: createNewOpportunity } = useManageOpportunities({ agentId });

    const {
        data: pipelines = [],
        isLoading: isLoadingPipelines,
    } = usePipelines(
        agentId,
        {
            disabled: !agentId,
            track: [agentId],
        },
    );

    const {
        data: pipelineStages = [],
        isLoading: isLoadingPipelineStages,
    } = usePipelineStages(
        agentId,
        activePipelineId,
        {
            disabled: !agentId || !activePipelineId,
            track: [activePipelineId],
        },
    );

    const orderedPipelineStages = useMemo(
        () => {
            const pipelineStageOrder = pipelines.find(
                // eslint-disable-next-line @typescript-eslint/no-shadow
                (pipeline) => pipeline.id === activePipelineId,
            )?.stages || [];

            return pipelineStageOrder.reduce(
                (activeStages: EntityGet<Stage>[], stageId: string) => {
                    const found = pipelineStages.find((stage) => stage.id === stageId);
                    if (found) {
                        return [...activeStages, found];
                    }

                    return activeStages;
                },
                [],
            );
        },
        [activePipelineId, pipelineStages, pipelines],
    );

    const handleAddNewOpportunity = useCallback(
        () => createNewOpportunity({
            pipeline: activePipelineId,
            stageId: orderedPipelineStages[0],
        }),
        [createNewOpportunity, activePipelineId, orderedPipelineStages],
    );

    const contextValue = useMemo(
        () => ({
            selectedPipeline: activePipelineId,
            isLoading: isLoadingPipelines || isLoadingPipelineStages,
            pipelines,
            stages: orderedPipelineStages,
            searchQuery: getFilterValue('search') || '',
            filters: filterValues,
            sortBy: sort,
            searchFilters: dataSearchFilters,
            activeFiltersCount,
        }) satisfies ViewContextType,
        [
            activePipelineId,
            isLoadingPipelines,
            isLoadingPipelineStages,
            pipelines,
            orderedPipelineStages,
            filterValues,
            activeFiltersCount,
            getFilterValue,
            sort,
        ],
    );

    const handleSwitchPipeline = useCallback((value: string) => {
        if (value === 'manage') {
            navigate(`/${agentId}/pipelines`);
            return;
        }
        navigate(`/${agentId}/opportunities/${value}`);
    }, [navigate, agentId]);

    return (
        <>
            <Container>
                <Content>
                    <StyledCard>
                        <SearchContainer>
                            <PipelineSwitcher
                                agentId={agentId}
                                value={activePipelineId}
                                onChange={handleSwitchPipeline}
                                hideManageAction={!isAdmin}
                            />
                            <StyledButtonGroupPicker
                                value={match?.params?.viewType}
                                onChange={(viewType) => {
                                    navigate(viewType as string);
                                }}
                            >
                                <ButtonOption name="board" label={<BoardIcon />} />
                                <ButtonOption name="list" label={<TableViewIcon />} />
                            </StyledButtonGroupPicker>
                            <SearchInput
                                type="search"
                                placeholder="Search opportunity..."
                                variant="bare"
                                icon={<SearchIcon />}
                                borderRadius="semi-rounded"
                                onChange={(e) => setFilterValue('search', e.target.value || null)}
                                value={getFilterValue('search') || ''}
                            />
                            <RightContent>
                                <SortByPicker
                                    sortFields={sortFields}
                                    value={sort}
                                    onChange={setSort}
                                    prefixLabel="Sort by:"
                                    iconPosition="right"
                                    borderRadius="semi-rounded"
                                />
                                <ButtonIcon
                                    icon={<Insights />}
                                    onClick={() => navigate(`/${agentId}/insights/opportunities?pipeline=${activePipelineId}`)}
                                    borderRadius="semi-rounded"
                                    tooltip="View Insights"
                                />
                                <StyledBadgeOverlay
                                    value={activeFiltersCount}
                                    isHidden={activeFiltersCount === 0}
                                    variant="brand"
                                    position="top-left"
                                >
                                    <FilterButton
                                        label="Filters"
                                        agentId={agentId}
                                        pipelineId={activePipelineId}
                                    />
                                </StyledBadgeOverlay>
                                <Button
                                    variant="brand"
                                    size="medium"
                                    borderRadius="semi-rounded"
                                    onClick={handleAddNewOpportunity}
                                >
                                    <Plus className="rainbow-m-right_small" />
                                    New
                                </Button>
                            </RightContent>
                        </SearchContainer>
                        <Outlet context={contextValue} />
                    </StyledCard>
                </Content>
            </Container>
            <CreateEditOpportunityForm />
        </>
    );
};

export default OpportunitiesViewPage;
