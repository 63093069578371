import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

interface ParamConfig<T> {
    type: 'string' | 'number' | 'boolean' | 'date' | 'array';
    default: T;
}

type ConfigMap = Record<string, ParamConfig<any>>;

type MappedValues<CM extends ConfigMap> = {
    [K in keyof CM]: CM[K]['default'];
};

const parseValue = <T>(rawValue: string, config: ParamConfig<T>): T => {
    switch (config.type) {
        case 'number':
            return (Number(rawValue) || config.default) as T;
        case 'string':
            return rawValue as T;
        case 'date':
            return (() => {
                const parsed = new Date(rawValue);
                return Number.isNaN(parsed.getTime()) ? config.default : parsed as T;
            })();
        case 'boolean':
            return (rawValue === 'true') as T;
        case 'array':
            return decodeURIComponent(rawValue).split(',').map(Number) as T;
        default:
            return rawValue as T;
    }
};

/**
 * A custom hook for reading & setting typed query params via react-router-dom's useSearchParams.
 *
 * @param config An object describing each query param, its type, and a default value.
 * @returns A tuple [values, setValues], where:
 *          - values is the typed object of current param values
 *          - setValues is a function to update any subset of them
 */
const useCustomSearchParams = <CM extends ConfigMap>(config: CM) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const currentParams = useMemo<MappedValues<CM>>(() => {
        const result = {} as MappedValues<CM>;
        Object.keys(config).forEach((key) => {
            const paramConfig = config[key];
            const rawValue = searchParams.get(key);

            if (rawValue == null) {
                result[key as keyof CM] = paramConfig.default;
            } else {
                result[key as keyof CM] = parseValue(rawValue, paramConfig);
            }
        });

        return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const [values, setValues] = useState(currentParams);

    useEffect(() => {
        setValues(currentParams);
    }, [currentParams]);

    const updateParams = useCallback(
        (updates: Partial<MappedValues<CM>>, replace = false) => {
            const newSearchParams = new URLSearchParams(searchParams);

            Object.entries(updates).forEach(([key, value]) => {
                if (value == null || value === '') {
                    newSearchParams.delete(key);
                } else if (Array.isArray(value)) {
                    newSearchParams.set(key, value.join(','));
                } else {
                    newSearchParams.set(key, String(value));
                }
            });
            setSearchParams(newSearchParams, { replace });
        },
        [searchParams, setSearchParams],
    );

    return [values, updateParams] as const;
};

export default useCustomSearchParams;
