import { useParams } from 'react-router-dom';
import Footer from 'components/Footer';
import SummaryBillingItem from 'components/SummaryBillingItem';
import Dollar from 'components/icons/dollar';
import CalendarIcon from 'components/icons/calendarThin';
import StatusIcon from 'components/icons/doubleCheck';
import useHttpQuery from 'data/firestore/useHttpQuery';
import priceFormatter from 'data/services/price/formatter';
import { getFormatter } from 'data/services/date/formatter';
import useAgentData from 'hooks/useAgentData';
import useRedirectOnMissingRoles from 'hooks/useRedirectWhenMissingRoles';
import {
    Container,
    SumaryContainer,
    IconContainer,
    CardTitle,
    HistoryCard,
    HistoryHeader,
} from './styled';
import InvoiceList from './InvoiceList';
import UsageCards from './usageCards';
import { SubscriptionSummary, SubscriptionUsage } from './types';
import { transformUsageToMinutes } from './helpers/transformUsageToMinutes';

const dateFormatterShort = getFormatter('en-US', {
    month: 'short',
    day: 'numeric',
});

const dateFormatterMedium = getFormatter('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
});

function daysLeftString(targetDate: number) {
    const currentDate = Date.now();
    const timeRemaining = targetDate - currentDate;

    const daysLeft = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

    return rtf.format(daysLeft, 'day');
}

const Billing = () => {
    const { agentId = '' } = useParams();
    const { agent } = useAgentData();

    useRedirectOnMissingRoles({
        requiredRoles: ['owner', 'editor'],
    });

    const {
        data: subscriptionSummary,
        isLoading: isLoadingSubscription,
    } = useHttpQuery<SubscriptionSummary>({
        pathname: `/agents/${agentId}/subscription`,
        queryOptions: {
            enabled: Boolean(agent?.billing?.subscriptionId),
        },
    });
    const {
        data: currentPeriodUsage,
    } = useHttpQuery<SubscriptionUsage>({
        pathname: `/agents/${agentId}/subscription/usage`,
        queryOptions: {
            enabled: Boolean(agent?.billing?.subscriptionId),
        },
    });
    const { upcomingInvoice = null } = subscriptionSummary || {};
    const transformedUsage = transformUsageToMinutes(
        currentPeriodUsage?.resources || [],
    );

    const getCurrentBillValue = () => {
        if (isLoadingSubscription || !subscriptionSummary) return '';
        if (!upcomingInvoice?.total) return 'No Subscription';
        return priceFormatter(upcomingInvoice.currency)
            .format((upcomingInvoice.total || 0) / 100);
    };

    const getCurrentBillFooter = () => {
        if (isLoadingSubscription || !subscriptionSummary) return '';
        if (!upcomingInvoice?.periodStart) return 'No Subscription';
        return `${dateFormatterShort.format(new Date(upcomingInvoice.periodStart))} - Today`;
    };

    const getNextPaymentValue = () => {
        if (isLoadingSubscription || !subscriptionSummary) return '';
        if (!upcomingInvoice) return 'No Subscription';
        return dateFormatterMedium.format(new Date(upcomingInvoice.nextPaymentAttempt));
    };

    const getSubscriptionStatus = () => {
        if (isLoadingSubscription || !subscriptionSummary) return '';
        return subscriptionSummary.status;
    };

    const getNextPaymentFooter = () => {
        if (isLoadingSubscription || !subscriptionSummary) return '';
        if (!upcomingInvoice) return 'No Subscription';
        return daysLeftString(new Date(upcomingInvoice.nextPaymentAttempt).getTime());
    };

    const getSubscriptionCreatedAt = () => {
        if (isLoadingSubscription || !subscriptionSummary) return '';
        return `Created on ${dateFormatterMedium.format(new Date(subscriptionSummary.createdAt))}`;
    };

    const currentBillValue = getCurrentBillValue();
    const currentBillFooter = getCurrentBillFooter();
    const nextPaymentValue = getNextPaymentValue();
    const nextPaymentFooter = getNextPaymentFooter();
    const subscriptionStatus = getSubscriptionStatus();
    const subscriptionCreatedAt = getSubscriptionCreatedAt();

    return (
        <Container>
            <SumaryContainer>
                <SummaryBillingItem
                    isLoading={isLoadingSubscription}
                    value={currentBillValue}
                    label="Current Monthly Bill"
                    icon={(
                        <IconContainer>
                            <Dollar />
                        </IconContainer>
                    )}
                    footer={currentBillFooter}
                />
                <SummaryBillingItem
                    isLoading={isLoadingSubscription}
                    value={nextPaymentValue}
                    label="Next Payment Due"
                    icon={(
                        <IconContainer>
                            <CalendarIcon />
                        </IconContainer>
                    )}
                    footer={nextPaymentFooter}
                />
                <SummaryBillingItem
                    isLoading={isLoadingSubscription}
                    value={subscriptionStatus}
                    label="Subscription Status"
                    icon={(
                        <IconContainer>
                            <StatusIcon />
                        </IconContainer>
                    )}
                    footer={subscriptionCreatedAt}
                />
            </SumaryContainer>
            {upcomingInvoice && (
                <UsageCards
                    resources={transformedUsage}
                    periodStart={upcomingInvoice.periodStart}
                    periodEnd={upcomingInvoice.periodEnd}
                />
            )}

            <HistoryCard>
                <HistoryHeader>
                    <CardTitle>
                        Invoice History
                    </CardTitle>
                </HistoryHeader>
                <InvoiceList subscriptionId={agent?.billing?.subscriptionId} />
            </HistoryCard>
            <Footer />
        </Container>
    );
};

export default Billing;
