import { DateTime } from 'luxon';
import { ResponsiveBar } from '@nivo/bar';
import styled from 'styled-components';
import ChartContainer from './ChartContainer';
import { ORANGE_500 } from '../../constants';
import {
    CardDescription,
    CardTitle,
    UsageCard,
    UsageCardHeader,
} from './styled';
import BarTooltip from './BarTooltip';

const ChartWrapper = styled.div`
    width: 100%;
    height: 300px;
`;

interface ResourceUsage {
    name: string;
    description?: string;
    unit: string;
    usage: Array<{ date: string; quantity: number }>;
}

interface Props {
    resources?: ResourceUsage[];
    isLoading?: boolean;
    periodStart: string;
    periodEnd: string;
}

const UsageCards = ({
    resources,
    isLoading,
    periodStart,
    periodEnd,
}: Props) => resources?.map((resource) => {
    // Create a map of existing usage data
    const usageMap = new Map(
        resource.usage.map((item) => [
            DateTime.fromISO(item.date).toISODate(),
            item.quantity,
        ]),
    );

    // Generate all dates between periodStart and periodEnd
    const start = DateTime.fromISO(periodStart);
    const end = DateTime.fromISO(periodEnd);
    const diffInDays = end.diff(start, 'days').days;

    const chartData = Array.from({ length: Math.ceil(diffInDays) + 1 }, (_, index) => {
        const currentDate = start.plus({ days: index });
        const isoDate = currentDate.toISODate();
        return {
            date: currentDate.toFormat('MMM d'),
            quantity: usageMap.get(isoDate) || 0,
            unit: resource.unit,
        };
    });

    return (
        <UsageCard key={resource.name}>
            <UsageCardHeader>
                <CardTitle>
                    {resource.name}
                </CardTitle>
                <CardDescription>
                    {resource.description}
                </CardDescription>
            </UsageCardHeader>
            <ChartContainer isLoading={isLoading}>
                <ChartWrapper id="chartContainer">
                    <ResponsiveBar
                        data={chartData}
                        keys={['quantity']}
                        indexBy="date"
                        indexScale={{
                            type: 'band',
                        }}
                        margin={{
                            top: 10,
                            right: 20,
                            bottom: 50,
                            left: 40,
                        }}
                        padding={0.3}
                        colors={ORANGE_500}
                        borderRadius={4}
                        axisBottom={{
                            tickRotation: -45,
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: resource.unit,
                            legendPosition: 'middle',
                            legendOffset: -35,
                            format: (e) => (Number.isInteger(e) ? e : ''),
                        }}
                        tooltip={BarTooltip}
                        animate
                    />
                </ChartWrapper>
            </ChartContainer>
        </UsageCard>
    );
});

export default UsageCards;
