import { InputGroup, ModalContent, ModalButtonGroup } from 'pages/MemoryManagement/styled';
import { Button, Modal } from 'react-rainbow-components';
import Input from 'components/Input';
import Textarea from 'components/Textarea';
import { useEffect, useState } from 'react';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { useParams } from 'react-router-dom';
import RetrieverSummaryCard from 'components/RetrieverSummaryCard';
import {
    MemoryFilter,
    Retriever,
} from '../../types';
import {
    ModalLayout,
    FormSection,
    CollapsibleSection,
    CollapsibleHeader,
    CollapsibleTitle,
    CollapsibleContent,
} from './styled';

interface CreateUpdateModalProps {
    onRequestClose: () => void;
    initialValues?: {
        id: string;
        name: string;
        variable: string;
        description?: string;
    };
    filters: Omit<MemoryFilter, 'customerId'>;
}

type CreateReq = Omit<Retriever, 'id' | 'createdAt' | 'updatedAt'>;
type UpdateReq = Partial<CreateReq>;

const CreateUpdateModal = ({
    onRequestClose,
    initialValues,
    filters,
    ...connectedProps
}: CreateUpdateModalProps) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [variable, setVariable] = useState('');
    const [isVariableValid, setIsVariableValid] = useState(false);
    const [isFilterSummaryOpen, setIsFilterSummaryOpen] = useState(false);
    const { agentId } = useParams();

    const { mutate: create, isLoading: isCreating } = useHttpMutation<CreateReq, unknown>({
        method: 'POST',
        pathname: `/agents/${agentId}/memory-retrievers`,
        onSuccess: () => {
            onRequestClose();
        },
    });
    const { mutate: update, isLoading: isUpdating } = useHttpMutation<UpdateReq, unknown>({
        method: 'PATCH',
        pathname: `/agents/${agentId}/memory-retrievers/${initialValues?.id}`,
        onSuccess: () => {
            onRequestClose();
        },
    });

    useEffect(() => {
        setName(initialValues?.name || '');
        setDescription(initialValues?.description || '');
        setVariable(initialValues?.variable || '');
    }, [initialValues?.name, initialValues?.description, initialValues?.variable]);

    useEffect(() => {
        setIsVariableValid(
            !!variable.match(/^[a-zA-Z_$][a-zA-Z_$0-9]*$/),
        );
    }, [variable]);

    const handleConfirm = () => {
        if (initialValues) {
            update({
                body: {
                    name,
                    description,
                    variable,
                    types: filters.types,
                    minImportance: filters.minImportance,
                    maxImportance: filters.maxImportance,
                    query: filters.query,
                    segmentPrefix: filters.segmentPrefix,
                    limit: filters.limit,
                    relativeTime: filters.relativeTime,
                },
            });
        } else {
            create({
                body: {
                    name,
                    description,
                    variable,
                    types: filters.types,
                    minImportance: filters.minImportance,
                    maxImportance: filters.maxImportance,
                    query: filters.query,
                    segmentPrefix: filters.segmentPrefix,
                    limit: filters.limit,
                    relativeTime: filters.relativeTime,
                },
            });
        }
    };

    const buttonLabel = initialValues?.name
        ? 'Update'
        : 'Save';

    const toggleFilterSummary = () => {
        setIsFilterSummaryOpen(!isFilterSummaryOpen);
    };

    return (
        <Modal
            onRequestClose={onRequestClose}
            footer={(
                <ModalButtonGroup>
                    <Button
                        label="Cancel"
                        variant="border"
                        onClick={onRequestClose}
                        borderRadius="semi-rounded"
                    />
                    <Button
                        label={buttonLabel}
                        variant="brand"
                        onClick={handleConfirm}
                        disabled={!name.trim() || !variable.trim() || !isVariableValid}
                        borderRadius="semi-rounded"
                        isLoading={isCreating || isUpdating}
                    />
                </ModalButtonGroup>
            )}
            {...connectedProps}
        >
            <ModalContent>
                <ModalLayout>
                    <FormSection>
                        <InputGroup>
                            <Input
                                label="Name"
                                labelAlignment="left"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter a name"
                                borderRadius="semi-rounded"
                                required
                            />
                        </InputGroup>

                        <InputGroup>
                            <Input
                                label="Variable Name"
                                labelAlignment="left"
                                value={variable}
                                onChange={(e) => setVariable(e.target.value)}
                                placeholder="Enter a variable name"
                                borderRadius="semi-rounded"
                                bottomHelpText="This will be used to reference the memory retriever in your agent. Use valid JavaScript variable naming conventions (start with letters, _, or $, followed by letters, numbers, or _)."
                                required
                                error={
                                    isVariableValid || !variable ? undefined : 'Invalid variable name'
                                }
                            />
                        </InputGroup>

                        <InputGroup>
                            <Textarea
                                label="Description (optional)"
                                labelAlignment="left"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Enter a description"
                                borderRadius="semi-rounded"
                            />
                        </InputGroup>
                    </FormSection>

                    <CollapsibleSection>
                        <CollapsibleHeader
                            isOpen={isFilterSummaryOpen}
                            onClick={toggleFilterSummary}
                        >
                            <CollapsibleTitle>
                                <span>Filter Configuration Details</span>
                            </CollapsibleTitle>
                        </CollapsibleHeader>

                        <CollapsibleContent isOpen={isFilterSummaryOpen}>
                            <RetrieverSummaryCard
                                name={name}
                                types={filters.types}
                                minImportance={filters.minImportance}
                                maxImportance={filters.maxImportance}
                                limit={filters.limit}
                                relativeTime={filters.relativeTime}
                                query={filters.query}
                                segmentPrefix={filters.segmentPrefix}
                            />
                        </CollapsibleContent>
                    </CollapsibleSection>
                </ModalLayout>
            </ModalContent>
        </Modal>
    );
};

export default CreateUpdateModal;
