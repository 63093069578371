import styled from 'styled-components';
import { CheckboxGroup } from 'react-rainbow-components';
import { InputSearch } from '@rainbow-modules/search';
import Badge from 'components/Badge';
import { color } from 'theme';
import {
    DIVIDER, GREEN_500, GREY_200, GREY_400, GREY_600,
    WHITE,
} from '../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction column;
    gap: 1rem;
`;

export const StyledCheckBoxGroup = styled(CheckboxGroup)`
    & > div {
        gap: 0.375rem;
    }

    input[disabled] + label > span:first-child {
        background-color: ${color('brand.light')};
        border-color: ${color('brand.main')};
        color: ${color('brand.main')};
        
        &::after {
            border-color: ${color('brand.main')};
        }
    }

    label > span:first-child {
        margin-top: 0.25rem;
    }

    label > span:last-child {
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        color: ${GREY_400};
    }
`;

export const StyledInputSearch = styled(InputSearch)`
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    input {
        border-radius: 0.375rem;
        font-weight: 300 !important;
        border: 1px solid ${DIVIDER};

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    div > span:first-child {
        z-index: unset;
    }
`;

export const EventElementContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
`;

export const EventName = styled.h5`
    font-size: 0.875rem;
    color: ${GREY_600};
`;

export const EventDescription = styled.p`
    font-size: 0.75rem;
    color: ${GREY_200};
`;

export const SelectAlloptionContainer = styled.div`
    display: inline-flex;
    justify-items: between;
`;

export const Name = styled.h5`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    letter-spacing: 0.2px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-right: 10px;
`;

export const StyledBadge = styled(Badge)`
    color: ${WHITE};
    background-color: ${GREEN_500};
`;
