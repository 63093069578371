import { Variable } from 'components/InstructionsEditor/intellisense/types';
import { Llm } from 'data/firestore/agent/llm/types';
import useMemoryRetrievers from 'data/firestore/agent/memoryretriever/useCollection';
import { useEffect, useRef } from 'react';

interface Props {
    agentId: string;
    responder: Llm | null;
}

const systemJsonSchema = {
    type: 'object',
    properties: {
        system: {
            type: 'object',
            description: 'System-level information',
            properties: {
                company: {
                    type: 'object',
                    description: 'Company information',
                    properties: {
                        name: {
                            type: 'string',
                            description: 'Name of the company',
                        },
                        address: {
                            type: 'string',
                            description: 'Formatted company address',
                        },
                        timezone: {
                            type: 'string',
                            description: 'Company timezone',
                        },
                        customersPreferredLanguage: {
                            type: 'object',
                            description: "Customer's preferred language information",
                            properties: {
                                code: {
                                    type: 'string',
                                    description: "Language code (e.g., 'en', 'fr')",
                                },
                                name: {
                                    type: 'string',
                                    description: "Language name (e.g., 'English', 'French')",
                                },
                            },
                        },
                        aiOutputLanguage: {
                            type: 'object',
                            description: "AI's output language configuration",
                            properties: {
                                code: {
                                    type: 'string',
                                    description: "Language code (e.g., 'en', 'fr')",
                                },
                                name: {
                                    type: 'string',
                                    description: "Language name (e.g., 'English', 'French')",
                                },
                            },
                        },
                        description: {
                            type: 'string',
                            description: 'Description of the company',
                        },
                    },
                },
            },
        },
    },
};

const generateVariablesFromJsonSchema = (jsonSchema: any, prefix?: Variable): any[] => {
    const variables: Variable[] = [];
    if (prefix) {
        variables.push(prefix);
    }

    if (jsonSchema && jsonSchema.properties) {
        Object.entries(jsonSchema.properties).forEach(([key, value]: [string, any]) => {
            // Create the current property variable
            const propertyPath = prefix ? `${prefix.value}.${key}` : key;
            const variable = {
                value: propertyPath,
                label: propertyPath,
                documentation: value.description || '',
            };
            variables.push(variable);

            // Recursively handle nested objects
            if (value.type === 'object' && value.properties) {
                const nestedVariables = generateVariablesFromJsonSchema(value, variable);
                variables.push(...nestedVariables);
            }
        });
    }

    return variables;
};

const useResponderIntellisenseVariables = ({ agentId, responder }: Props) => {
    const variables = useRef<Set<Variable>>(new Set());
    const { data: allMemoryRetrievers } = useMemoryRetrievers(agentId);
    const { memoryRetrieverIds = [] } = responder || {};

    useEffect(() => {
        const systemVariables = generateVariablesFromJsonSchema(
            systemJsonSchema,
        );
        const inputVariables = generateVariablesFromJsonSchema(
            responder?.inputSchema,
            {
                label: 'input',
                value: 'input',
                documentation: 'Input variables',
            },
        );

        const responderMemoryRetrievers = allMemoryRetrievers.filter(
            (retriever) => memoryRetrieverIds.includes(retriever.id),
        );

        const memoryVariables = [
            {
                value: 'input.customer.memory',
                label: 'input.customer.memory',
                documentation: 'Memory variables for the customer',
            },
            ...responderMemoryRetrievers
                .map((retriever) => ({
                    value: `input.customer.memory.${retriever.variable}`,
                    label: retriever.variable,
                    documentation: `Memory retriever for ${retriever.name}`,
                })),
        ];

        variables.current.clear();
        [
            ...systemVariables,
            ...inputVariables,
            ...memoryVariables,
        ].forEach((variable) => {
            variables.current.add(variable);
        });
    }, [allMemoryRetrievers, memoryRetrieverIds, responder?.inputSchema]);

    return variables.current;
};

export default useResponderIntellisenseVariables;
