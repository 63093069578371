import {
    FormHeaderTitle, FormHeader, FormHeaderDescription,
} from './styled';

const NewResponderFormHeader = () => (
    <FormHeader>
        <FormHeaderTitle>Create New AI Persona</FormHeaderTitle>
        <FormHeaderDescription>
            Set up a unique AI persona by naming it,
            providing a brief description, and defining its skill type.
        </FormHeaderDescription>
    </FormHeader>
);

export default NewResponderFormHeader;
