import { useSearchParams } from 'react-router-dom';

/**
 * Hook that provides a function to update URL query parameters
 * @returns Function to update query parameters
 */
const useQueryUpdater = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    /**
     * Updates query parameters in the URL
     * @param params - Object containing key-value pairs to update in the URL
     *                 Pass null to clear all parameters
     */
    const updateQuery = (
        params: Record<string, string | number | string[] | undefined> | null,
    ) => {
        // If params is null, clear all parameters
        if (params === null) {
            setSearchParams(new URLSearchParams());
            return;
        }

        const newParams = new URLSearchParams(searchParams);

        Object.entries(params).forEach(([key, value]) => {
            if (
                value === undefined
                || value === ''
                || value === null
                || (Array.isArray(value) && value.length === 0)
            ) {
                // Remove the parameter if value is empty
                newParams.delete(key);
            } else if (Array.isArray(value)) {
                // Handle array values (convert to comma-separated string)
                newParams.set(key, value.join(','));
            } else {
                // Handle string or number values
                newParams.set(key, value.toString());
            }
        });

        setSearchParams(newParams);
    };

    return updateQuery;
};

export default useQueryUpdater;
