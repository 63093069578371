import {
  collection,
  query,
  startAfter,
  endBefore,
  limitToLast,
  limit as dbLimit,
  doc,
  getDoc,
  onSnapshot,
  Unsubscribe,
  FirestoreError,
} from "firebase/firestore";
import { db } from "../../firebase";
import {
  EntityGet,
  EntityServerGet,
  PaginateServiceOpts,
  Page,
} from "../../types";
import { Logs } from "./types";
import { transformMetadata, processTimestampFields } from "../../utils";

export type LogsOnSnapshotPaginateService<T> = (
  webhooksId: string,
  opts: PaginateServiceOpts,
  callback: (page: Page<T>) => void,
  onError?: (error: FirestoreError) => void
) => Promise<Unsubscribe>;

const onSnapshotPaginate: LogsOnSnapshotPaginateService<
  EntityGet<Logs>
> = async (
  webhooksId,
  { direction = "forward", limit = 25, cursor, listQueryFn, disableCache } = {},
  callback,
  onError = () => {}
) => {
  const collectionRef = collection(db, `/webhooks/${webhooksId}/logs`);
  const startFn = direction === "forward" ? startAfter : endBefore;
  const limitFn = direction === "forward" ? dbLimit : limitToLast;
  let q =
    typeof listQueryFn === "function"
      ? listQueryFn(collectionRef)
      : query(collectionRef);
  if (cursor) {
    const cursorDoc = await getDoc(
      doc(db, `/webhooks/${webhooksId}/logs/${cursor}`)
    );
    if (!cursorDoc.exists()) {
      throw new Error("Invalid cursor");
    }
    q = query(q, startFn(cursorDoc), limitFn(limit));
  } else {
    q = query(q, dbLimit(limit));
  }

  return onSnapshot(
    q,
    { includeMetadataChanges: disableCache },
    (querySnapshot) => {
      const { metadata } = querySnapshot;
      const { fromCache } = metadata;
      if (disableCache && fromCache) return;

      const docs = querySnapshot.docs.map((doc) => {
        return processTimestampFields<EntityGet<Logs>>(
          transformMetadata({
            ...doc.data(),
            id: doc.id,
          } as EntityServerGet<Logs>)
        ) as EntityGet<Logs>;
      });
      callback({
        docs,
        count: docs.length,
        firstDocId: docs.length > 0 ? docs[0].id : null,
        lastDocId: docs.length > 0 ? docs[docs.length - 1].id : null,
      });
    }
  );
};

export default onSnapshotPaginate;
