import { collection, query, getCountFromServer } from "firebase/firestore";
import { ListServiceOpts } from "../../types";
import { db } from "../../firebase";

export type LogsCountService = (
  webhooksId: string,
  opts?: ListServiceOpts
) => Promise<number>;

const count: LogsCountService = async (webhooksId, opts = {}) => {
  const collectionRef = collection(db, `/webhooks/${webhooksId}/logs`);
  const q =
    typeof opts.listQueryFn === "function"
      ? opts.listQueryFn(collectionRef)
      : query(collectionRef);
  const snapshot = await getCountFromServer(q);

  return snapshot.data().count;
};

export default count;
