/* eslint-disable react/jsx-no-undef */
import { Field as FinalFormField } from 'react-final-form';
import { composeValidators, isRequired, isUrl } from '@rainbow-modules/forms';
import { isEmptyArray } from '@rainbow-modules/validation';
import {
    FieldsSection,
    FieldSectionTitle,
    StyledInput,
    StyledTextarea,
} from './styled';
import EventsSelectInput from '../EventsSelectInput';

const Field = (props: any) => <FinalFormField parse={(value) => value} {...props} />;

const Fields = () => (
    <>
        <FieldsSection>
            <FieldSectionTitle>General Information</FieldSectionTitle>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledInput}
                name="endpoint"
                label="Endpoint URL"
                type="url"
                labelAlignment="left"
                placeholder="Enter endpoint url..."
                borderRadius="semi-rounded"
                autoComplete="off"
                required
                validate={composeValidators(
                    isRequired('This field is required'),
                    isUrl('Invalid url'),
                )}
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledInput}
                name="secret"
                label="Secret"
                type="password"
                labelAlignment="left"
                placeholder=""
                borderRadius="semi-rounded"
                autoComplete="off"
                required
                validate={isRequired('This field is required')}
            />
            <Field
                component={StyledTextarea}
                name="description"
                label="Webhook Description"
                labelAlignment="left"
                placeholder="Enter the webhook description"
                borderRadius="semi-rounded"
            />
        </FieldsSection>
        <FieldsSection>
            <FieldSectionTitle>Events to send</FieldSectionTitle>
            <Field
                component={EventsSelectInput}
                name="events"
                label="Select events to send"
                hideLabel
                enableSearch
                validate={composeValidators(
                    isRequired('You must select at least one event.'),
                    isEmptyArray,
                )}
            />
        </FieldsSection>
    </>
);

export default Fields;
