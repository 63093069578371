import { useReduxForm } from '@rainbow-modules/hooks';
import { RadioButtonGroup as OriginalRadioButtonGroup } from 'react-rainbow-components';
import { RadioButtonGroupProps } from 'react-rainbow-components/components/RadioButtonGroup';
import { RadioOption } from 'react-rainbow-components/components/types';

interface RadioButtonGroupValue<T> extends Omit<RadioOption, 'value'> {
    name: string;
    value?: T;
}

export interface RedioGroupWithCustomValueProps<T = any> extends Omit<RadioButtonGroupProps, 'value' | 'onChange' | 'options'> {
    value?: RadioButtonGroupValue<T>;
    onChange?: (value: RadioButtonGroupValue<T>) => void;
    options: RadioButtonGroupValue<T>[];
}

const RadioButtonGroup = (props: any) => {
    const {
        value: valueInProps,
        onChange = () => {},
        options,
        ...rest
    } = useReduxForm(props);

    const radioGroupValue = valueInProps
        ? options.find((option: any) => option.value === valueInProps)?.value
        : undefined;

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = options.find((option: any) => option.value === event.target.value)?.value;
        if (newValue) onChange(newValue);
    };

    return (
        <OriginalRadioButtonGroup
            {...rest}
            options={options}
            value={radioGroupValue}
            onChange={handleOnChange}
        />
    );
};

export default RadioButtonGroup;
