import { useCallback, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
    orderBy, OrderByDirection, query, where,
} from 'firebase/firestore';
import { ViewContextType } from 'pages/Opportunities/types';
import useAlgoliaSearchData from 'data/algolia/useSearchData';
import { EntityGet } from 'data/firestore/types';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import useCollectionWithPagination from 'data/firestore/agent/opportunity/useCollectionWithPagination';
import { ALGOLIA_INDEX_OPPORTUNITIES, PAGE_SIZE } from '../../../../../constants';
import { DEFAULT_SORT_OPPORTUNITIES } from '../../contants';
import buildAlgoliaFilters from '../../helpers/buildAlgoliaFilters';

const useListOpportunities = (agentId: string) => {
    const [activePage, setActivePage] = useState(1);
    const {
        selectedPipeline = '',
        stages = [],
        sortBy: sort = DEFAULT_SORT_OPPORTUNITIES,
        filters,
        activeFiltersCount = 0,
        searchQuery,
    } = useOutletContext<ViewContextType>();

    const {
        data: blockedNumbers, isLoading, nextPage, prevPage, totalRecords,
    } = useCollectionWithPagination(agentId as string, {
        limit: PAGE_SIZE,
        listQueryFn: (ref) => query(
            ref,
            where('pipelineId', '==', selectedPipeline),
            where('removed', '==', false),
            orderBy(sort.sortBy, sort.order as OrderByDirection),
        ),
        disabled: !agentId || !selectedPipeline || !stages.length,
        track: [agentId, selectedPipeline, sort.order, sort.sortBy],
    });

    const algoliaData = useAlgoliaSearchData<EntityGet<Opportunity>>({
        search: searchQuery,
        activePage,
        pageSize: PAGE_SIZE,
        indexName: ALGOLIA_INDEX_OPPORTUNITIES,
        filters: buildAlgoliaFilters({
            agentId,
            pipeline: selectedPipeline,
            filters,
        }),
        enabled: activeFiltersCount > 0 || Boolean(searchQuery),
    });

    const changePage = useCallback((event: React.ChangeEvent<unknown>, page: number) => {
        if (isLoading) return;
        if (page === activePage) return;
        if (!algoliaData?.hits) {
            if (page > activePage) nextPage();
            if (page < activePage) prevPage();
        }
        setActivePage(page);
    }, [activePage, algoliaData?.hits, isLoading, nextPage, prevPage]);

    const dataToShow = algoliaData?.hits || blockedNumbers;
    const totalHits = totalRecords;
    const pages = Math.ceil(totalHits / PAGE_SIZE);
    const firstItemOfActivePage = dataToShow.length === 0 ? 0 : PAGE_SIZE * (activePage - 1) + 1;
    const lastItemOfActivePage = Math.min(
        PAGE_SIZE * activePage,
        (PAGE_SIZE * activePage - PAGE_SIZE) + dataToShow.length,
    );

    return {
        isLoading,
        opportunities: dataToShow,
        firstItemOfActivePage,
        lastItemOfActivePage,
        totalHits,
        pages,
        activePage,
        changePage,
    };
};

export default useListOpportunities;
