import styled from 'styled-components';
import { GREY_800, GREY_400 } from '../../../../constants';

export const FormHeader = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem 1rem;
    gap: 0.5rem;
`;

export const FormHeaderTitle = styled.h1`
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 2.25rem;
    color: ${GREY_800};
`;

export const FormHeaderDescription = styled.p`
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_400};
`;
