import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Footer from 'components/Footer';
import usePipelines from 'data/firestore/agent/pipeline/useCollection';
import usePipelineStages from 'data/firestore/agent/pipeline/stage/useCollection';
import { Stage } from 'data/firestore/agent/pipeline/stage/types';
import useSearchFilters from 'hooks/useSearchFilters';
import { BottomGradient, Gradient } from 'pages/Tasks/styled';
import { Provider } from './context';
import ConversionJourneyInsights from './ConversionJourneyInsights';
import PipelineStageInsights from './PipelineStageInsights';
import {
    Container, Header, Title, Actions, StyledPipelineSwitcher, InsightsContainer,
} from './styled';
import { GREEN_600, ORANGE_400, RED_600 } from '../../constants';

const stageTypeToColoMap: Record<Stage['type'], string> = {
    open: ORANGE_400,
    lost: RED_600,
    won: GREEN_600,
};

const OpportunityInsights = () => {
    const { agentId = '' } = useParams();

    const { values, setValue } = useSearchFilters({ filterNames: ['pipeline'] });

    const { data: pipelines = [], isLoading: isLoadingPipelines } = usePipelines(
        agentId,
        { disabled: !agentId, track: [agentId] },
    );

    const pipelineId = useMemo(() => {
        if (values.pipeline) return values.pipeline as string;
        const [firstPipeline] = pipelines;
        const defaultPipeline = pipelines.find((pipeline) => pipeline.name === 'default');
        return defaultPipeline?.id || firstPipeline?.id;
    }, [pipelines, values]);

    const { data: pipelineStages, isLoading: isLoadingPipelineStages } = usePipelineStages(
        agentId,
        pipelineId,
        { disabled: !agentId || !pipelineId, track: [pipelineId] },
    );

    const stagesInsights = useMemo(() => {
        const currentPipeline = pipelines.find((pipeline) => pipeline.id === pipelineId);
        return currentPipeline?.stages.map((stageId) => {
            const stage = pipelineStages.find((pipelineStage) => pipelineStage.id === stageId);
            if (!stage) return null;
            return (
                <PipelineStageInsights
                    key={stage.id}
                    pipelineId={pipelineId}
                    stageName={stage.name}
                    title={stage.name}
                    description={stage.description}
                    colors={[stageTypeToColoMap[stage.type]]}
                />
            );
        });
    }, [pipelineId, pipelineStages, pipelines]);

    const handleChangePipeline = useCallback(
        (value: string) => setValue('pipeline', value),
        [setValue],
    );

    return (
        <Provider
            value={{
                pipelineId,
                isLoading: isLoadingPipelines || isLoadingPipelineStages,
            }}
        >
            <Container>
                <Header>
                    <Title>Opportunities Insights</Title>
                    <Actions>
                        <StyledPipelineSwitcher
                            agentId={agentId}
                            hideManageAction
                            value={pipelineId}
                            onChange={handleChangePipeline}
                        />
                    </Actions>
                </Header>
                <InsightsContainer>
                    <ConversionJourneyInsights />
                    {stagesInsights}
                </InsightsContainer>
                <Footer />
                <Gradient />
                <BottomGradient />
            </Container>
        </Provider>
    );
};

export default OpportunityInsights;
