import {
  collection,
  onSnapshot,
  FirestoreError,
  query,
  Unsubscribe,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import { EntityGet, EntityServerGet, ListServiceOpts } from "../../../../types";
import { Version } from "./types";
import { transformMetadata, processTimestampFields } from "../../../../utils";

export type VersionOnSnapshotCollectionService<T> = (
  agentId: string,
  llmId: string,
  templatepartialId: string,
  opts: ListServiceOpts,
  callback: (docs: Array<T>) => void,
  onError?: (error: FirestoreError) => void
) => Unsubscribe;

const onSnapshotCollection: VersionOnSnapshotCollectionService<
  EntityGet<Version>
> = (
  agentId,
  llmId,
  templatepartialId,
  opts: ListServiceOpts,
  callback,
  onError = () => {}
) => {
  const collectionRef = collection(
    db,
    `/agent/${agentId}/llm/${llmId}/templatepartial/${templatepartialId}/version`
  );
  const { disableCache } = opts;
  const q = opts.listQueryFn
    ? opts.listQueryFn(collectionRef)
    : query(collectionRef);
  return onSnapshot(
    q,
    { includeMetadataChanges: disableCache },
    (querySnap) => {
      const { metadata } = querySnap;
      const { fromCache } = metadata;
      if (disableCache && fromCache) return;
      callback(
        querySnap.docs.map(
          (doc) =>
            processTimestampFields(
              transformMetadata(
                {
                  ...doc.data(),
                  id: doc.id,
                } as EntityServerGet<Version>,
                doc.metadata
              )
            ) as EntityGet<Version>
        )
      );
    },
    onError
  );
};

export default onSnapshotCollection;
