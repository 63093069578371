import styled, { css } from 'styled-components';
import { Chip } from 'react-rainbow-components';
import Button from 'components/Button';
import Drawer from 'components/Drawer';
import Picklist from 'components/Picklist';
import { color } from 'theme';
import { GREY_100 } from '../../constants';

export const ValueContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    outline: none;
    cursor: pointer;

    &:hover,
    &:focus {
        outline: 0;
    }
`;

export const InnerContainer = styled.div`
    position: relative;
    display: flex;
`;

export const StyledChip = styled(Chip)<{ disabled?: boolean }>`
    background-color: ${color('background.main')};
    padding: 0.4375rem 0.625rem 0.4375rem 0.75rem;
    align-items: center;
    gap: 0.5rem;
    flex: 1 1 auto;
    height: 3.375rem;
    border-radius: 1rem;

    & > button[data-id="button-icon-element"] {
        margin-right: -0.3rem;
        border-radius: 0.375rem;

        &:hover {
            margin-right: -0.3rem;
            border-radius: 0.375rem;
        }
    }

    ${(props) => props.disabled && css`
        background-color: ${GREY_100};
        cursor: default;
    `};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
    position: relative;
`;

export const StyledPicklist = styled(Picklist)`
    align-self: stretch;
`;

export const StyledButton = styled(Button)<{ hasError?: boolean }>`
    align-self: flex-end;
    ${(props) => props.hasError && css`
        position: absolute;
        bottom: -4px;
    `};
`;

export const StyledDrawer = styled(Drawer)`
    width: 400px;

    div[id^=drawer-content] {
        padding: 0;
    }
`;
