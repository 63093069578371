import styled from 'styled-components';
import ButtonIcon from 'components/ButtonIcon';

export const Container = styled.div`
    width: 100%;
    overflow-x: auto;
    padding: 8px;
    position: relative;
`;

export const ExpandButton = styled(ButtonIcon)`
    position: absolute;
    top: 0px;
    right: 0px;
    flex-shrink: 0;
`;

export const ContentWrapper = styled.div`
    min-width: min-content;
`;

export const Header = styled.div`
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e0e0e0;
`;

export const Field = styled.div`
    margin-bottom: 4px;
    display: flex;
    align-items: baseline;
`;

export const Label = styled.span`
    font-weight: 600;
    margin-right: 4px;
    color: #666;
    flex-shrink: 0;
`;

export const Value = styled.span`
    color: #333;
`;

export const Body = styled.div`
    white-space: pre-wrap;
    line-height: 1.3;
    word-break: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
`;
