import styled from 'styled-components';
import SaraAvatar from 'components/icons/saraAvatar';
import Avatar from 'components/UserAvatar';
import { GREY_400 } from '../../../../constants';

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${GREY_400};
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.375rem;
    text-transform: capitalize;
`;

export const ActionsColumnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    gap: 0.5rem;
    padding-right: 1rem;
`;

export const UserCellWrapper = styled(CellWrapper)`
    padding: 10px 10px 10px 20px;
`;

export const StyledLocationIcon = styled(Avatar)`    
`;

export const StyledSaraAvatar = styled(SaraAvatar)`
    height: fit-content;
    flex-shrink: 0;
    position: relative;
`;
