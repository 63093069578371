import styled from 'styled-components';
import { CardHeader, StyledUserAvatar } from 'pages/Opportunities/View/Board/OpportunityCard/styled';
import Badge from 'components/Badge';

export const StyledCardHeader = styled(CardHeader)`
    align-items: center;
    padding: 0.5rem 0.5rem 0.625rem;
`;

export const CustomerAvatar = styled(StyledUserAvatar)`
    svg {
        width: 2rem;
        height: 2rem;
    }
`;

export const StyledBadge = styled(Badge)<{ color: string }>`
    color: ${(props) => props.color};
    background: ${(props) => `${props.color}29`};
    border-radius: 0.375rem;
    font-size: 0.75rem;
    font-weight: normal;
    margin: 0 auto;
`;

export const PipelineLabel = styled.div`
    font-size: 0.875rem;
    font-weight: 500;
`;
