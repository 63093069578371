import { useState, useEffect, useRef } from "react";
import { Unsubscribe, FirestoreError } from "firebase/firestore";
import paginate from "./paginate";
import countFn from "./count";
import onSnapshotPaginate from "./onSnapshotPaginate";
import { Logs } from "./types";
import {
  EntityGet,
  Page,
  HookPaginatedCollectionOpts,
  HookReturnPageCollection,
} from "../../types";

export type LogsUsePaginatedCollectionHook<T> = (
  webhooksId: string,
  opts?: HookPaginatedCollectionOpts<T>
) => HookReturnPageCollection<T>;

const useCollectionWithPagination: LogsUsePaginatedCollectionHook<
  EntityGet<Logs>
> = (webhooksId, opts = {}) => {
  const unsubscribe = useRef<Unsubscribe | null>(null);
  const unsubscribePromise = useRef<Promise<Unsubscribe> | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<FirestoreError | null>(null);
  const [firstPage, setFirstPage] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const previousPageIndex = useRef(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState<Page<EntityGet<Logs>>>({
    docs: [],
    count: 0,
    firstDocId: null,
    lastDocId: null,
  });
  const {
    limit = 25,
    disabled = false,
    onSnap,
    track = [],
    ...restOpts
  } = opts;

  const handleError = (err: unknown) => {
    if (err instanceof FirestoreError) {
      setError(err);
    } else {
      setError({
        code: "unknown",
        message: "Unknown error",
        name: "Unknown error",
      });
    }
    setLoading(false);
  };

  const unsubscribeFromSnapshot = () => {
    if (unsubscribe.current) {
      unsubscribe.current();
      unsubscribe.current = null;
      unsubscribePromise.current = null;
    }
  };

  useEffect(() => {
    if (!disabled) {
      setLoading(true);
      const direction =
        previousPageIndex.current !== pageIndex
          ? previousPageIndex.current < pageIndex
            ? "forward"
            : "backward"
          : undefined;
      const cursor = direction
        ? direction === "forward"
          ? page.lastDocId
          : page.firstDocId
        : undefined;
      if (!cursor && pageIndex !== 0) {
        previousPageIndex.current = 0;
        setPageIndex(0);
      }
      (async () => {
        if (unsubscribePromise.current) {
          await unsubscribePromise.current;
          unsubscribeFromSnapshot();
        }
        unsubscribePromise.current = onSnapshotPaginate(
          webhooksId,
          {
            ...restOpts,
            limit,
            cursor: cursor || undefined,
            direction,
          },
          async (page) => {
            try {
              const recordsCount = await countFn(webhooksId, opts);
              setPage(page);
              setTotalRecords(recordsCount);
              setHasMore(page.count < recordsCount);
              if (typeof onSnap === "function") {
                onSnap(page.docs);
              }
            } catch (err) {
              handleError(err);
            }
            setLoading(false);
          },
          handleError
        );
        unsubscribe.current = await unsubscribePromise.current;
      })();

      return () => {
        unsubscribeFromSnapshot();
      };
    }
  }, [disabled, pageIndex, ...track]);

  useEffect(() => {
    setFirstPage(pageIndex === 0);
    setHasMore((pageIndex + 1) * limit < totalRecords);
    previousPageIndex.current = pageIndex;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  const nextPage = async () => {
    if (isLoading || !hasMore) return;
    setPageIndex(pageIndex + 1);
  };
  const prevPage = async () => {
    if (isLoading || firstPage) return;
    setPageIndex(pageIndex - 1);
  };

  return {
    data: page.docs,
    error,
    totalRecords,
    isLoading,
    nextPage,
    prevPage,
    firstPage,
    hasMore,
  };
};

export default useCollectionWithPagination;
