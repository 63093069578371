import { Monaco } from '@monaco-editor/react';
import { Variable, Snippet } from './types';
import registerVariables from './registerVariables';
import registerSnippets from './registerSnippets';
import defaultSnippets from './defaultSnippets';

interface Params {
    monaco: Monaco;
    variables?: Set<Variable>;
    snippets?: Snippet[];
}

export default ({
    monaco,
    variables = new Set(),
    snippets = [],
}: Params) => {
    registerVariables(monaco, variables);
    registerSnippets(monaco, [...defaultSnippets, ...snippets]);
};
