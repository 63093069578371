import { useMemo } from 'react';
import User from 'components/icons/user';
import Location from 'components/icons/location';
import formatter, { getFormatter } from 'data/services/date/formatter';
import Building from 'components/icons/building';
import Languages from 'components/icons/languages';
import useCustomer from 'data/firestore/agent/customer/use';
import useWorkplace from 'data/firestore/agent/workplace/useOnce';
import { formatLanguage } from 'data/services/language/formatter';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import formatGender from 'data/services/profile/formatGender';
import formatSex from 'data/services/profile/formatSex';
import formatPronoun from 'data/services/profile/formatPronoun';
import getDisplayName from 'data/services/profile/getDisplayName';
import Phone from 'components/icons/phone';
import Facebook from 'components/icons/facebookSolid';
import InstagramIcon from 'components/icons/instagramSolid';
import Email from 'components/icons/email';
import Calendar from 'components/icons/calendar';
import BirthdayCake from 'components/icons/birthdayCake';
import Note from 'components/icons/note';
import { RenderIf } from 'react-rainbow-components';
import UserAvatar from './userAvatar';
import DetailElement from './detail';
import {
    Container,
    DetailsElements,
    SectionTitle,
    Footer,
    Gradient,
} from './styled';

interface CustomerProfileProps {
    agentId?: string;
    customerId?: string;
    showAdditionalInfo?: boolean;
    showMetadata?: boolean;
    footer?: React.ReactNode;
}

const dobFormatter = getFormatter('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'utc',
});

const CustomerProfile = ({
    agentId = '',
    customerId = '',
    showAdditionalInfo = false,
    showMetadata = false,
    footer,
}: CustomerProfileProps) => {
    const { data: profileData, isLoading } = useCustomer(
        agentId,
        customerId,
        {
            disabled: !agentId || !customerId,
        },
    );
    const workplaceId = profileData?.workplaceId ?? '';

    const { data: workplace, isLoading: isLoadingWorkplace } = useWorkplace(agentId, profileData?.workplaceId || '', {
        disabled: !agentId || !workplaceId,
        track: [agentId, workplaceId],
    });

    const displayName = useMemo(() => getDisplayName(profileData), [profileData]);
    const instagramUsername = profileData?.instagram?.username;
    const instagramProfileURL = profileData?.instagramProfileURL;
    const facebookProfileURL = profileData?.facebookProfileURL;

    return (
        <Container>
            <UserAvatar
                uid={customerId}
                displayName={displayName}
                src={profileData?.photoURL}
                phoneNumber={profileData?.phoneNumber}
                email={profileData?.email}
                facebookProfileURL={facebookProfileURL}
                instagramProfileURL={instagramProfileURL}
                profileData={profileData}
            />
            <div className="rainbow-flex rainbow-flex_column">
                <DetailsElements>
                    <DetailElement
                        icon={<Phone />}
                        label="Phone Number"
                        value={formatIntlPhoneNumber(profileData?.phoneNumber)}
                        isLoading={isLoading}
                    />
                    <DetailElement
                        icon={<Email />}
                        label="Email"
                        value={profileData?.email}
                        isLoading={isLoading}
                    />
                    <DetailElement
                        icon={<Location />}
                        label="Address"
                        value={profileData?.address?.formatted}
                        isLoading={isLoading}
                    />
                    <DetailElement
                        icon={<BirthdayCake />}
                        label="Birthday"
                        value={profileData?.dob && dobFormatter.format(new Date(profileData.dob))}
                        isLoading={isLoading}
                    />
                    <DetailElement
                        icon={<Languages />}
                        label="Preferred Language:"
                        value={formatLanguage(profileData?.preferredLanguage || '')}
                        isLoading={isLoading}
                    />
                    <DetailElement
                        icon={<Note />}
                        label="Bio"
                        value={profileData?.bio}
                        isLoading={isLoading}
                    />
                    <RenderIf isTrue={!!workplace}>
                        <DetailElement
                            icon={<Building />}
                            label="Workplace"
                            value={workplace?.name}
                            isLoading={isLoadingWorkplace}
                        />
                    </RenderIf>
                </DetailsElements>
            </div>
            <RenderIf isTrue={showAdditionalInfo}>
                <div className="rainbow-flex rainbow-flex_column">
                    <SectionTitle>ADDITIONAL INFORMATION</SectionTitle>
                    <DetailsElements>
                        <DetailElement
                            icon={<User />}
                            label="Alias"
                            value={profileData?.alias}
                            isLoading={isLoading}
                        />
                        <DetailElement
                            icon={<User />}
                            label="First Name"
                            value={profileData?.firstName}
                            isLoading={isLoading}
                        />
                        <DetailElement
                            icon={<User />}
                            label="Last Name"
                            value={profileData?.lastName}
                            isLoading={isLoading}
                        />
                        <DetailElement
                            icon={<User />}
                            label="Gender:"
                            value={formatGender(profileData?.gender)}
                            isLoading={isLoading}
                        />
                        <DetailElement
                            icon={<User />}
                            label="Sex:"
                            value={formatSex(profileData?.sex)}
                            isLoading={isLoading}
                        />
                        <DetailElement
                            icon={<User />}
                            label="Pronouns:"
                            value={formatPronoun(profileData?.pronoun)}
                            isLoading={isLoading}
                        />
                        <DetailElement
                            icon={<InstagramIcon />}
                            label="Instagram Username"
                            to={instagramProfileURL}
                            value={instagramUsername ? `@${instagramUsername}` : instagramProfileURL}
                            isLoading={isLoading}
                        />
                        <DetailElement
                            icon={<Facebook />}
                            label="Facebook Profile URL"
                            to={facebookProfileURL}
                            value={facebookProfileURL}
                            isLoading={isLoading}
                        />
                    </DetailsElements>
                </div>
            </RenderIf>
            <RenderIf isTrue={showMetadata}>
                <div className="rainbow-flex rainbow-flex_column">
                    <SectionTitle>METADATA</SectionTitle>
                    <DetailsElements>
                        <DetailElement
                            icon={<Calendar />}
                            label="Created At:"
                            value={formatter.format(profileData?.createdAt)}
                            isLoading={isLoading}
                        />
                        <DetailElement
                            icon={<User />}
                            label="Created By:"
                            value={getDisplayName(profileData?.createdBy)}
                            isLoading={isLoading}
                        />
                    </DetailsElements>
                </div>
            </RenderIf>
            <Gradient />
            <Footer>
                {footer}
            </Footer>
        </Container>
    );
};

export default CustomerProfile;
