import styled, { css } from 'styled-components';
import { RecordField } from '@rainbow-modules/record';
import {
    Picklist, Option, Spinner, ProgressCircular,
} from 'react-rainbow-components';
import {
    GREY_800, GREY_500, GREY_400,
    GREY_300,
    WHITE,
} from '../../constants';

export const PrimaryText = styled.h3`
    font-size: 0.9375rem;
    line-height: 1.375rem;
    font-weight: 600;
    color: ${GREY_500};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0 1rem;
    gap: 1.5rem;
`;

export const BadgeContainer = styled.div`
    display: flex;
    align-items: start;
`;

export const StyledBadge = styled.div<{ color?: string }>`
    padding: 0.125rem 0.375rem 0.125rem 0.5rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.875rem;
    whites-pace: nowrap;
    ${(props) => props.color && css`
        color: ${props.color};
        background: ${props.color}29;
    `};
`;

export const Details = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 0.5rem;
    margin-top: 0.75rem;
`;

export const ElementContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    color: ${GREY_500};

    svg {
        width: 20px;
        height: auto;
    }
`;

export const IconContainer = styled.div`
    display: inline-flex;
    width: 1rem;
    height: 1rem;
    margin-top: 0.275rem;

    svg {
        width: 1rem !important;
        height: 1rem !important;
    }
`;

export const StyledRecordField = styled(RecordField)<{ wrap: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;

    > span {
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        color: ${GREY_500};
    }
    
    > span:first-child {
        font-weight: 600;
        white-space: nowrap;
        color: ${GREY_500};
        line-height: 1.375rem;
    }

    > span:last-child {
        overflow: hidden;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        color: ${GREY_400};
        text-overflow: ellipsis;
        flex: 1;
            
        > div {
            flex: 1;
        }
    }

    ${(props) => props.wrap && css`
        flex-direction: column;
        flex: 1;
        gap: 0;
        align-items: stretch;

        > span {
            align-self: stretch;
            flex: 1 1 100%;
        }
    `};
`;

export const Description = styled.span`
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_400};
    display: flex;
    gap: 0.25rem;

    & > svg {
        width: 1rem;
        height: 1rem;
    }
`;

export const StagePickerContainer = styled.div`
    position: relative;
    display: flex;
    gap: 0.5rem;
    align-content: flex-start;
    align-items: center;
    width: 170px;
`;

export const StyledPicklist = styled(Picklist)<{ color?: string }>`
    width: 100%;

    input {
        height: 3rem;
    }

    input,
    input:focus,
    input:active {
        border: 1px solid transparent;
        color: ${(props) => props.color};
        background: ${(props) => `${props.color}29`};
        transition: all 200ms ease-in;
    }

    input[disabled] {
        padding-left: 1rem;
        border: 1px solid transparent;
        color: ${(props) => `${props.color}60`};
        background: ${(props) => `${props.color}19`};
    }
    
    input:focus,
    input:active {
        box-shadow: ${(props) => `${props.color}29`} 0px 0px 2px;
    }

    span::after {
        color: ${(props) => props.color};
    }

    ${(props) => !props.value?.value.removable && css`
        input {
            text-transform: capitalize;
        }
    `};

    ${(props) => props.borderRadius === 'semi-rounded' && css`
        border-radius: 0.5rem;
    `}
`;

export const StyledOption = styled(Option)<{ isBuiltIn: boolean }>`
    ${(props) => props.isBuiltIn && css`
        span {
            text-transform: capitalize;
        }
    `};
`;

export const StyledSpinner = styled(Spinner)`
    z-index: unset;
    position: absolute;
    left: 100%;
    top: 50%;
`;

export const OpportunitySourceLabel = styled.span`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    color: ${GREY_800};
    display: flex;
    gap: 0.25rem;
    text-transform: capitalize;

    svg {
        width: 1rem;
        height: 1rem;
        order: 0;
    }
`;

export const OpportunityStageLabel = styled.span<{ isBuiltIn: boolean }>`
    ${(props) => props.isBuiltIn && css`
        text-transform: capitalize;
    `};
`;

export const OpportunityDescription = styled.p`
    font-size: 0.876rem;
    color: ${GREY_400};
`;

export const ScoreContainer = styled.div`
    display: flex;
    padding: 0.8125rem 0.75rem;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px solid #DBDADE;
`;

export const StyledProgressCircular = styled(ProgressCircular)`
    min-width: 2.7rem;
    width: 2.7rem;
    height: 2.7rem;

    svg {
        width: 2.4rem;
        height: 2.4rem;
        overflow: visible !important;
        
        circle {
            stroke-width: 4px;
        }
    }

    & > h1 {
        font-size: 1px;
        color: ${WHITE};
        &::after {
            color: ${GREY_400};
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.375rem;

            ${(props) => props.variant && css`
                content: '${props.value}';

            `}
        }
    }

    ${(props) => !props.variant && css`
        color: ${GREY_400};
        & > h1 {
            color: ${WHITE};
            ::after {
                color: ${GREY_300};
                content: '-';
                position: absolute;
                left: 0;
                width: 100%;
                text-align: center;
            }
        }
    `};
`;

export const OpportunityDescriptionBold = styled(OpportunityDescription)`
    font-weight: 600;
    font-size: 1rem;
`;
