import { BarDatum, BarTooltipProps } from '@nivo/bar';
import { TooltipContainer } from './styled';

const BarTooltip = ({ data, value }: BarTooltipProps<BarDatum>) => (
    <TooltipContainer>
        <strong>{data.date}</strong>
        {value}
        {' '}
        {String(data.unit).endsWith('s') && value === 1
            ? String(data.unit).slice(0, -1)
            : data.unit}
    </TooltipContainer>
);

export default BarTooltip;
