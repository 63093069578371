import styled from 'styled-components';
import Drawer from 'components/Drawer';

export const StyledDrawer = styled(Drawer)`
    width: 400px;

    div[id^=drawer-content] {
        padding: 0;
    }
`;
