import {
  collection,
  query,
  getDocs,
  getDocsFromServer,
} from "firebase/firestore";
import { db } from "../../firebase";
import { EntityGet, EntityServerGet, ListServiceOpts } from "../../types";
import { Pipeline } from "./types";
import { transformMetadata, processTimestampFields } from "../../utils";

export type PipelineListService<T> = (
  agentId: string,
  opts?: ListServiceOpts
) => Promise<Array<T>>;

const list: PipelineListService<EntityGet<Pipeline>> = async (
  agentId,
  opts = {}
) => {
  const collectionRef = collection(db, `/agent/${agentId}/pipeline`);
  const { disableCache } = opts;
  const q =
    typeof opts.listQueryFn === "function"
      ? opts.listQueryFn(collectionRef)
      : query(collectionRef);
  const getFn = disableCache ? getDocsFromServer : getDocs;
  const querySnapshot = await getFn(q);
  return querySnapshot.docs.map((doc) => {
    return processTimestampFields(
      transformMetadata({
        ...doc.data(),
        id: doc.id,
      } as EntityServerGet<Pipeline>)
    ) as EntityGet<Pipeline>;
  });
};

export default list;
