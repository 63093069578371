import styled from 'styled-components';
import {
    GREY_300,
    GREY_600,
    GREY_800,
    WHITE,
} from '../../../../constants';

// Custom colors for a better UI
const SOFT_BLUE = '#f0f7ff';
const BLUE_500 = '#3182ce';
const BLUE_600 = '#2b6cb0';
const BLUE_100 = '#ebf8ff';
const TEAL_500 = '#38b2ac';
const TEAL_100 = '#e6fffa';
const INDIGO_500 = '#667eea';
const INDIGO_100 = '#ebf4ff';
const SOFT_GREY = '#f7fafc';

export const ModalLayout = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FormSection = styled.div`
`;

export const CollapsibleSection = styled.div`
`;

export const CollapsibleHeader = styled.div<{ isOpen?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 8px 0;
    margin-bottom: ${({ isOpen }) => (isOpen ? '16px' : '0')};
    
    &:hover {
        opacity: 0.8;
    }
`;

export const CollapsibleTitle = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: ${BLUE_600};
    display: flex;
    align-items: center;
    gap: 8px;
`;
export const CollapsibleContent = styled.div<{ isOpen?: boolean }>`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    animation: ${({ isOpen }) => (isOpen ? 'fadeIn 0.3s ease' : 'none')};
    
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
`;

export const FilterSummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: ${SOFT_BLUE};
    border-radius: 10px;
    padding: 16px;
    border: 1px solid rgba(49, 130, 206, 0.2);
    position: relative;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`;

export const FilterSummaryTitle = styled.div`
    position: absolute;
    top: -10px;
    left: 12px;
    background-color: ${BLUE_500};
    padding: 0 10px;
    font-size: 12px;
    font-weight: 600;
    color: ${WHITE};
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const FilterItem = styled.div`
    display: flex;
    align-items: center;
    background-color: ${WHITE};
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 13px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(49, 130, 206, 0.15);
    margin-bottom: 8px;
    transition: all 0.2s ease;
    
    &:hover {
        box-shadow: 0 3px 6px rgba(49, 130, 206, 0.15);
        border-color: rgba(49, 130, 206, 0.3);
    }
    
    &:last-child {
        margin-bottom: 0;
    }
`;

export const FilterLabel = styled.span`
    font-weight: 600;
    color: ${GREY_800};
    margin-right: 8px;
    min-width: 80px;
`;

export const FilterValue = styled.span`
    color: ${BLUE_600};
    font-weight: 500;
    flex: 1;
    word-break: break-word;
`;

export const FilterGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const FilterGroupTitle = styled.div`
    font-size: 11px;
    font-weight: 600;
    color: ${BLUE_500};
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(49, 130, 206, 0.2);
`;

export const MemoryTypeFilterItem = styled(FilterItem)`
    background-color: ${INDIGO_100};
    border-color: rgba(102, 126, 234, 0.3);
    
    ${FilterValue} {
        color: ${INDIGO_500};
    }
`;

export const ImportanceFilterItem = styled(FilterItem)`
    background-color: ${TEAL_100};
    border-color: rgba(56, 178, 172, 0.3);
    
    ${FilterValue} {
        color: ${TEAL_500};
    }
`;

export const TimeFilterItem = styled(FilterItem)`
    background-color: ${BLUE_100};
    border-color: rgba(49, 130, 206, 0.3);
    
    ${FilterValue} {
        color: ${BLUE_500};
    }
`;

export const SearchFilterItem = styled(FilterItem)`
    background-color: ${SOFT_GREY};
    border-color: ${GREY_300};
    
    ${FilterValue} {
        color: ${GREY_600};
    }
`;
