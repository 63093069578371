import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ListTile from 'components/ListTile';
import { GREY_100, GREY_400, GREY_800 } from '../../../../constants';

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.75rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${GREY_400};
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.375rem;
    gap: 1rem;
`;

export const ActionsColumnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    gap: 0.25rem;
    padding-right: 1rem;
`;

export const UrlContainer = styled.span`
    display flex;
    padding: 0.125rem 0.125rem 0.125rem 0.5rem;
    align-items: center;
    gap: 0.5rem;
    color: ${GREY_800};
    background: ${GREY_100};
    width: fit-content;
    max-width: 100%;
    border-radius: 0.375rem;
`;

export const UrlLink = styled(Link)`
    font-weight: 500;
    font-size: 0.9375rem;
    user-select: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;    
    color: ${GREY_800};

    &:hover {
        color: ${GREY_800};
        text-decoration: underline;
    }
`;

export const StyledListTile = styled(ListTile)`
    & > div:last-child {
        gap: 0.25rem;
        & > span:first-child {
            font-weight: normal;
            font-size: 0.875rem;
            user-select: none;
        }
    }
`;
