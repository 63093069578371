import { useMemo } from 'react';
import Label from 'react-rainbow-components/components/Input/label';
import ListTile from 'components/ListTile';
import UserAvatar from 'components/UserAvatar';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import { InnerContainer, StyledChip, ValueContainer } from './styled';

interface Params {
    id?: string;
    className?: string;
    label?: string;
    required?: boolean;
    disabled?: boolean;
    value: any;
    onClearValue?: () => void;
}

const SelectedValue = ({
    id,
    className,
    label,
    required = false,
    disabled = false,
    value,
    onClearValue = () => {},
}: Params) => {
    const displayName = useMemo(() => getDisplayName(value), [value]);

    const initials = useMemo(() => (
        displayName === 'Unknown'
            ? null
            : getInitials(displayName)
    ), [displayName]);

    return (
        <ValueContainer id={id} className={className}>
            {label && (
                <Label
                    label={label}
                    labelAlignment="left"
                    required={required}
                />
            )}
            <InnerContainer>
                <StyledChip
                    label={(
                        <ListTile
                            icon={(
                                <UserAvatar
                                    initials={initials}
                                    value={value.id}
                                    src={value.photoURL}
                                />
                            )}
                            title={displayName}
                            subtitle={formatIntlPhoneNumber(value.phoneNumber)}
                        />
                    )}
                    borderRadius="semi-square"
                    onDelete={disabled ? undefined : onClearValue}
                />
            </InnerContainer>
        </ValueContainer>
    );
};

export default SelectedValue;
