import { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { RenderIf } from 'react-rainbow-components';
import { isDefined, isEmpty } from '@rainbow-modules/validation';
import Button from 'components/Button';
import Calendar from 'components/icons/calendarThin';
import CircleWithDotIcon from 'components/icons/circleWithDot';
import GaugeIcon from 'components/icons/gauge';
import Dollar from 'components/icons/dollar';
import ButtonIcon from 'components/ButtonIcon';
import Close from 'components/icons/close';
import Gradient from 'components/Gradient';
import User from 'components/icons/user';
import { ItemUser } from 'data/firestore/globals';
import tryParseNumber from 'data/services/string/parseNumber';
import useSearchFilters from 'hooks/useSearchFilters';
import Accordion from './accordion';
import {
    FilterHeader,
    Title,
    StyledDatePickerFilter,
    StyledCheckboxGroup,
    StyledTeamMemberSelect,
    StyledPipelineStageSelect,
    StyledPriceFilter,
    FiltersContainer,
    FiltersContent,
} from './styled';

interface Params {
    agentId: string;
    pipelineId: string;
    onRequestClose?: () => void;
}

const filterNames = ['createdAt', 'owner', 'stage', 'score', 'amount'];

const scoreFilterOptions = [
    { label: 'High (70 - 100)', value: 'high' },
    { label: 'Medium (40 - 69)', value: 'medium' },
    { label: 'Low (0 - 39)', value: 'low' },
    { label: 'Not Scored', value: 'not-scored' },
];

const Filters = ({
    agentId,
    pipelineId,
    onRequestClose = () => {},
}: Params) => {
    const {
        values,
        getValue: getFilterValue,
        setValue: setFilterValue,
        clear: clearFilters,
    } = useSearchFilters({ filterNames });

    const activeFiltersCount = useMemo(
        () => filterNames.reduce(
            (count, key) => (
                getFilterValue(key) !== null
                    ? count + 1
                    : count
            ),
            0,
        ),
        [getFilterValue],
    );

    const createdAtFilterValue = useMemo(
        () => {
            const { createdAt } = values;
            if (!createdAt) return undefined;
            return String(createdAt).split(',').map((strDate) => DateTime.fromISO(strDate).toJSDate());
        },
        [values],
    );

    const handleChangeCreatedAt = useCallback(
        (newFilterValue: Date[]) => setFilterValue(
            'createdAt',
            (
                newFilterValue.length === 0
                    ? null
                    : newFilterValue.map((date) => date.toISOString()).join(',')
            ),
        ),
        [setFilterValue],
    );

    const handleChangeOwner = useCallback(
        (newFilterValue: Array<ItemUser | null> | null) => setFilterValue(
            'owner',
            (
                !newFilterValue || isEmpty(newFilterValue)
                    ? null
                    : newFilterValue.map((user) => user?.uid as string).join(',')
            ),
        ),
        [setFilterValue],
    );

    const ownerFilterValue = useMemo(
        () => {
            const { owner } = values;
            if (owner !== null) return String(owner).split(',');
            return null;
        },
        [values],
    );

    const handleChangeStage = useCallback(
        (newFilterValue: string[] | null) => setFilterValue(
            'stage',
            (
                !newFilterValue || isEmpty(newFilterValue)
                    ? null
                    : newFilterValue.join(',')
            ),
        ),
        [setFilterValue],
    );

    const stageFilterValue = useMemo(
        () => {
            const { stage } = values;
            if (stage !== null) return String(stage).split(',');
            return null;
        },
        [values],
    );

    const handleChangeScore = useCallback(
        (newFilterValue: string[]) => setFilterValue(
            'score',
            !newFilterValue || isEmpty(newFilterValue)
                ? null
                : newFilterValue.join(','),
        ),
        [setFilterValue],
    );

    const scoreFilterValue = useMemo(
        () => {
            const { score } = values;
            const allFilterOptionValues = scoreFilterOptions.map((option) => option.value);
            if (!isDefined(score)) return [];
            return String(score).split(',').reduce(
                (result: string[], value: string) => {
                    if (allFilterOptionValues.includes(value)) {
                        return [...result, value];
                    }

                    return result;
                },
                [],
            );
        },
        [values],
    );

    const handleChangeAmount = useCallback(
        (newFilterValue: Array<number | null>) => {
            setFilterValue(
                'amount',
                newFilterValue.every((value) => isEmpty(value))
                    ? null
                    : newFilterValue.map((value) => value || '').join(','),
            );
        },
        [setFilterValue],
    );

    const amountFilterValue = useMemo(
        () => {
            const { amount } = values;
            if (amount) {
                return String(amount).split(',').reduce(
                    (
                        result: Array<number | null>,
                        value: string,
                    ) => [...result, tryParseNumber(value)],
                    [],
                );
            }

            return undefined;
        },
        [values],
    );

    return (
        <FiltersContainer>
            <FilterHeader>
                <Title>
                    Filters
                    {' '}
                    <RenderIf isTrue={activeFiltersCount > 0}>
                        (
                        {activeFiltersCount}
                        )
                    </RenderIf>
                </Title>
                <div>
                    <Button
                        label="Clear"
                        variant="base"
                        size="small"
                        disabled={!activeFiltersCount}
                        borderRadius="semi-square"
                        onClick={clearFilters}
                    />
                    <ButtonIcon
                        borderRadius="semi-square"
                        size="small"
                        icon={<Close />}
                        onClick={onRequestClose}
                    />
                </div>
            </FilterHeader>
            <FiltersContent>
                <Accordion icon={<Calendar />} label="Created Date" name="createdAt">
                    <StyledDatePickerFilter
                        onChange={handleChangeCreatedAt}
                        value={createdAtFilterValue}
                    />
                </Accordion>
                <Accordion icon={<User />} label="Owner" name="owner">
                    <StyledTeamMemberSelect
                        hideLabel
                        agentId={agentId}
                        value={ownerFilterValue}
                        onChange={handleChangeOwner}
                    />
                </Accordion>
                <Accordion icon={<CircleWithDotIcon />} label="Pipeline Stage" name="stage">
                    <StyledPipelineStageSelect
                        agentId={agentId}
                        pipelineId={pipelineId}
                        value={stageFilterValue}
                        onChange={handleChangeStage}
                    />
                </Accordion>
                <Accordion icon={<GaugeIcon />} label="Opportunity Score" name="score">
                    <StyledCheckboxGroup
                        value={scoreFilterValue}
                        options={scoreFilterOptions}
                        onChange={handleChangeScore}
                    />
                </Accordion>
                <Accordion icon={<Dollar />} label="Amount" name="amount">
                    <StyledPriceFilter
                        value={amountFilterValue}
                        onChange={handleChangeAmount}
                    />
                </Accordion>
            </FiltersContent>
            <Gradient position="bottom" />
        </FiltersContainer>
    );
};

export default Filters;
