/**
 * Transform an array of resource usages from seconds to minutes.
 * If unit is not in seconds, leave as is.
 */
import { ResourceUsage } from '../types';

export function transformUsageToMinutes(resources: ResourceUsage[]): ResourceUsage[] {
    if (!resources) {
        return [];
    }

    return resources.map((resource) => {
        if (resource.unit !== 'seconds') {
            return resource;
        }

        return {
            ...resource,
            unit: 'minutes',
            usage: resource.usage.map((item) => ({
                date: item.date.split('T')[0],
                quantity: Math.round(item.quantity / 60),
            })),
        };
    });
}
