import { DateTime } from 'luxon';

export const getFormatter = (
    locale: string,
    options: Intl.DateTimeFormatOptions,
) => new Intl.DateTimeFormat(locale, options);

const normalizeDate = (date: Date | string | number) => (typeof date === 'string' || typeof date === 'number' ? new Date(date) : date);

export const formatRelativeDate = (date: Date | string | number) => {
    const dateObj = DateTime.fromJSDate(normalizeDate(date));
    const isToday = dateObj.startOf('day').diff(DateTime.now().startOf('day')).toMillis() === 0;
    const lessThan15days = Math.abs(dateObj.startOf('day').diffNow('days').days) < 16;

    if (Math.abs(dateObj.diffNow('hours').hours) < 1) {
        return dateObj.toRelativeCalendar({
            unit: 'minutes',
        });
    }

    if (isToday) {
        return dateObj.toRelativeCalendar({
            unit: 'hours',
        });
    }

    if (lessThan15days) {
        return dateObj.toRelativeCalendar({
            unit: 'days',
        });
    }

    return dateObj.toRelativeCalendar();
};

export default new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
});
