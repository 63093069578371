import {
  doc,
  onSnapshot,
  FirestoreError,
  Unsubscribe,
} from "firebase/firestore";
import { db } from "../../firebase";
import { EntityGet, EntityServerGet, OnSnapshotDocService } from "../../types";
import { Llm } from "./types";
import { transformMetadata, processTimestampFields } from "../../utils";

export type LlmOnSnapshotDocService<T> = (
  agentId: string,
  id: string,
  callback: (doc: T) => void,
  onError?: (error: FirestoreError) => void
) => Unsubscribe;

const onSnapshotDoc: LlmOnSnapshotDocService<EntityGet<Llm>> = (
  agentId,
  id,
  callback,
  onError
) => {
  const docRef = doc(db, `/agent/${agentId}/llm/${id}`);
  return onSnapshot(
    docRef,
    (docSnap) => {
      if (docSnap.exists()) {
        callback(
          processTimestampFields(
            transformMetadata(
              {
                ...docSnap.data(),
                id: docSnap.id,
              } as EntityServerGet<Llm>,
              docSnap.metadata
            )
          ) as EntityGet<Llm>
        );
      }
    },
    onError
  );
};

export default onSnapshotDoc;
