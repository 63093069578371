import styled from 'styled-components';
import { ButtonGroup } from 'react-rainbow-components';
import Card from '../../components/Card';
import { GREY_300, GREY_800 } from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const StyledCard = styled(Card)`
    height: calc(100vh - 161px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const TableContainer = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const Header = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
    & > button {
        border-top-left-radius: 0.75rem !important;
        border-bottom-left-radius: 0.75rem !important;
    }
    div > button {
        margin-left: 1px;
        border-top-right-radius: 0.75rem !important;
        border-bottom-right-radius: 0.75rem !important;
    }
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-right: 1rem;
    gap: 0.35rem;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const CardTitle = styled.h2`
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;
    white-space: nowrap;
    color: ${GREY_800};
`;

export const CardSubtitle = styled.h3`
    font-size: 0.9375rem;
    font-weight: 300;
    line-height: 1.25rem;
    text-align: left;
    white-space: nowrap;
    color: ${GREY_300};
`;

export const ActionsColumnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    gap: 0.5rem;
    padding-right: 1rem;
`;
