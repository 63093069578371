import {
    ModalContainer, Highlight, StyledWarningIcon,
} from './styled';

const RedeliverWebhookModalContent = ({ endpoint }: { endpoint: string }) => (
    <ModalContainer>
        <StyledWarningIcon />
        <div>
            <p>
                The payload will be delivered to
                <Highlight>{endpoint}</Highlight>
                using the current webhook configuration.
            </p>
        </div>
    </ModalContainer>
);

export default RedeliverWebhookModalContent;
