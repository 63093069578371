import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import { useOpenModal } from '@rainbow-modules/hooks';
import Button from 'components/Button';
import Plus from 'components/icons/plus';
import FeatureSchemaSelect from 'components/FeatureSchemaSelect';
import TypeFilter from 'components/ResponderTypeFilter';
import { orderBy, query } from 'firebase/firestore';
import FloatingFilterButton from 'components/FloatingFilterButton';
import useResponders from 'data/firestore/agent/llm/useCollection';
import useFeaturesSchemas from 'data/firestore/responderfeatureschema/useCollection';
import { ADD_EDIT_LLM_RESPONDER_MODAL } from '../../../constants';
import {
    Actions, Container, Header, ListContainer, Title,
} from './styled';
import Loading from './Loading';
import { BottomGradient, Gradient, PageContainer } from '../styled';
import NewResponderFormHeader from './NewResponderFormHeader';
import EditResponderConfigForm from '../EditResponderConfigForm';
import useFilters from './hooks/useFilters';
import ResponderCards from './responderCards';

const RespondersList = () => {
    const { agentId = '' } = useParams();
    const {
        searchQuery = '', skillFilter, typeFilter, changeFilter,
    } = useFilters();

    const {
        data: responders,
        isLoading: isLoadingResponders,
    } = useResponders(
        agentId,
        {
            disabled: !agentId,
            listQueryFn: (ref) => query(ref, orderBy('createdAt', 'asc')),
            track: [agentId],
        },
    );
    const { data: features = [], isLoading: isLoadingFeatures } = useFeaturesSchemas();
    const [openModal] = useOpenModal(ADD_EDIT_LLM_RESPONDER_MODAL);

    const isLoading = isLoadingResponders || isLoadingFeatures;

    const filteredResponders = useMemo(() => {
        const responderSkills = skillFilter ?? [];
        const responderTypes = typeFilter ?? [];
        const hasFilters = responderSkills.length > 0
            || responderTypes.length > 0
            || !isEmpty(searchQuery);
        if (hasFilters) {
            const result = responders.filter((responder) => {
                let showResponder = true;

                if (searchQuery) {
                    const regexp = new RegExp(searchQuery, 'gi');
                    showResponder = regexp.test(responder.name) || regexp.test(responder.description ?? '');
                }

                if (responderSkills.length > 0) {
                    showResponder = showResponder && responderSkills.includes(responder.featureSchema ?? 'open');
                }

                if (responderTypes.length > 0) {
                    showResponder = showResponder && responderTypes.includes(responder.type ?? 'llm');
                }
                return showResponder;
            });

            return result;
        }
        return responders;
    }, [searchQuery, skillFilter, typeFilter, responders]);

    return (
        <PageContainer>
            <Container>
                <Header>
                    <Title>Sara AI Hub</Title>
                    <Actions>
                        <FloatingFilterButton
                            value={searchQuery}
                            onChange={(value) => changeFilter('search', value)}
                        />
                        <FeatureSchemaSelect
                            value={skillFilter}
                            onChange={(value) => changeFilter('skill', value)}
                        />
                        <TypeFilter
                            value={typeFilter}
                            onChange={(value) => changeFilter('type', value)}
                        />
                        <Button
                            variant="brand"
                            size="small"
                            borderRadius="semi-rounded"
                            onClick={() => openModal({ title: <NewResponderFormHeader /> })}
                        >
                            <Plus className="rainbow-m-right_small" />
                            New AI Persona
                        </Button>
                    </Actions>
                </Header>
                <ListContainer>
                    <RenderIf isTrue={isLoading}>
                        <Loading />
                    </RenderIf>
                    <RenderIf isTrue={!isLoading}>
                        <ResponderCards
                            agentId={agentId}
                            responders={filteredResponders}
                            features={features}
                        />
                    </RenderIf>
                </ListContainer>
            </Container>
            <Gradient />
            <BottomGradient />
            <EditResponderConfigForm />
        </PageContainer>
    );
};

export default RespondersList;
