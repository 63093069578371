import styled from 'styled-components';
import {
    GREY_400, GREY_600, GREY_800,
} from '../../../../../constants';

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.75rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${GREY_400};
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.375rem;
    gap: 1rem;
`;

export const UrlContainer = styled.span`
    display flex;
    align-items: center;
    gap: 0.5rem;
    color: ${GREY_800};
    width: fit-content;
    max-width: 100%;
    user-select: none;
    border-radius: 0.375rem;
`;

export const UrlLink = styled.span`
    cursor: pointer;
    font-size: 0.9375rem;
    padding: 0.125rem 0.5rem 0.125rem 0.25rem;
    border-radius: 0.375rem;
    user-select: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;    
    color: ${GREY_800};

    b {
        border-radius: 0.375rem;
        color: ${GREY_600};
        font-weight: 600;
        font-size: 0.75rem;
        padding: 0.075rem 0.5rem;
    }

    &:hover {
        color: ${GREY_800};
        text-decoration: underline;
    }
`;
