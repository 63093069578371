import React, { useState } from 'react';
import CustomerPicker from 'components/CustomerPicker';
import Select from 'components/Picklist';
import { Option } from 'react-rainbow-components';
import Input from 'components/Input';
import Textarea from 'components/Textarea';
import { useParams } from 'react-router-dom';
import MemoryRetrieverPicker from 'components/MemoryRetrieverPicker';
import Button from 'components/Button';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import {
    MemoryFilter,
    Retriever,
    DEFAULT_FILTER,
    MEMORY_TYPE_OPTIONS,
    TIME_UNIT_OPTIONS,
} from '../types';
import {
    Sidebar as SidebarContainer,
    SectionTitle,
    FilterSection,
    StyledCheckboxGroup,
    StyledSlider,
    ButtonGroup,
    InputGroup,
    InputLabel,
    RelativeTimeContainer,
    RelativeTimeInput,
} from '../styled';
import useQueryUpdater from '../hooks/useQueryUpdater';
import CreateUpdateModal from './CreateUpdateModal';

interface SidebarProps {
    filters: MemoryFilter;
}

const CREATE_UPDATE_MODAL = 'memory-management-create-update-modal';

const Sidebar = ({
    filters,
}: SidebarProps) => {
    const [selectedConfig, setSelectedConfig] = useState<Retriever | null>(null);
    const { agentId } = useParams();
    const { ...connectedProps } = useConnectModal(CREATE_UPDATE_MODAL);
    const [openModal, closeModal] = useOpenModal(CREATE_UPDATE_MODAL);

    const updateQuery = useQueryUpdater();

    const handleCustomerChange = (baseCustomer: any | null) => {
        if (baseCustomer) {
            updateQuery({ customer: baseCustomer.id });
        } else {
            updateQuery(null);
        }
    };

    const handleTypeChange = (values: string[]) => {
        updateQuery({ types: values as MemoryFilter['types'] });
    };

    const handleImportanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const values = value.split(',').map((v) => parseInt(v, 10));

        updateQuery({ minImportance: values[0], maxImportance: 10 });
    };

    const handleRelativeTimeValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10);
        updateQuery({ 'releative-time-value': Number.isNaN(value) ? 0 : value });
    };

    const handleRelativeTimeUnitChange = ({ value }: PicklistValue) => {
        updateQuery({
            'releative-time-unit': value as 'days' | 'weeks' | 'months' | 'years',
        });
    };

    const handleSegmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateQuery({ segment: event.target.value || '' });
    };

    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateQuery({ limit: Number(event.target.value) });
    };

    const handleReferenceTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        updateQuery({ query: event.target.value });
    };

    const loadConfig = (config: Retriever | null) => {
        setSelectedConfig(config);
        if (config) {
            updateQuery({
                minImportance: config.minImportance,
                maxImportance: config.maxImportance,
                'releative-time-value': config.relativeTime?.value,
                'releative-time-unit': config.relativeTime?.unit,
                segment: config.segmentPrefix,
                query: config.query,
                limit: config.limit,
            });
        } else {
            updateQuery({
                types: [],
                minImportance: DEFAULT_FILTER.minImportance,
                maxImportance: DEFAULT_FILTER.maxImportance,
                'releative-time-value': DEFAULT_FILTER.relativeTime?.value,
                'releative-time-unit': DEFAULT_FILTER.relativeTime?.unit,
                segmentPrefix: DEFAULT_FILTER.segmentPrefix,
                query: DEFAULT_FILTER.query,
                limit: DEFAULT_FILTER.limit,
            });
        }
    };
    return (
        <SidebarContainer>
            <FilterSection>
                <CustomerPicker
                    agentId={agentId}
                    value={filters.customerId || ''}
                    onChange={handleCustomerChange}
                    placeholder="Select a customer"
                    disableCreate
                />
            </FilterSection>

            {filters.customerId && (
                <>
                    <FilterSection>
                        <SectionTitle>Filter Controls</SectionTitle>

                        <StyledCheckboxGroup
                            label="Memory Types"
                            labelAlignment="left"
                            options={MEMORY_TYPE_OPTIONS}
                            value={filters.types}
                            onChange={handleTypeChange}
                        />

                        <StyledSlider
                            label="Min Importance (1-10)"
                            labelAlignment="left"
                            value={`${filters.minImportance}`}
                            onChange={handleImportanceChange}
                            min={1}
                            max={10}
                            step={1}
                        />

                        <InputGroup>
                            <InputLabel>Relative Time Period</InputLabel>
                            <RelativeTimeContainer>
                                <RelativeTimeInput
                                    label="Value"
                                    labelAlignment="left"
                                    type="number"
                                    value={filters.relativeTime?.value}
                                    onChange={handleRelativeTimeValueChange}
                                    placeholder="Enter value"
                                    min={1}
                                    borderRadius="semi-rounded"
                                />
                                <Select
                                    label="Unit"
                                    labelAlignment="left"
                                    value={{
                                        name: filters.relativeTime?.unit,
                                        label: TIME_UNIT_OPTIONS.find(
                                            (option) => option.value === filters.relativeTime?.unit,
                                        )?.label,
                                        value: filters.relativeTime?.unit,
                                    }}
                                    onChange={handleRelativeTimeUnitChange}
                                >
                                    {TIME_UNIT_OPTIONS.map((option) => (
                                        <Option
                                            key={option.value}
                                            name={option.value}
                                            label={option.label}
                                            value={option.value}
                                        />
                                    ))}
                                </Select>
                            </RelativeTimeContainer>
                        </InputGroup>

                        <InputGroup>
                            <Input
                                label="Segment Prefix"
                                labelAlignment="left"
                                value={filters.segmentPrefix || ''}
                                onChange={handleSegmentChange}
                                placeholder="memories.customer.12345"
                                borderRadius="semi-rounded"
                            />
                        </InputGroup>

                        <InputGroup>
                            <Input
                                label="Limit"
                                labelAlignment="left"
                                type="number"
                                value={filters.limit}
                                onChange={handleLimitChange}
                                placeholder="Enter limit"
                                borderRadius="semi-rounded"
                            />
                        </InputGroup>
                    </FilterSection>

                    <FilterSection>
                        <SectionTitle>Embedding / Retrieval Options</SectionTitle>

                        <InputGroup>
                            <Textarea
                                label="Reference Text"
                                labelAlignment="left"
                                value={filters.query}
                                onChange={handleReferenceTextChange}
                                placeholder="Enter reference text for semantic search"
                                borderRadius="semi-rounded"
                            />
                        </InputGroup>
                    </FilterSection>

                    <>
                        <SectionTitle>Actions</SectionTitle>
                        <MemoryRetrieverPicker
                            label="Retrieval Configuration"
                            onChange={loadConfig}
                            agentId={agentId as string}
                        />
                        <ButtonGroup>
                            <Button
                                label="Save as New"
                                variant="brand"
                                onClick={() => openModal({
                                    title: 'Save Retrieval Configuration',
                                })}
                                borderRadius="semi-rounded"
                            />
                            <Button
                                label="Update Existing"
                                variant="border"
                                onClick={() => openModal({
                                    title: 'Update Retrieval Configuration',
                                    initialValues: {
                                        id: selectedConfig?.id,
                                        name: selectedConfig?.name,
                                        variable: selectedConfig?.variable,
                                        description: selectedConfig?.description,
                                    },
                                })}
                                borderRadius="semi-rounded"
                                disabled={!selectedConfig}
                            />
                        </ButtonGroup>
                    </>
                </>
            )}

            <CreateUpdateModal
                onRequestClose={closeModal}
                filters={{
                    minImportance: filters.minImportance,
                    maxImportance: filters.maxImportance,
                    query: filters.query,
                    segmentPrefix: filters.segmentPrefix,
                    relativeTime: filters.relativeTime,
                    types: filters.types,
                    limit: filters.limit,
                }}
                {...connectedProps}
            />
        </SidebarContainer>
    );
};

export default Sidebar;
