import FormFooter from './footer';
import Form from './form';
import { Container, Content } from './styled';
import { BaseFormProps } from './types';

interface EditWebhookFormProps extends BaseFormProps {}

const EditWebhookForm = ({
    submitButtonLabel,
    initialValues,
    onSubmit,
    onRequestClose = () => {},
}: EditWebhookFormProps) => (
    <Container>
        <Content>
            <Form
                id="webhooks-form"
                initialValues={initialValues}
                onSubmit={onSubmit}
            />
        </Content>
        <FormFooter
            formId="webhooks-form"
            submitButtonLabel={submitButtonLabel}
            onCancelClick={onRequestClose}
        />
    </Container>
);

export default EditWebhookForm;
