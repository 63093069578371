import { Field } from 'react-final-form';
import LanguagePicker from 'components/LanguagePicker';
import { Message } from '../styled';
import { FieldsContainer } from './styled';

interface Params {
    message?: string;
}

const Fields = ({
    message = '',
}: Params) => (
    <>
        <Message>{message}</Message>
        <FieldsContainer>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={LanguagePicker}
                name="preferredLanguage"
                label="Preferred Language"
                labelAlignment="left"
                borderRadius="semi-square"
                required
                languages={['en', 'es', 'multi']}
            />
        </FieldsContainer>
    </>
);

export default Fields;
