import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'react-rainbow-components';
import useOpportunities from 'data/firestore/agent/opportunity/useCollectionWithPagination';
import { orderBy, query, where } from 'firebase/firestore';
import Pagination from 'components/Pagination';
import Table from 'components/Table';
import DateTimeColumn from 'components/DateTimeColumn';
import { PAGE_SIZE } from '../../../../constants';
import { Container, EntriesText, PaginationContainer } from './styled';
import OpportunityColumn from './columns/opportunity';
import StageColumn from './columns/stage';

const CustomerDetailsOpportunitiesList = () => {
    const { agentId = '', customerId = '' } = useParams();
    const [activePage, setActivePage] = useState(1);
    const {
        data: opportunities,
        isLoading: isLoadingOpportunities,
        totalRecords: totalOpportunities,
        nextPage,
        prevPage,
        error,
    } = useOpportunities(
        agentId,
        {
            listQueryFn: (ref) => query(
                ref,
                where('removed', '==', false),
                where('customer.id', '==', customerId),
                orderBy('updatedAt', 'desc'),
            ),
            disabled: !agentId || !customerId,
            track: [agentId, customerId],
        },
    );

    const pages = Math.ceil(totalOpportunities / PAGE_SIZE);
    const firstItemOfActivePage = opportunities.length === 0 ? 0 : PAGE_SIZE * (activePage - 1) + 1;
    const lastItemOfActivePage = Math.min(
        PAGE_SIZE * activePage,
        (PAGE_SIZE * activePage - PAGE_SIZE) + opportunities.length,
    );

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        if (isLoadingOpportunities) return;
        if (page === activePage) return;
        if (page > activePage) nextPage();
        if (page < activePage) prevPage();
        setActivePage(page);
    };

    if (error || (!opportunities && !isLoadingOpportunities)) {
        return (
            <Container>
                <h1>Something went wrong</h1>
                {error?.message && (
                    <p>
                        {error.name}
                        :
                        {' '}
                        {error.code}
                    </p>
                )}
            </Container>
        );
    }

    return (
        <Container>
            <Table
                data={opportunities}
                keyField="id"
                isLoading={isLoadingOpportunities}
            >
                <Column
                    header="Opportunity"
                    field="id"
                    component={OpportunityColumn}
                />
                <Column
                    header="Stage"
                    field="stageId"
                    component={StageColumn}
                    defaultWidth={200}
                />
                <Column
                    header="Last Updated"
                    field="updatedAt"
                    component={DateTimeColumn}
                    defaultWidth={210}
                    showRelativeDate
                    sortable
                />
            </Table>
            <PaginationContainer>
                <EntriesText>
                    Showing
                    {' '}
                    {firstItemOfActivePage}
                    {' '}
                    to
                    {' '}
                    {lastItemOfActivePage}
                    {' '}
                    of
                    {' '}
                    {totalOpportunities}
                    {' '}
                    entries.
                </EntriesText>
                <Pagination
                    activePage={activePage}
                    pages={pages}
                    onChange={handlePageChange}
                />
            </PaginationContainer>
        </Container>
    );
};

export default CustomerDetailsOpportunitiesList;
