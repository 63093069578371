import { useCallback, useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import ButtonIcon from 'components/ButtonIcon';
import PhoneIcon from 'components/icons/phone';
import SmsIcon from 'components/icons/sms';
import OpportunityStuckBadge from 'components/OpportunityStuckBadge';
import RenderIfAdmin from 'components/RenderIfAdmin';
import { formatRelativeDate } from 'data/services/date/formatter';
import priceFormatter from 'data/services/price/formatter';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import { EntityGet } from 'data/firestore/types';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import useCustomerPanel from 'hooks/useCustomerPanel';
import useSMSChat from 'hooks/useSMSChat';
import { CUSTOMER_CALL_MODAL, OPPORTUNITY_DETAILS_DRAWER } from '../../../../../constants';
import getVariantFromScore from '../../../helpers/getVariantFromScore';
import { OpportunityCard as OpportunityCardProps } from '../types';
import {
    CardActions,
    CardBody,
    CardContainer,
    CardFooter,
    CardHeader,
    CardHeaderContent,
    CardTitle,
    CustomerName,
    LastUpdateDateContainer,
    StyledAlertCircle,
    StyledClockIcon,
    StyledBadgeOverlay,
    Score,
    PriceLabel,
    StyledUserAvatar,
    OwnerAvatar,
    StyledListTile,
} from './styled';

const OpportunityCard = ({
    title,
    ...props
}: OpportunityCardProps) => {
    const data = props as unknown as EntityGet<Opportunity>;
    const {
        id = '', firstName = '', lastName = '', alias = '', displayName: displayNameProp = '', photoURL, channel,
    } = data?.customer || {};

    const cardTitle = title || data?.name;

    const [openOpportunityDetailsModal] = useOpenModal(OPPORTUNITY_DETAILS_DRAWER);
    const [openCustomerCallModal] = useOpenModal(CUSTOMER_CALL_MODAL);
    const { openCustomerPanel } = useCustomerPanel();
    const { openChat } = useSMSChat();

    const handleSMSClick = useCallback(
        () => openChat({ ...(data?.customer || {}) }),
        [openChat, data],
    );

    const handleOpenDetails = useCallback(
        () => openOpportunityDetailsModal({ data: data.id }),
        [data, openOpportunityDetailsModal],
    );

    const handleOpenCustomerPanel = useCallback(
        () => openCustomerPanel({ data: data?.customer }),
        [data, openCustomerPanel],
    );

    const displayName = useMemo(
        () => getDisplayName({
            firstName,
            lastName,
            alias,
            displayName: displayNameProp,
        }),
        [firstName, lastName, alias, displayNameProp],
    );

    const customerInitials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName);
    }, [displayName]);

    const ownerDisplayName = useMemo(() => {
        if (data?.owner) {
            return getDisplayName(data?.owner);
        }
        return null;
    }, [data?.owner]);

    const ownerInitials = useMemo(() => {
        if (ownerDisplayName && ownerDisplayName !== 'Unknown') return getInitials(ownerDisplayName);
        return null;
    }, [ownerDisplayName]);

    const amount = useMemo(
        () => priceFormatter('usd').format((data?.amount || 0) / 100),
        [data?.amount],
    );

    const relativeDateValue = useMemo(
        () => data?.updatedAt && `Updated ${formatRelativeDate(data.updatedAt)}`,
        [data?.updatedAt],
    );

    const isStuck = useMemo(
        () => data?.stuck?.condition === 'stalled',
        [data],
    );

    return (
        <CardContainer isStuck={isStuck}>
            <CardHeader>
                <StyledUserAvatar
                    size="large"
                    initials={customerInitials}
                    src={photoURL}
                    value={id}
                    channel={channel}
                />
                <CardHeaderContent>
                    <CustomerName
                        type="button"
                        onClick={handleOpenCustomerPanel}
                    >
                        {displayName}
                    </CustomerName>
                    <CardTitle
                        type="button"
                        onClick={handleOpenDetails}
                    >
                        {cardTitle}
                    </CardTitle>
                </CardHeaderContent>
            </CardHeader>
            <CardBody>
                <LastUpdateDateContainer>
                    <RenderIf isTrue={!isStuck}>
                        <StyledClockIcon />
                    </RenderIf>
                    <RenderIf isTrue={isStuck}>
                        <StyledAlertCircle />
                    </RenderIf>
                    {relativeDateValue}
                    <OpportunityStuckBadge stuck={data?.stuck} />
                </LastUpdateDateContainer>
                <RenderIf isTrue={!!data?.owner}>
                    <StyledListTile
                        icon={(
                            <OwnerAvatar
                                size="x-small"
                                initials={ownerInitials}
                                src={data?.owner?.photoURL}
                                value={data?.owner?.uid}
                            />
                        )}
                        title={`${ownerDisplayName} (Owner)`}
                    />
                </RenderIf>
            </CardBody>
            <CardFooter>
                <PriceLabel>{amount}</PriceLabel>
                <CardActions>
                    <Score
                        variant={getVariantFromScore(data?.score)}
                        value={data?.score || 0}
                    />
                    <RenderIf isTrue={data?.customer.phoneNumber}>
                        <RenderIfAdmin>
                            <ButtonIcon
                                size="small"
                                icon={<PhoneIcon />}
                                onClick={() => openCustomerCallModal({ customer: data?.customer })}
                            />
                        </RenderIfAdmin>
                    </RenderIf>
                    <RenderIf isTrue={data?.customer?.phoneNumber}>
                        <ButtonIcon
                            size="small"
                            icon={(
                                <StyledBadgeOverlay
                                    overlap="circle"
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    isHidden={!data?.customer?.hasNewMessage}
                                >
                                    <SmsIcon />
                                </StyledBadgeOverlay>
                            )}
                            onClick={handleSMSClick}
                        />
                    </RenderIf>
                </CardActions>
            </CardFooter>
        </CardContainer>
    );
};

export default OpportunityCard;
