import { useEffect, useRef, useState } from 'react';
import {
    Outlet, useParams, useMatch, useNavigate,
} from 'react-router-dom';
import { useOpenModal } from '@rainbow-modules/hooks';
import WorkplaceProfile from 'components/WorkplaceProfile';
import ButtonIcon from 'components/ButtonIcon';
import { StyledBreadcrumb, StyledBreadcrumbs } from 'components/Breadcrumbs';
import InfoIcon from 'components/icons/info';
import ArrowLeftIcon from 'components/icons/arrowLeft';
import UserIcon from 'components/icons/user';
import OpportunityIcon from 'components/icons/opportunity';
import FilesIcon from 'components/icons/files';
import useWorkplace from 'data/firestore/agent/workplace/use';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import {
    Columns,
    Container,
    LeftColumn,
    RightColumn,
    StyledCard,
    StyledTabset,
    StyledTab,
    TabLabel,
    StyledButton,
    HeaderContainer,
} from './styled';
import { ADD_EDIT_WORKPLACE_MODAL } from '../../../constants';

const WorkplaceDetails = () => {
    const { agentId = '', workplaceId = '' } = useParams();
    const navigate = useNavigate();
    const navigateWithQuery = useNavigateWithQuery();
    const match = useMatch(':agentId/workplaces/:workplaceId/:tabName');
    const leftColumnRef = useRef<HTMLDivElement>(null);
    const [maxHeight, setMaxHeight] = useState<number>();

    const { data: workplaceData, isLoading } = useWorkplace(
        agentId,
        workplaceId,
        {
            disabled: !agentId || !workplaceId,
        },
    );
    const [editWorkplace] = useOpenModal(ADD_EDIT_WORKPLACE_MODAL);

    useEffect(() => {
        setMaxHeight(leftColumnRef.current?.clientHeight);
    }, []);

    return (
        <Container>
            <HeaderContainer>
                <ButtonIcon
                    icon={<ArrowLeftIcon />}
                    onClick={() => navigateWithQuery(`/${agentId}/workplaces`)}
                    size="small"
                />
                <StyledBreadcrumbs>
                    <StyledBreadcrumb
                        label="Workplaces"
                        onClick={() => navigate(`/${agentId}/workplaces`)}
                    />
                    <StyledBreadcrumb label="Workplace Details" />
                </StyledBreadcrumbs>
            </HeaderContainer>
            <Columns ref={leftColumnRef}>
                <LeftColumn>
                    <StyledCard>
                        <WorkplaceProfile
                            agentId={agentId}
                            workplaceId={workplaceId}
                            showAdditionalInfo
                            footer={(
                                <StyledButton
                                    label="Edit Workplace"
                                    variant="brand"
                                    shaded
                                    borderRadius="semi-rounded"
                                    onClick={() => editWorkplace({
                                        mode: 'edit',
                                        header: 'Edit workplace',
                                        workplaceData,
                                    })}
                                />
                            )}
                        />
                    </StyledCard>
                </LeftColumn>
                <RightColumn maxHeight={maxHeight}>
                    <StyledTabset
                        variant="line"
                        activeTabName={match?.params?.tabName}
                        onSelect={(event, eventName) => {
                            navigate(eventName);
                            event.preventDefault();
                        }}
                    >
                        <StyledTab
                            name="customers"
                            label={(
                                <TabLabel>
                                    <UserIcon />
                                    Customers
                                </TabLabel>
                            )}
                        />
                        <StyledTab
                            name="opportunities"
                            label={(
                                <TabLabel>
                                    <OpportunityIcon />
                                    Opportunities
                                </TabLabel>
                            )}
                        />
                        <StyledTab
                            name="notes"
                            label={(
                                <TabLabel>
                                    <FilesIcon />
                                    Notes
                                </TabLabel>
                            )}
                        />
                        <StyledTab
                            name="description"
                            label={(
                                <TabLabel>
                                    <InfoIcon />
                                    Description
                                </TabLabel>
                            )}
                        />
                    </StyledTabset>
                    <Outlet context={{ isLoading, workplace: workplaceData }} />
                </RightColumn>
            </Columns>
        </Container>
    );
};

export default WorkplaceDetails;
