import styled from 'styled-components';
import { CheckboxGroup } from 'react-rainbow-components';
import Button from 'components/Button';
import { color } from 'theme';
import { GREY_400, GREY_800, WHITE } from '../../constants';

export const StyledButton = styled(Button)`
    color: ${GREY_800};
    height: 48px;
    flex-shrink: 0;

    > svg {
        flex-shrink: 0;
    }
`;

export const DropDown = styled.div`
    display: flex;
    padding: 0.75rem 0 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 1rem;
    background: ${WHITE};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06), 0px 8px 24px 0px rgba(75, 70, 92, 0.16);
`;

export const LabelContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Header = styled.li`
    width: 100%;
    padding: 0 1rem 0.75rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.625rem; 
`;

export const StyledCheckBoxGroup = styled(CheckboxGroup)`
    padding: 0 1.5rem;

    & > div {
        gap: 0.25rem;
    }

    input[disabled] + label > span:first-child {
        background-color: ${color('brand.light')};
        border-color: ${color('brand.main')};
        color: ${color('brand.main')};
        
        &::after {
            border-color: ${color('brand.main')};
        }
    }

    label > span:last-child {
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        color: ${GREY_400};
    }
`;
