import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { orderBy, query } from 'firebase/firestore';
import { Column } from 'react-rainbow-components';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import DateTimeColumn from 'components/DateTimeColumn';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import useWebhookLogs from 'data/firestore/webhooks/logs/useCollectionWithPagination';
import { PAGE_SIZE, WEBHOOK_LOG_DETAILS_DRAWER } from '../../../../constants';
import {
    StyledDrawer, Container, PaginationContainer, EntriesText,
} from './styled';
import LogDetails from './LogDetails';
import EndpointColumn from './columns/endpoint';
import ReponseStatusColumn from './columns/status';
import { HeaderTitle } from '../Details/styled';
import { CardHeaderContainer } from '../styled';

const WebhookLogs = () => {
    const { agentId = '', webhookId = '' } = useParams();
    const [activePage, setActivePage] = useState(1);
    const connectLogDetailsProps = useConnectModal(WEBHOOK_LOG_DETAILS_DRAWER, {});
    const [, closeLogDetailsDrawer] = useOpenModal(WEBHOOK_LOG_DETAILS_DRAWER);

    const {
        data: logs,
        isLoading: isLoadingLogs,
        totalRecords: totalLogs,
        nextPage,
        prevPage,
    } = useWebhookLogs(
        webhookId,
        {
            disabled: !webhookId,
            limit: PAGE_SIZE,
            listQueryFn: (ref) => query(ref, orderBy('createdAt', 'desc')),
            track: [webhookId],
        },
    );

    const handlePageChange = useCallback(
        (event: React.ChangeEvent<unknown>, page: number) => {
            if (isLoadingLogs) return;
            if (page === activePage) return;
            if (page > activePage) nextPage();
            if (page < activePage) prevPage();
            setActivePage(page);
        },
        [activePage, isLoadingLogs, nextPage, prevPage],
    );

    const dataToShow = logs;
    const totalHits = totalLogs;
    const firstItemOfActivePage = dataToShow.length === 0 ? 0 : PAGE_SIZE * (activePage - 1) + 1;
    const lastItemOfActivePage = Math.min(
        PAGE_SIZE * activePage,
        (PAGE_SIZE * activePage - PAGE_SIZE) + dataToShow.length,
    );

    return (
        <>
            <Container>
                <CardHeaderContainer>
                    <HeaderTitle>
                        Requests Log
                    </HeaderTitle>
                </CardHeaderContainer>
                <Table isLoading={isLoadingLogs} data={logs} keyField="id" sortedBy="createdAt" sortDirection="desc">
                    <Column
                        header="Status"
                        field="response.status"
                        width={120}
                        component={ReponseStatusColumn}
                    />
                    <Column header="Endpoint" field="endpoint" cellAlignment="left" component={EndpointColumn} />
                    <Column header="Created At" field="createdAt" width={220} component={DateTimeColumn} />
                </Table>
                <PaginationContainer>
                    <EntriesText>
                        Showing
                        {' '}
                        {firstItemOfActivePage}
                        {' '}
                        to
                        {' '}
                        {lastItemOfActivePage}
                        {' '}
                        of
                        {' '}
                        {totalHits}
                        {' '}
                        entries.
                    </EntriesText>
                    <Pagination
                        activePage={activePage}
                        pages={0}
                        onChange={handlePageChange}
                    />
                </PaginationContainer>
            </Container>
            <StyledDrawer
                {...connectLogDetailsProps}
                header="Log Details"
                slideFrom="right"
                onRequestClose={closeLogDetailsDrawer}
            >
                <LogDetails
                    agentId={agentId}
                    webhookId={webhookId}
                    logId={connectLogDetailsProps.logId as string}
                />
            </StyledDrawer>
        </>
    );
};

export default WebhookLogs;
