import styled from 'styled-components';
import { GREY_50 } from '../../../constants';

export const TooltipContainer = styled.div`
    display: flex;
    width: 10.5rem;
    padding: 0.75rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    border-radius: 0.375rem;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.65) 100%), #8692D0;
    color: ${GREY_50};
`;
