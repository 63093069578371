import { LoadingShape, RenderIf } from 'react-rainbow-components';
import {
    Container, Header, Info, Filter, Chart, CardTitle, CardSubtitle, StyledLoading,
} from './styled';

interface Props {
    label: string;
    description?: string;
    filter?: React.ReactNode;
    children?: React.ReactNode;
    isLoading?: boolean;
    className?: string;
}

const InsightSectionContainer = ({
    label, description, filter, children, isLoading, className,
}: Props) => (
    <Container className={className}>
        <Header>
            <RenderIf isTrue={isLoading}>
                <LoadingShape shape="square" />
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                <Info>
                    <CardTitle>{label}</CardTitle>
                    <CardSubtitle>{description}</CardSubtitle>
                </Info>
                <RenderIf isTrue={!!filter}>
                    <Filter>
                        {filter}
                    </Filter>
                </RenderIf>
            </RenderIf>
        </Header>
        <Chart>
            <RenderIf isTrue={isLoading}>
                {
                    Array.from(Array(8).keys()).map((_, index) => (
                        <StyledLoading key={`ls_${index + 1}`} randomWidth />
                    ))
                }
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                {children}
            </RenderIf>
        </Chart>
    </Container>
);

export default InsightSectionContainer;
