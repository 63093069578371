import { useParams } from 'react-router-dom';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { ADD_EDIT_LLM_RESPONDER_CONFIG_MODAL } from '../../../constants';
import EditForm from './form';

const EditResponderConfigForm = () => {
    const { agentId = '' } = useParams();
    const connectedAddModifyProps = useConnectModal(ADD_EDIT_LLM_RESPONDER_CONFIG_MODAL);

    const [, closeModal] = useOpenModal(ADD_EDIT_LLM_RESPONDER_CONFIG_MODAL);
    const {
        responderData = {}, isOpen,
    } = connectedAddModifyProps;

    if (!isOpen) return null;
    return (
        <EditForm
            agentId={agentId}
            onRequestClose={closeModal}
            {...(responderData as object)}
        />
    );
};

export default EditResponderConfigForm;
