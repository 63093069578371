import { Button, RenderIf } from 'react-rainbow-components';
import { RecordField } from '@rainbow-modules/record';
import { useOpenModal } from '@rainbow-modules/hooks';
import useLog from 'data/firestore/webhooks/logs/useOnce';
import { Logs } from 'data/firestore/webhooks/logs/types';
import { EntityGet } from 'data/firestore/types';
import formatter from 'data/services/date/formatter';
import { REDELIVER_WEBHOOK_MODAL, WEBHOOK_LOG_DETAILS_DRAWER } from '../../../../../constants';
import formatHeaders from './helpers/formatHeaders';
import RequestResponseDetails from './requestResponseDetails';
import {
    HeaderContainer, HeaderTitle, LogDetailsContainer, RequestResponseContainer, Title,
    StyledRecordPrimaryDetails,
} from './styled';
import ResponseStatusBadge from '../ResponseStatusBadge';
import RedeliveredLabel from './redeliveredLabel';
import RedeliverModal from '../../RedeliverModal';

const LogDetails = ({
    agentId,
    webhookId,
    logId = '',
}: {
    agentId: string;
    webhookId: string;
    logId?: string;
}) => {
    const [
        openRedeliverWebhookModal,
    ] = useOpenModal(REDELIVER_WEBHOOK_MODAL);
    const [, closeLogDetailsDrawer] = useOpenModal(WEBHOOK_LOG_DETAILS_DRAWER);

    const { data, isLoading } = useLog(
        webhookId,
        logId,
        {
            disabled: !webhookId || !logId,
            track: [webhookId, logId],
        },
    );

    const {
        createdAt, endpoint, request, response, redelivered,
    } = (data || {}) as EntityGet<Logs>;

    const {
        headers: requestHeaders,
        body: requestBody,
    } = (request || {}) as Record<string, unknown>;
    const {
        headers: responseHeaders,
        body: responseBody,
        status: responseStatus,
    } = (response || {}) as Record<string, unknown>;

    const requestStringifyBody = JSON.stringify(requestBody, null, 2) || 'No request body found';
    const formattedRequestHeaders = formatHeaders(requestHeaders as Record<string, unknown>) || 'No request header found';

    const responseStringifyBody = JSON.stringify(responseBody, null, 2) || 'No response body found';
    const formattedResponseHeaders = formatHeaders(responseHeaders as Record<string, unknown>) || 'No response header found';

    return (
        <>
            <LogDetailsContainer>
                <HeaderContainer>
                    <HeaderTitle>
                        <b>POST</b>
                        <span title={endpoint}>{endpoint}</span>
                    </HeaderTitle>
                    <Button
                        size="small"
                        variant="outline-brand"
                        label="Redeliver"
                        borderRadius="semi-rounded"
                        onClick={() => openRedeliverWebhookModal({
                            agentId,
                            webhookId,
                            logId,
                            endpoint,
                            onRedelivered: closeLogDetailsDrawer,
                        })}
                    />
                </HeaderContainer>
                <StyledRecordPrimaryDetails>
                    <RecordField
                        label="Status"
                        value={`${responseStatus}`}
                        isLoading={isLoading}
                        component={ResponseStatusBadge}
                    />
                    <RecordField
                        label="Time"
                        value={formatter.format(createdAt)}
                        isLoading={isLoading}
                    />
                    <RenderIf isTrue={redelivered}>
                        <RecordField
                            label="Type"
                            value="redelivered"
                            isLoading={isLoading}
                            component={RedeliveredLabel}
                        />
                    </RenderIf>
                </StyledRecordPrimaryDetails>
                <RequestResponseContainer>
                    <Title>Request</Title>
                    <RequestResponseDetails
                        header={formattedRequestHeaders}
                        body={requestStringifyBody}
                        headerLabel="Request Header"
                        bodyLabel="Request POST body"
                    />
                    <Title>Response</Title>
                    <RequestResponseDetails
                        header={formattedResponseHeaders}
                        body={responseStringifyBody}
                        headerLabel="Response Header"
                        bodyLabel="Response body"
                    />
                </RequestResponseContainer>
            </LogDetailsContainer>
            <RedeliverModal />
        </>
    );
};

export default LogDetails;
