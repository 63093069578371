import React from 'react';

export interface Context {
    pipelineId?: string;
    isLoading: boolean;
}

const context = React.createContext<Context>({
    isLoading: false,
});

export const { Provider, Consumer } = context;

export default context;
