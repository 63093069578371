import { UniversalPicker } from '@rainbow-modules/forms';
import { CheckboxToggle } from 'react-rainbow-components';
import styled, { css } from 'styled-components';
import Brain from 'components/icons/brain';
import { DIVIDER, PURPLE_500 } from '../../../../constants';

export const StyledUniversalPicker = styled(UniversalPicker)`
    & > legend {
        padding: 0;
    }

    & > div {
        flex-direction: column;
        gap: 0.5rem;
    }

    & div[data-id="universal-picker-option"] {
        margin: 0;
        width: 25rem;
    }
`;

export const OptionContainer = styled.div<{
    isFocused: boolean;
    isHover: boolean;
    isSelected: boolean;
    disabled?: boolean;
}>`
    display: flex;
    padding: 0.75rem;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    border: 1px solid ${DIVIDER};
    border-radius: 0.375rem;
    width: 25rem;

    ${(props) => props.isHover && !props.disabled && css`
        border-color: ${PURPLE_500};
        color: ${PURPLE_500};
        cursor: pointer;
    `};

    ${(props) => props.isSelected && css`
        border-color: ${PURPLE_500};
        color: ${PURPLE_500};
    `};

    & > svg {
        width: 1.25rem;
        height: 1.25rem;
    }
`;

export const RetrieverHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 0.5rem;
`;

export const NameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const RetrieverName = styled.h3`
    margin: 0 0 0.25rem 0;
    font-size: 0.9375rem;
    font-weight: 600;
    color: ${PURPLE_500};
    letter-spacing: 0.01em;
    line-height: 1.3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
`;

export const RetrieverDescription = styled.p`
    margin: 0;
    font-size: 0.8125rem;
    line-height: 1.4;
    color: #5f6368;
    font-weight: 400;
    letter-spacing: 0.01em;
`;

export const BrainIcon = styled(Brain)`
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    color: ${PURPLE_500};
`;

export const StyledCheckbox = styled(CheckboxToggle)`
    pointer-events: none;
`;

export const VariableLabel = styled.span`
    text-transform: none;
    font-size: 0.8125rem;
    line-height: 1.4;
    color: #5f6368;
    font-weight: 400;
    letter-spacing: 0.01em;
    &::before {
        content: 'var: ';
        color:rgb(76, 77, 79);
        font-weight: 600;
    }
`;
