import {
    EmptyStateMessage, EmptyStateContainer, EmptyStateIcon, EmptyStateTitle,
    CenteredContent,
} from '../../styled';
import {
    MemoriesWrapper,
} from './styled';
import {
    Memory,
} from '../../types';
import MemoryCard from './MemoryCard';

interface MomeriesTableProps {
    memories: Memory[];
}

const MemoriesTable = ({ memories }: MomeriesTableProps) => (
    <MemoriesWrapper>
        {memories.length > 0 ? (
            memories.map((memory) => (
                <MemoryCard key={memory.id} value={memory} />
            ))
        ) : (
            <CenteredContent>
                <EmptyStateContainer>
                    <EmptyStateIcon>🔍</EmptyStateIcon>
                    <EmptyStateTitle>No Memories Found</EmptyStateTitle>
                    <EmptyStateMessage>
                        We couldn&apos;t find any memories matching your filters.
                        Try adjusting your filter criteria or selecting different
                        memory types to see more results.
                    </EmptyStateMessage>
                </EmptyStateContainer>
            </CenteredContent>
        )}
    </MemoriesWrapper>
);

export default MemoriesTable;
