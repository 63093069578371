import {
    EventsContainer,
    StyledBadge,
} from './styled';

const WebHookEvents = ({
    value = [],
}: {
    value?: string[];
}) => (
    <EventsContainer>
        {value.map((event) => (
            <StyledBadge label={event} borderRadius="semi-square" size="small" />
        ))}
    </EventsContainer>
);

export default WebHookEvents;
