import { useMemo } from 'react';
import SearchIcon from 'components/icons/search';
import DatePickerFilter from 'components/DatePickerFilter';
import Footer from 'components/Footer';
import useCustomSearchParams from 'hooks/useCustomSearchParams';
import ConversationsList from './ConversationsList';
import {
    SearchContainer, SearchInput, StyledCard,
} from './styled';
import { PAGE_SIZE } from '../../constants';

const Page = () => {
    const [searchParams, setSearchParams] = useCustomSearchParams({
        search: {
            type: 'string',
            default: '',
        },
        page: {
            type: 'number',
            default: 1,
        },
        pageSize: {
            type: 'number',
            default: PAGE_SIZE,
        },
        startAt: {
            type: 'number',
            default: undefined as unknown as number,
        },
        endAt: {
            type: 'number',
            default: undefined as unknown as number,
        },
    });

    const dateFilterValue = useMemo(() => {
        if (searchParams.startAt && searchParams.endAt) {
            return [new Date(searchParams.startAt), new Date(searchParams.endAt)];
        }
        if (searchParams.startAt) {
            return [new Date(searchParams.startAt)];
        }
        return [];
    }, [searchParams.startAt, searchParams.endAt]);

    const handleDateFilterChange = (dates: Date[]) => {
        if (dates.length === 0) {
            setSearchParams({
                startAt: undefined as unknown as number,
                endAt: undefined as unknown as number,
            });
        } else if (dates.length === 1) {
            setSearchParams({
                startAt: dates[0].getTime(),
                endAt: undefined as unknown as number,
            });
        } else {
            setSearchParams({
                startAt: dates[0].getTime(),
                endAt: dates[1].getTime(),
            });
        }
    };

    return (
        <>
            <StyledCard>
                <SearchContainer>
                    <SearchInput
                        type="search"
                        placeholder="Search conversation..."
                        variant="bare"
                        icon={<SearchIcon />}
                        borderRadius="semi-square"
                        value={searchParams.search}
                        onChange={(event) => setSearchParams({ search: event.target.value })}
                    />
                    <DatePickerFilter
                        placeholder="Select Date"
                        borderRadius="semi-square"
                        value={dateFilterValue}
                        onChange={handleDateFilterChange}
                    />
                </SearchContainer>
                <ConversationsList
                    search={searchParams.search}
                    startAt={searchParams.startAt}
                    endAt={searchParams.endAt}
                    page={searchParams.page}
                    pageSize={searchParams.pageSize}
                />
            </StyledCard>
            <Footer />
        </>
    );
};

export default Page;
