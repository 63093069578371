import { useState } from 'react';
import { Button, RenderIf } from 'react-rainbow-components';
import { CopyToClipboardButton } from '@rainbow-modules/record';
import {
    WebhookSecretInfoContainer, CopyToClipboarValue, Content,
} from './styled';

const WebHookSecret = ({
    value,
}: {
    value?: string;
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const label = isVisible ? 'Hide' : 'Show';

    return (
        <WebhookSecretInfoContainer>
            <Button size="small" variant="base" borderRadius="semi-square" label={label} onClick={() => setIsVisible(!isVisible)} />
            <RenderIf isTrue={!isVisible}>
                <CopyToClipboarValue>••••••</CopyToClipboarValue>
            </RenderIf>
            <RenderIf isTrue={isVisible}>
                <Content>
                    <CopyToClipboarValue>{value}</CopyToClipboarValue>
                    <CopyToClipboardButton variant="border-filled" size="small" shaded value={value} />
                </Content>
            </RenderIf>
        </WebhookSecretInfoContainer>
    );
};

export default WebHookSecret;
