import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { Memory } from 'data/firestore/agent/customer/memory/types';
import { MemoryFilter } from '../types';

interface UseMemoriesProps {
    filters: MemoryFilter;
}

type Request = Omit<MemoryFilter, 'customerId'>;
type Response = {
    total: number;
    hits: number;
    data: Memory[];
};

const useMemories = ({ filters }: UseMemoriesProps) => {
    const { agentId } = useParams();
    const [hits, setHits] = useState<number>(0);
    const [totalMemories, setTotalMemories] = useState<number>(0);
    const [memories, setMemories] = useState<Memory[]>([]);

    const { mutate, isLoading } = useHttpMutation<Request, Response>({
        method: 'POST',
        server: process.env.APP_BASE_URL,
        onSuccess: ({ data, hits: responseHits, total }) => {
            setHits(responseHits);
            setTotalMemories(total);
            setMemories(data);
        },
        onError: (error) => {
            console.error(error);
        },
    });

    useEffect(() => {
        mutate({
            pathname: `/agents/${agentId}/customers/${filters.customerId}/memories/search`,
            body: {
                types: filters.types,
                minImportance: filters.minImportance,
                maxImportance: filters.maxImportance,
                relativeTime: filters.relativeTime,
                segmentPrefix: filters.segmentPrefix,
                query: filters.query,
                limit: filters.limit,
            },
        });
    }, [
        mutate,
        agentId,
        filters.customerId,
        filters.types,
        filters.minImportance,
        filters.maxImportance,
        filters.relativeTime?.unit,
        filters.relativeTime?.value,
        filters.segmentPrefix,
        filters.query,
        filters.relativeTime,
        filters.limit,
    ]);

    return {
        memories,
        isLoading,
        hits,
        totalMemories,
    };
};

export default useMemories;
