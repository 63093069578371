import { ReactNode } from 'react';
import Eye from 'components/icons/eye';
import { Memory } from 'data/firestore/agent/customer/memory/types';
import { ItemUser } from 'data/firestore/globals';
import { EntityGet } from 'data/firestore/types';
import dateFormatter from 'data/services/date/formatter';
import getDisplayName from 'data/services/profile/getDisplayName';
import Light from 'components/icons/light';
import CheckIcon from 'components/icons/check';
import DocumentIcon from 'components/icons/document';
import Badge from 'components/Badge';
import {
    BodyContent, BodyTitle, IconContainer, ItemBody,
    LabelContainer, LabelDate, LabelMessage,
    StyledTimelineMarker,
} from './styled';

const iconMap: Record<Memory['type'], ReactNode> = {
    observation: (
        <IconContainer color="#7367F0" background="#F1F0FE">
            <Eye />
        </IconContainer>
    ),
    reflection: (
        <IconContainer color="#F2A356" background="#FEF6EE">
            <Light />
        </IconContainer>
    ),
    plan: (
        <IconContainer color="#19B332" background="#F4FDF5">
            <DocumentIcon />
        </IconContainer>
    ),
    fact: (
        <IconContainer color="#19B332" background="#F4FDF5">
            <CheckIcon />
        </IconContainer>
    ),
};

const typeLabelMap: Record<Memory['type'], ReactNode> = {
    observation: 'Observation',
    reflection: 'Reflection',
    plan: 'Plan',
    fact: 'Fact',
};

const formatSource = (memory: Memory) => {
    if (memory.source?.details) {
        const { user } = memory.source.details as { user: ItemUser };
        return <b>{` ${getDisplayName(user)} (${user.role})`}</b>;
    }
    return '...';
};

const getMessage = (memory: Memory) => {
    const messageParts = [<b>Sara</b>, ' created a new ', <b>{`"${typeLabelMap[memory.type || 'observation']}"`}</b>];
    messageParts.push((memory.type === 'plan' ? ' for' : ' about'));
    messageParts.push(formatSource(memory));
    return messageParts;
};

const ItemMemory = ({ memory }: { memory: EntityGet<Memory> }) => (
    <StyledTimelineMarker
        icon={iconMap[memory.type || 'observation']}
        label={(
            <LabelContainer>
                <LabelDate>
                    {dateFormatter.format(new Date(memory.timeMark || Date.now()))}
                </LabelDate>
                <LabelMessage>{getMessage(memory)}</LabelMessage>
            </LabelContainer>
        )}
        datetime={<Badge>{`Importance ${memory.importance ?? 0}`}</Badge>}
    >
        <ItemBody>
            <BodyTitle>Memory Content</BodyTitle>
            <BodyContent>{memory.content}</BodyContent>
        </ItemBody>
    </StyledTimelineMarker>
);

export default ItemMemory;
