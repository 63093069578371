import styled, { css } from 'styled-components';
import { BadgeOverlay, ProgressCircular } from 'react-rainbow-components';
import Card from 'components/Card';
import AlertCircle from 'components/icons/alertCircle';
import ClockIcon from 'components/icons/clock';
import UserAvatar from 'components/UserAvatar';
import ListTile from 'components/ListTile';
import { Customer } from 'data/firestore/agent/customer/types';
import {
    GREY_100, GREY_400, GREY_800, WHITE,
} from '../../../../../constants';

export const CardContainer = styled(Card)<{ isStuck?: boolean }>`
    display: flex;
    padding: 0.875rem 0 0.25rem 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 1rem;
    background: #FFF;
    flex-shrink: 0;
    border: 2px solid transparent;

    ${(props) => props.isStuck && css`
        border: 2px solid #FADABB;        
    `};
`;

export const CardHeader = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 0 1rem;
    gap: 0.75rem;
    align-self: stretch;
`;

export const CardHeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 1rem 0 1.875rem;
    gap: 0.375rem;
`;

export const CardFooter = styled.div`
    display: flex;
    padding: 0.25rem 0.5rem 0 1.125rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    box-sizing: border-box;
    border-top: 1px solid #F5F5F5;
    height: 3rem;
`;

export const CardActions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
`;

export const CardTitle = styled.button`
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;
    padding: 0;
    margin: 0;
    color: ${GREY_800};
    background: none;
    border: none;
    &:hover {
        text-decoration: underline;
    }
`;

export const CustomerName = styled.button`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;

    text-align: left;
    padding: 0;
    margin: 0;
    color: ${GREY_400};
    background: none;
    border: none;
    &:hover {
        text-decoration: underline;
    }
`;

export const LastUpdateDateContainer = styled.div`
    display: flex;
    height: 1.5rem;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem; 
    margin-top: 0.5rem;
    color: #A5A2AD;    
`;

export const StyledAlertCircle = styled(AlertCircle)`
    transform: rotate(180deg);
    color: #C18245;
`;

export const StyledClockIcon = styled(ClockIcon)`
    width: 1.5rem !important;
    height: 1.5rem !important;
    color: #88888D;
`;

export const StyledBadgeOverlay = styled(BadgeOverlay)`
    & > span {
        width: 0.625rem;
        height: 0.625rem;
        box-sixing: border-box;
        border: 2px solid ${GREY_100};
        right: 0.325rem;
        border-radius: 100%;
    }
`;

export const Score = styled(ProgressCircular)`
    min-width: 1.75rem;
    width: 1.75rem;
    height: 1.75rem;
    flex-shrink: 0;

    svg {
        width: 1.6rem;
        height: 1.75rem;
        flex-shrink: 0;
        overflow: visible !important;
        
        circle {
            stroke-width: 4px;
        }
    }

    & > h1 {
        font-size: 1px;
        color: ${WHITE};
        &::after {
            color: ${GREY_400};
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 0.625rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.375rem;

            ${(props) => props.variant && css`
                content: '${props.value}';
            `}
        }
    }
    
    ${(props) => !props.variant && css`
        color: ${GREY_400};
        > h1 {
            color: ${WHITE};
            ::after {
                color: ${GREY_400};
                content: '-';
                position: absolute;
                left: 0;
                font-size: 0.625rem;
                width: 100%;
                text-align: center;
            }
        }
    `};
`;

export const PriceLabel = styled.span`
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.875rem;
`;

export const StyledUserAvatar = styled(UserAvatar)<{ channel?: Customer['channel'] }>`
    border: 4px solid #7367F0;
    svg {
        width: 2.5rem;
        height: 2.5rem;
    }

    ${(props) => props.channel === 'facebook' && css`
        border-color: #1877F2;
    `};
    
    ${(props) => props.channel === 'instagram' && css`
        border-color: #DD2A7B;
    `};

    ${(props) => props.channel === 'whatsapp' && css`
        border-color: #25D366;
    `};
    
    ${(props) => props.channel === 'widget_chatbot' && css`
        border-color: #2E2960;
    `};
`;

export const OwnerAvatar = styled(UserAvatar)`
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.125rem;

    svg {
        width: 0.875rem;
        height: 0.875rem;
    }

    & abbr {
        font-size: 0.7rem;
    }
`;

export const StyledListTile = styled(ListTile)`
    & > div ~ div > span {
        color: #AFAFB3;
        font-size: 13px;
    }
`;
