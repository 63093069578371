import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ImportRecordsFlow, MenuItem } from 'react-rainbow-components';
import { CompleteValue } from 'react-rainbow-components/components/ImportRecordsFlow';
import { useOpenModal } from '@rainbow-modules/hooks';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import Button from 'components/Button';
import ButtonMenu from 'components/ButtonMenu';
import ChevronDown from 'components/icons/chevronDown';
import SearchIcon from 'components/icons/search';
import useCustomSearchParams from 'hooks/useCustomSearchParams';
import useHttpMutation from 'data/firestore/useHttpMutation';
import validatePhoneNumber from 'data/services/phone/isValid';
import { ADD_EDIT_CUSTOMER_MODAL } from '../../../constants';
import { StyledCard } from '../styled';
import CustomersList from './customersList';
import { SearchContainer, SearchInput, StyledButtonGroup } from './styled';

const importSchema = {
    attributes: {
        phoneNumber: String,
        firstName: String,
        lastName: String,
        dob: Date,
        gender: String,
        sex: String,
        preferredLanguage: String,
        email: String,
        bio: String,
    },
};

const CustomersListPage = () => {
    const { agentId = '' } = useParams();
    const navigate = useNavigate();
    const [isOpenImport, setOpenImport] = useState(false);
    const [openDrawer] = useOpenModal(ADD_EDIT_CUSTOMER_MODAL);

    const [searchParams, setSearchParams] = useCustomSearchParams({
        search: {
            type: 'string',
            default: '',
        },
    });

    const openImportModal = useCallback(() => setOpenImport(true), []);
    const closeImportModal = useCallback(() => setOpenImport(false), []);

    const {
        mutateAsync: importCustomers,
    } = useHttpMutation<Record<string, unknown>, void>({
        pathname: `/agents/${agentId}/customers/import`,
        method: 'POST',
        onSuccess: closeImportModal,
    });

    const handleValidateRecord = useCallback((row: Record<string, unknown>) => {
        const error: Record<string, string> = {};
        const { preferredLanguage = '', sex = '', phoneNumber } = row as Record<string, string> || {};

        if (preferredLanguage && !['en', 'es'].includes(preferredLanguage)) {
            error.preferredLanguage = 'Preferred Language should be one of: en, es';
        }

        if (sex && !['male', 'meale', 'intersex', 'unknown'].includes(sex as string)) {
            error.sex = 'Sex should be one of: male, female, intersex, unknown';
        }

        if (!phoneNumber || !validatePhoneNumber(phoneNumber)) {
            error.phoneNumber = 'Invalid phone number';
        }
        return error;
    }, []);

    const handleImportRecords = useCallback(async (value: CompleteValue) => {
        showAppSpinner();
        try {
            await importCustomers({
                body: {
                    customers: value.data || [],
                },
            });
            showAppNotification({
                icon: 'success',
                title: 'Import in Progress',
                description: 'Your file has been uploaded successfully. We are processing your data in the background.',
                timeout: 5000,
            });
        } catch (error) {
            const message = (error as any)?.message || 'Something went wrong. Please try again.';
            showAppNotification({
                title: 'Error',
                description: message,
                icon: 'error',
                timeout: 5000,
            });
        }
        hideAppSpinner();
    }, [importCustomers]);

    return (
        <StyledCard>
            <SearchContainer>
                <SearchInput
                    type="search"
                    placeholder="Search customer..."
                    variant="bare"
                    icon={<SearchIcon />}
                    borderRadius="semi-square"
                    value={searchParams.search}
                    onChange={(event) => setSearchParams({ search: event.target.value })}
                />
                <StyledButtonGroup borderRadius="semi-rounded">
                    <Button
                        label="Add Customer"
                        variant="brand"
                        size="medium"
                        onClick={() => openDrawer({
                            header: 'Add a Customer',
                            onCompleted: (
                                customer: Record<string, unknown>,
                            ) => navigate(customer.id as string),
                        })}
                    >
                        New Customer
                    </Button>
                    <ButtonMenu
                        buttonVariant="brand"
                        menuAlignment="right"
                        menuSize="small"
                        icon={<ChevronDown />}
                    >
                        <MenuItem label="Import Customer from CSV" onClick={openImportModal} />
                        {/* <MenuItem label="Export Customers" /> */}
                    </ButtonMenu>
                </StyledButtonGroup>
            </SearchContainer>
            <CustomersList search={searchParams.search} />
            <ImportRecordsFlow
                isOpen={isOpenImport}
                actionType="add-records"
                schema={importSchema}
                onRequestClose={closeImportModal}
                validateRecordCallback={handleValidateRecord}
                onComplete={handleImportRecords}
                borderRadius="semi-rounded"
            />
        </StyledCard>
    );
};

export default CustomersListPage;
