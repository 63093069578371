import styled from 'styled-components';
import Card from 'components/Card';
import { GREY_800, DIVIDER } from '../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
    height: 45px;
`;

export const Columns = styled.div`
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    align-items: start;
    min-height: 100%;
    flex-grow: 1;
`;

export const LeftColumn = styled(Card)`
    max-width: 22.5rem;
    width: 100%;
    padding: 1.5rem;

    @media (max-width: 1023px) {
        max-width: unset;
    }
`;

export const RightColumn = styled(Card)`
    flex: 1;
`;

export const RightContent = styled.div`
    border-top: 1px solid ${DIVIDER};
    padding: 20px;
`;

export const ConversationCardTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${GREY_800};
    padding: 0.65rem 0.55rem 0.55rem 0.55rem;
    gap: 0.875rem;
`;
