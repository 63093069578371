import styled from 'styled-components';
import { Breadcrumb, Breadcrumbs } from 'react-rainbow-components';
import { GREY_300, GREY_800 } from '../../constants';

export const StyledBreadcrumbs = styled(Breadcrumbs)`
    align-items: center;
    padding: 4px 0 1rem 0;
    
    ol > li button {
        color: ${GREY_300};
    }
    
    ol > li:last-child {
        color: color: ${GREY_800};
        font-weight: 400;
    }
    
    ol > li:not(:first-child)::before {
        content: none;
    }

    ol > li:not(:last-child)::after {
        content: '/';
        padding: 5px;
        color: ${GREY_300};
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.5rem;
    }
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
    button {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        line-height: 1.5rem !important;
        color: color: ${GREY_800} !important;
    }
`;
