import styled from 'styled-components';
import { Slider } from 'react-rainbow-components';
import CustomerPicker from 'components/CustomerPicker';
import TeamMemberPicker from 'components/TeamMemberPicker';
import Input from 'components/Input';
import EntityTagSelect from 'components/EntityTagSelect';
import { GREY_400, DIVIDER, GREY_200 } from '../../constants';

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: stretch;
    gap: 1rem;
    padding: 1rem 0 1rem;

    & > div {
        flex: 1;
    }
`;

export const StyledInput = styled(Input)`
    flex: 1;
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    input {
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    label + div > span {
        color: ${GREY_400};
        width: 1.5rem;
        flex-shrink: 0;
        
        svg {
            width: 1.5rem !important;
            height: 1.5rem !important;
            font-size: 1.5rem !important;
        }        
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const StyledCustomerPicker = styled(CustomerPicker)`
    flex: 1;

    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const StyledMemberPicker = styled(TeamMemberPicker)`
    flex: 1;

    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const Message = styled.div`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_200};
    text-align: center;
    padding-top: 0.75rem;
`;

export const Row = styled.div`
    display: flex;
    flex: 0;
    align-items: stretch;
    gap: 1rem;

    & > div {
        flex: 1;
    }
`;

export const StyledSlider = styled(Slider)`
    input + span {
        width: unset;
    }
`;

export const StyledEntityTagSelect = styled(EntityTagSelect)`
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;
