import { FilterValue, SearchFilterItem, FilterLabel } from 'pages/MemoryManagement/Sidebar/CreateUpdateModal/styled';
import { Retriever, TIME_UNIT_OPTIONS } from 'pages/MemoryManagement/types';
import {
    SummaryContainer,
    SummaryTitle,
    Group,
    GroupTitle,
    Label,
    Value,
    MemoryTypeItem,
    ImportanceItem,
    TimeItem,
} from './styled';

type RetrieverSummaryCardProps = Pick<
Retriever,
'name' |
'types' |
'minImportance' |
'maxImportance' |
'limit' |
'relativeTime' |
'query' |
'segmentPrefix'
>;

const RetrieverSummaryCard = ({
    name,
    types,
    minImportance,
    maxImportance,
    limit,
    relativeTime,
    query,
    segmentPrefix,
}: RetrieverSummaryCardProps) => (
    <SummaryContainer>
        <SummaryTitle>{name}</SummaryTitle>

        <Group>
            <GroupTitle>Memory Types</GroupTitle>
            <MemoryTypeItem>
                <Label>Types:</Label>
                <Value>
                    {types?.map(
                        (type: string) => type.charAt(0).toUpperCase() + type.slice(1),
                    ).join(', ') || 'ALL'}
                </Value>
            </MemoryTypeItem>
        </Group>

        <Group>
            <GroupTitle>Importance & Limits</GroupTitle>
            <ImportanceItem>
                <Label>Importance:</Label>
                <Value>
                    {minImportance}
                    {' '}
                    -
                    {' '}
                    {maxImportance}
                </Value>
            </ImportanceItem>
            <ImportanceItem>
                <Label>Limit:</Label>
                <Value>
                    {limit}
                    {' '}
                    memories
                </Value>
            </ImportanceItem>
        </Group>

        <Group>
            <GroupTitle>Time Range</GroupTitle>
            <TimeItem>
                <Label>Time:</Label>
                <Value>
                    Last
                    {' '}
                    {relativeTime?.value}
                    {' '}
                    {TIME_UNIT_OPTIONS.find(
                        (option) => option
                            .value === relativeTime?.unit,
                    )?.label || relativeTime?.unit}
                </Value>
            </TimeItem>
        </Group>

        {(query || segmentPrefix) && (
            <Group>
                <GroupTitle>Search Parameters</GroupTitle>
                {query && (
                    <SearchFilterItem>
                        <FilterLabel>Query:</FilterLabel>
                        <FilterValue>{query}</FilterValue>
                    </SearchFilterItem>
                )}
                {segmentPrefix && (
                    <SearchFilterItem>
                        <Label>Segment:</Label>
                        <Value>{segmentPrefix}</Value>
                    </SearchFilterItem>
                )}
            </Group>
        )}
    </SummaryContainer>
);

export default RetrieverSummaryCard;
