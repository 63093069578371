import { useCallback, useMemo } from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import Trash from 'components/icons/trash';
import ModalBody from 'components/ModalBody';
import { Topic } from 'data/firestore/agent/topic/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import AlertTriangle from 'components/icons/alertTriangle';

const mapDataToFormValues = (data: Topic) => data;

const mapFormValuesToData = (values: Record<string, unknown>) => values;

const createDownloadLink = (data: Blob, filename: string) => {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

const useManageTopics = ({
    agentId,
    modalId,
    onCreated = () => {},
    onDeleted = () => {},
} : {
    agentId: string;
    modalId: string;
    onCreated?: (topic: Topic) => void
    onDeleted?: () => void;
}) => {
    const [openForm, closeForm] = useOpenModal(modalId);

    const { mutateAsync: createTopic } = useHttpMutation({
        method: 'POST',
        pathname: `/agents/${agentId}/topics`,
        onSuccess: closeForm,
    });

    const { mutateAsync: importTopics } = useHttpMutation({
        method: 'POST',
        pathname: `/agents/${agentId}/topics/import`,
        onSuccess: closeForm,
    });

    const { mutateAsync: exportTopics } = useHttpMutation({
        method: 'GET',
        pathname: `/agents/${agentId}/topics/export`,
        onSuccess: closeForm,
    });

    const { mutateAsync: updateTopic } = useHttpMutation({
        method: 'PATCH',
        onSuccess: closeForm,
    });

    const { mutateAsync: deleteTopic } = useHttpMutation({
        method: 'DELETE',
    });

    const handleCreateTopic = useCallback(
        (initialValues: Record<string, unknown> = {}) => openForm({
            title: 'Create New Topic',
            submitButtonLabel: 'Add',
            initialValues,
            onSubmit: async (values: Record<string, unknown>) => {
                showAppSpinner();
                try {
                    const topic = await createTopic({
                        body: mapFormValuesToData(values),
                    }) as Topic;
                    showAppNotification({
                        title: 'Success',
                        description: 'Topic added successfuly.',
                        icon: 'success',
                        timeout: 5000,
                    });
                    onCreated(topic);
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [createTopic, onCreated, openForm],
    );

    const handleEditTopic = useCallback(
        (data: Topic) => openForm({
            disabledFields: [],
            title: 'Edit topic',
            submitButtonLabel: 'Update',
            initialValues: mapDataToFormValues(data),
            onSubmit: async (values: Record<string, unknown>) => {
                showAppSpinner();
                try {
                    await updateTopic({
                        pathname: `/agents/${agentId}/topics/${data.id}`,
                        body: mapFormValuesToData(values),
                    });
                    showAppNotification({
                        title: 'Success',
                        description: 'Topic updated successfully.',
                        icon: 'success',
                        timeout: 5000,
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [openForm, agentId, updateTopic],
    );

    const handleDeleteTopic = useCallback(
        async (topicId: string) => {
            const result = await confirmModal({
                header: '',
                variant: 'destructive',
                question: (
                    <ModalBody
                        variant="destructive"
                        icon={<Trash />}
                        title="Remove topic?"
                        description="Once this action is confirmed, it cannot be undone."
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await deleteTopic({
                    pathname: `/agents/${agentId}/topics/${topicId}`,
                });
                showAppNotification({
                    title: 'Success',
                    description: 'Topic deleted successfuly.',
                    icon: 'success',
                    timeout: 5000,
                });
                onDeleted();
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [deleteTopic, agentId, onDeleted],
    );

    const handleImport = useCallback(
        async () => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = '.json';
            input.onchange = async (e) => {
                const file = (e.target as HTMLInputElement).files?.[0];
                if (!file) return;
                const reader = new FileReader();
                reader.onload = async (event) => {
                    const data = event.target?.result;
                    if (!data) return;

                    const result = await confirmModal({
                        header: '',
                        question: (
                            <ModalBody
                                title="Are you sure you want to import these topics?"
                                description="There are topics created that will be permanently removed from your assistant."
                                icon={<AlertTriangle />}
                                variant="warning"
                            />),
                        borderRadius: 'semi-square',
                        okButtonLabel: 'Import',
                        cancelButtonLabel: 'Cancel',
                        variant: 'neutral',
                    });
                    if (!result) return;

                    try {
                        showAppSpinner();
                        await importTopics({
                            body: JSON.parse(data as string),
                        });
                        showAppNotification({
                            title: 'Success',
                            description: 'Topics were successfully imported.',
                            icon: 'success',
                            timeout: 5000,
                        });
                    } catch (error) {
                        const message = (error as any)?.message || 'Something went wrong. Please try again.';
                        showAppNotification({
                            title: 'Error',
                            description: message,
                            icon: 'error',
                            timeout: 5000,
                        });
                    }
                    hideAppSpinner();
                };
                reader.readAsText(file);
            };
            input.click();
        },
        [importTopics],
    );

    const handleExport = useCallback(
        async () => {
            try {
                const res = await exportTopics({});
                createDownloadLink(
                    new Blob([JSON.stringify(res, null, 4)], { type: 'text/json' }),
                    'topics.json',
                );
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
        },
        [exportTopics],
    );

    return useMemo(
        () => ({
            create: handleCreateTopic,
            edit: handleEditTopic,
            remove: handleDeleteTopic,
            import: handleImport,
            export: handleExport,
            closeForm,
        }),
        [
            closeForm,
            handleCreateTopic,
            handleDeleteTopic,
            handleEditTopic,
            handleExport,
            handleImport,
        ],
    );
};

export default useManageTopics;
