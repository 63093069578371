import {
    useCallback, useMemo, useState,
} from 'react';
import { showAppNotification } from '@rainbow-modules/app';
import {
    RenderIf,
    MenuItem,
    Tabset,
} from 'react-rainbow-components';
import CustomerActivity from 'components/CustomerActivity';
import useCustomerActivity from 'data/hooks/useCustomerActivity';
import Close from 'components/icons/close';
import ListIcon from 'components/icons/list';
import Note from 'components/icons/note';
import Pencil from 'components/icons/pencil3';
import Trash from 'components/icons/trash';
import DotsVertical from 'components/icons/dotsVertical';
import UserAvatar from 'components/UserAvatar';
import Badge from 'components/Badge';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import { EntityGet } from 'data/firestore/types';
import { Stage } from 'data/firestore/agent/pipeline/stage/types';
import useSendNote from 'data/hooks/useSendNote';
import priceFormatter from 'data/services/price/formatter';
import getInitials from 'data/services/string/getInitials';
import ButtonMenu from 'components/ButtonMenu';
import ButtonIcon from 'components/ButtonIcon';
import StagePicker from 'components/OpportunityInformation/stage';
import OpportunityIcon from 'components/icons/opportunity';
import useManageOpportunities from 'data/hooks/useManageOpportunities';
import {
    DetailsContainer,
    Header,
    Row,
    StyledTab,
    TabLabel,
    StyledSendNoteForm,
    TabsetContainer,
    HeaderActions,
    NotesContainer,
    Gradient,
    ActivityContainer,
} from './styled';
import OpportunityDetailsWidget from './detailsWidget';

interface Params {
    agentId: string;
    pipelineStages?: EntityGet<Stage>[];
    data?: EntityGet<Opportunity>;
    // eslint-disable-next-line react/no-unused-prop-types
    isLoadingSummary?: boolean;
    summary?: {
        summary: string;
        updatedAt: string;
    };
    onRequestClose?: () => void;
}

const OpportunityDetails = ({
    agentId = '',
    isLoadingSummary = false,
    pipelineStages = [],
    data,
    summary,
    onRequestClose = () => {},
}: Params) => {
    const [isSendingNote, setSendingNote] = useState(false);
    const [activeTab, setActiveTab] = useState('details');

    const { id: customerId = '' } = data?.customer ?? {};

    const {
        edit: editOpportinity,
        remove: removeOpportunity,
        updateField: updateOpportunityField,
        move: moveOpportunityToPipeline,
    } = useManageOpportunities({
        agentId,
        onOpportunityDeleted: onRequestClose,
    });

    const { mutateAsync: sendNote } = useSendNote();

    const [messages, isLoadingMessages] = useCustomerActivity(
        agentId,
        (customerId || ''),
        {
            disabled: !agentId || !customerId,
            track: [agentId, customerId],
        },
    );

    const handleSendNote = useCallback(
        async (note: string, files: File[]) => {
            setSendingNote(true);
            try {
                await sendNote({
                    agentId,
                    opportunityId: data?.id,
                    customerId,
                    note,
                    files,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            setSendingNote(false);
        },
        [agentId, customerId, data?.id, sendNote],
    );

    const noteMessages = useMemo(
        () => messages.filter((message) => message.type === 'note' && message.opportunityId === data?.id),
        [messages, data?.id],
    );

    return (
        <DetailsContainer>
            <Header>
                <ButtonIcon icon={<Close />} onClick={onRequestClose} />
                <HeaderActions>
                    <StagePicker
                        value={data?.stageId}
                        onUpdate={updateOpportunityField(data?.id as string, 'stageId')}
                        pipelineStages={pipelineStages}
                    />
                    <RenderIf isTrue={data?.amount}>
                        <Badge size="large">{priceFormatter('usd').format((data?.amount || 0) / 100)}</Badge>
                    </RenderIf>
                    <RenderIf isTrue={data?.owner}>
                        <UserAvatar
                            value={data?.owner?.uid}
                            initials={getInitials(data?.owner?.displayName || '')}
                            src={data?.owner?.photoURL}
                            assistiveText={data?.owner?.displayName}
                            title={data?.owner?.displayName}
                        />
                    </RenderIf>
                    <ButtonMenu
                        menuAlignment="right"
                        menuSize="x-small"
                        buttonVariant="base"
                        icon={<DotsVertical />}
                    >
                        <MenuItem label="Edit" onClick={() => data && editOpportinity(data)} icon={<Pencil />} />
                        <MenuItem label="Switch pipeline" onClick={() => data && moveOpportunityToPipeline(data)} icon={<Pencil />} />
                        <MenuItem label="Remove" onClick={() => data && removeOpportunity(data?.id)} icon={<Trash />} />
                    </ButtonMenu>
                </HeaderActions>
            </Header>
            <TabsetContainer>
                <Tabset
                    variant="line"
                    activeTabName={activeTab}
                    onSelect={(e, selectedTab) => setActiveTab(selectedTab)}
                >
                    <StyledTab
                        name="details"
                        label={(
                            <TabLabel>
                                <OpportunityIcon />
                                Details
                            </TabLabel>
                        )}
                    />
                    <StyledTab
                        name="activity"
                        label={(
                            <TabLabel>
                                <ListIcon />
                                Activity
                            </TabLabel>
                        )}
                    />
                    <StyledTab
                        name="notes"
                        label={(
                            <TabLabel>
                                <Note />
                                Notes
                                <RenderIf isTrue={noteMessages.length > 0}>
                                    <Badge size="small">{noteMessages.length}</Badge>
                                </RenderIf>
                            </TabLabel>
                        )}
                    />
                </Tabset>
            </TabsetContainer>
            {activeTab === 'details' && (
                <Row>
                    <OpportunityDetailsWidget
                        agentId={agentId}
                        data={data}
                        isLoadingSummary={isLoadingSummary}
                        summary={summary}
                        onRequestClose={onRequestClose}
                    />
                </Row>
            )}
            {activeTab === 'activity' && (
                <ActivityContainer>
                    <CustomerActivity
                        agentId={agentId}
                        isLoading={isLoadingMessages}
                        messages={messages}
                        context="opportunity"
                    />
                </ActivityContainer>
            )}
            {activeTab === 'notes' && (
                <>
                    <ActivityContainer>
                        <CustomerActivity
                            agentId={agentId}
                            isLoading={isLoadingMessages}
                            messages={noteMessages}
                            context="opportunity"
                            emptyMessage="No notes have been sent"
                        />
                    </ActivityContainer>
                    <NotesContainer>
                        <Gradient />
                        <StyledSendNoteForm
                            onSend={handleSendNote}
                            disableSend={isSendingNote}
                        />
                    </NotesContainer>
                </>
            )}
        </DetailsContainer>
    );
};

export default OpportunityDetails;
