import { Textarea } from 'react-rainbow-components';
import styled from 'styled-components';
import { PURPLE_500, DIVIDER } from '../../constants';

const StyledTextarea = styled(Textarea)`
    display: flex;
    align-self: stretch;
    width: 100%;
    box-sizing: border-box;

    label {
        color: rgb(63, 73, 84);
        font-size: 0.875rem;
        line-height: 1.5;
        margin-bottom: 0.125rem;
        align-self: flex-start;
        box-sizing: border-box;
        margin-left: 1rem;
    }

    > div {
        border: none;
        box-shadow: none;
        box-sizing: border-box;
    }

    textarea {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid ${DIVIDER};
        padding: 0.5rem 0.875rem;

        :focus, :active {
            width: 100%;
            padding: 0.5rem 0.875rem;
            border: 2px solid ${PURPLE_500};            
        }

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export default StyledTextarea;
