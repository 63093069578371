import { Memoryretriever } from 'data/firestore/agent/memoryretriever/types';
import { Memory as BaseMemory } from 'data/firestore/agent/customer/memory/types';
import { EntityGet } from 'data/firestore/types';

export type Retriever = EntityGet<Memoryretriever>;
export type Memory = EntityGet<BaseMemory>;
type MemoryType = 'observation' | 'reflection' | 'plan' | 'fact';
export type MemoryFilter = Pick<
Retriever, 'minImportance' | 'maxImportance' | 'query' | 'segmentPrefix' | 'limit' | 'relativeTime'
> & {
    customerId: string | null;
    types: MemoryType[];
};
export interface TypeDistribution {
    type: MemoryFilter['types'];
    count: number;
}

export interface SegmentDistribution {
    segment: string;
    count: number;
}
export const TYPE_COLORS: Record<MemoryType, string> = {
    observation: '#4a6bff',
    reflection: '#ff6b4a',
    plan: '#2ecc71',
    fact: '#3498db',
};
export const TYPE_LABELS: Record<MemoryType, string> = {
    observation: 'Observation',
    reflection: 'Reflection',
    plan: 'Plan',
    fact: 'Fact',
};

export const MEMORY_TYPE_OPTIONS = [
    { value: 'observation', label: 'Observation' },
    { value: 'reflection', label: 'Reflection' },
    { value: 'plan', label: 'Plan' },
    { value: 'fact', label: 'Fact' },
];

export const TIME_UNIT_OPTIONS = [
    { value: 'days', label: 'Days' },
    { value: 'weeks', label: 'Weeks' },
    { value: 'months', label: 'Months' },
    { value: 'years', label: 'Years' },
];

export const DEFAULT_FILTER: MemoryFilter = {
    customerId: null,
    types: ['observation', 'reflection', 'plan', 'fact'],
    minImportance: 1,
    maxImportance: 10,
    query: '',
    relativeTime: {
        value: 1,
        unit: 'months',
    },
    segmentPrefix: '',
    limit: 20,
};
