import { useCallback, useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import CreateEditOpportunityStageForm from 'components/CreateEditOpportunityStageForm';
import ConfigOpportunityStageTrackingForm from 'components/ConfigOpportunityStageTrackingForm';
import KanbanBoard from 'components/KanbanBoard';
import { BoardCard, BoardColumn, CardMoveEvent } from 'components/KanbanBoard/types';
import { ViewContextType } from 'pages/Opportunities/types';
import useManageOpportunities from '../../../../data/hooks/useManageOpportunities';
import { BoardContainer } from './styled';
import StageSummary from './summary';
import StageInfo from './stageInfo';
import EmptySearchMessage from './emptySearchMessage';
import useBoardStorage from './hooks/useBoardStorage';
import OpportunityCard from './OpportunityCard/index';
import useBoardOpportunities from './hooks/useBoardOpportunities';

const OpportunitiesViewBoard = () => {
    const { agentId = '' } = useParams();
    const { isLoading: isLoadingOpportunities, opportunities } = useBoardOpportunities(agentId);

    const {
        selectedPipeline = '',
        stages,
        isLoading: isLoadingPipelineData,
        searchQuery,
    } = useOutletContext<ViewContextType>();

    const isLoading = isLoadingPipelineData || isLoadingOpportunities;

    const {
        collapsedColumns,
        expandColumn,
        collapseColumn,
    } = useBoardStorage({ pipelineId: selectedPipeline });

    const {
        create: createNewOpportunity,
        updateField: updateOpportunityField,
    } = useManageOpportunities({ agentId });

    const boardColumns = useMemo(
        () => stages?.map((stage, index) => ({
            name: stage.id,
            label: stage.name,
            removable: stage.removable && opportunities[stage.id]?.length === 0,
            draggable: stage.removable,
            editable: true,
            collapsed: collapsedColumns.includes(stage.name),
            position: index,
            data: stage,
        })),
        [collapsedColumns, opportunities, stages],
    );

    const isEmptyBoard = !isLoading && Object.values(opportunities).every(
        (columnCards) => columnCards.length === 0,
    );

    const handleAddNewOpportunity = useCallback(
        (columnName: string) => createNewOpportunity({
            pipeline: selectedPipeline,
            stageId: columnName,
        }),
        [createNewOpportunity, selectedPipeline],
    );

    const handleChangeOpportunityStage = useCallback(
        async ({ cardId, destinationColumn }: CardMoveEvent) => {
            try {
                await updateOpportunityField(cardId as string, 'stageId')(destinationColumn);
                return true;
            } catch (error) {
                // no catch
            }
            return false;
        },
        [updateOpportunityField],
    );

    const handleToggleColumn = useCallback(
        (column: BoardColumn) => (
            collapsedColumns.includes(column.label)
                ? expandColumn
                : collapseColumn
        )(column.label),
        [collapseColumn, expandColumn, collapsedColumns],
    );

    return (
        <BoardContainer>
            <RenderIf isTrue={isEmpty(searchQuery) || !isEmptyBoard}>
                <KanbanBoard
                    isLoading={Object.values(isLoading).some((value) => value)}
                    columns={boardColumns}
                    cards={opportunities as unknown as Record<string, BoardCard[]>}
                    cardComponent={OpportunityCard}
                    columnHeaderComponent={StageInfo}
                    columnFooterComponent={StageSummary}
                    onAddCardClick={handleAddNewOpportunity}
                    onCardMoved={handleChangeOpportunityStage}
                    onColumnCollapse={handleToggleColumn}
                    onColumnExpand={handleToggleColumn}
                />
            </RenderIf>
            <RenderIf isTrue={!isEmpty(searchQuery) && isEmptyBoard}>
                <EmptySearchMessage query={searchQuery} />
            </RenderIf>
            <CreateEditOpportunityStageForm />
            <ConfigOpportunityStageTrackingForm />
        </BoardContainer>
    );
};

export default OpportunitiesViewBoard;
