import { useParams } from 'react-router-dom';
import useCustomSearchParams from 'hooks/useCustomSearchParams';
import {
    orderBy, query, where,
} from 'firebase/firestore';
import { Column } from 'react-rainbow-components';
import useSessions from 'data/firestore/session/useCollectionWithPagination';
import DurationColumn from 'components/DurationColumn';
import DateTimeColumn from 'components/DateTimeColumn';
import Pagination from 'components/Pagination';
import Table from 'components/Table';
import useAlgoliaSearchData from 'data/algolia/useSearchData';
import { useEffect } from 'react';
import { ALGOLIA_INDEX_SESSIONS } from '../../../constants';
import {
    Container, EntriesText, PaginationContainer,
} from './styled';
import ConversationIdColumn from './columns/conversationId';
import UserColumn from './columns/user';
import StatusColumn from './columns/status';
import DirectionColumn from './columns/direction';
import HandledByColumn from './columns/handledBy';

interface ConversationsListProps {
    search: string;
    page: number;
    pageSize: number;
    startAt?: number;
    endAt?: number;
}

const buildAlgoliaFilters = (
    agentId: string,
    dates: Date[] = [],
) => {
    const filters = [];

    if (agentId) {
        filters.push(`agentId:${agentId}`);
    }

    if (dates.length > 1) {
        const [from, to] = dates;
        filters.push(`createdAt:${from.getTime()} TO ${to.getTime()}`);
    }

    return filters.join(' AND ');
};

const ConversationsList = ({
    search, page, pageSize, startAt, endAt,
}: ConversationsListProps) => {
    const { agentId = '' } = useParams<{ agentId: string }>();
    const {
        data: sessions,
        isLoading: isLoadingSessions,
        totalRecords: totalSessions,
        error,
    } = useSessions({
        limit: pageSize,
        listQueryFn: (ref) => query(ref, where('agentId', '==', agentId), orderBy('createdAt', 'desc')),
        track: [agentId],
        disabled: Boolean(
            search || page > 1,
        ),
    });

    const [searchParams, setSearchParams] = useCustomSearchParams({
        page: {
            type: 'number',
            default: 1,
        },
    });

    const algoliaData = useAlgoliaSearchData({
        search,
        activePage: page,
        pageSize: 10,
        indexName: ALGOLIA_INDEX_SESSIONS,
        filters: buildAlgoliaFilters(
            agentId,
            startAt && endAt ? [new Date(startAt), new Date(endAt)] : [],
        ),
        enabled: Boolean(search || (startAt && endAt) || page > 1),
    });

    const handlePageChange = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
        if (isLoadingSessions) return;
        setSearchParams({ page: pageNumber });
    };

    useEffect(() => {
        const totalPages = algoliaData?.nbPages;
        if (algoliaData.isLoading) return;
        if (totalPages === 0) {
            setSearchParams({ page: undefined }, true);
        }
        if (
            totalPages !== undefined
            && totalPages > 0
            && page > totalPages
        ) {
            setSearchParams({ page: 1 }, true);
        }
    }, [
        algoliaData?.nbPages,
        page,
        setSearchParams,
        algoliaData.isLoading,
    ]);

    if (error || (!sessions && !isLoadingSessions)) {
        return (
            <Container>
                <h1>Something went wrong</h1>
                {error?.message && (
                    <p>
                        {error.name}
                        :
                        {' '}
                        {error.code}
                    </p>
                )}
            </Container>
        );
    }
    const dataToShow = algoliaData?.hits || sessions;
    const totalHits = typeof algoliaData?.nbHits === 'number' ? algoliaData.nbHits : totalSessions;
    const pages = Math.ceil(totalHits / pageSize);
    const firstItemOfActivePage = dataToShow.length === 0
        ? 0
        : pageSize * (page - 1) + 1;
    const lastItemOfActivePage = Math.min(
        pageSize * page,
        (pageSize * page - pageSize) + dataToShow.length,
    );

    return (
        <Container>
            <Table
                data={dataToShow}
                keyField="id"
                isLoading={isLoadingSessions}
            >
                <Column header="ID" field="id" component={ConversationIdColumn} defaultWidth={120} />
                <Column header="Direction" field="direction" component={DirectionColumn} defaultWidth={120} />
                <Column header="Customer" component={UserColumn} />
                <Column header="Date" field="createdAt" component={DateTimeColumn} />
                <Column header="Duration" field="duration" component={DurationColumn} cellAlignment="right" defaultWidth={120} />
                <Column header="Handled By" component={HandledByColumn} />
                <Column header="Status" field="status" component={StatusColumn} defaultWidth={140} />
            </Table>
            <PaginationContainer>
                <EntriesText>
                    Showing
                    {' '}
                    {firstItemOfActivePage}
                    {' '}
                    to
                    {' '}
                    {lastItemOfActivePage}
                    {' '}
                    of
                    {' '}
                    {totalHits}
                    {' '}
                    entries.
                </EntriesText>
                <Pagination
                    activePage={searchParams.page}
                    pages={pages}
                    onChange={handlePageChange}
                />
            </PaginationContainer>
        </Container>
    );
};

export default ConversationsList;
