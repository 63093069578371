import styled from 'styled-components';
import Picklist from 'components/Picklist';
import { Chip } from 'react-rainbow-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
    position: relative;
`;

export const StyledPicklist = styled(Picklist)`
    align-self: stretch;
`;

export const LoadConfigButton = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    background-color: #f4f6f9;
    border: 1px solid #e3e5ed;
    color: #576574;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        background-color: #e3e5ed;
    }

    span {
        margin-left: 0.5rem;
    }
`;

export const ConfigDropdown = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #e3e5ed;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    margin-top: 0.25rem;
`;

export const ConfigItem = styled.div`
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #f4f6f9;
    }

    &:not(:last-child) {
        border-bottom: 1px solid #e3e5ed;
    }
`;

export const ConfigItemName = styled.div`
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 0.25rem;
`;

export const ConfigItemDescription = styled.div`
    font-size: 0.75rem;
    color: #576574;
`;

export const ValueContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    outline: none;
    cursor: pointer;

    &:hover,
    &:focus {
        outline: 0;
    }
`;

export const InnerContainer = styled.div`
    position: relative;
    display: flex;
`;

export const StyledChip = styled(Chip)<{ disabled?: boolean }>`
    background-color: white;
    padding: 0.4375rem 0.625rem 0.4375rem 0.75rem;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    width: 100%;
    height: 3.375rem;
    border-radius: 1rem;

    & > button[data-id="button-icon-element"] {
        margin-right: -0.3rem;
        border-radius: 0.375rem;

        &:hover {
            margin-right: -0.3rem;
            border-radius: 0.375rem;
        }
    }

    ${(props) => props.disabled && `
        background-color: #f0f0f0;
        cursor: default;
    `};
`;

export const ConfigName = styled.div`
    font-weight: 600;
    font-size: 14px;
    color: #2c3e50;
    line-height: 1.25;
`;

export const ConfigDescription = styled.div`
    font-size: 12px;
    color: #576574;
    line-height: 1.25;
`;
