import { useMemo } from 'react';
import { Chat } from 'data/firestore/agent/customer/chat/types';
import { EntityGet } from 'data/firestore/types';
import isSameDay from 'helpers/time/isSameDay';
import { ChatMessage, DisplayContext } from '../types';

const useGroupMessages = ({
    agentId,
    context,
    messages = [],
}: {
    agentId: string;
    messages?: EntityGet<Chat>[];
    context: DisplayContext;
}): ChatMessage[] => useMemo(
    () => messages
        .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
        .map((message, index) => ({
            ...message,
            agentId,
            context,
            showGroupDate: !isSameDay(message.createdAt, messages?.[index - 1]?.createdAt),
        })),
    [messages, agentId, context],
);

export default useGroupMessages;
