import { IconProps } from 'types';

const ExpandContentIcon = ({ title = 'Expand Content', className }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        height="32px"
        viewBox="0 -960 960 960"
        width="32px"
        fill="currentColor"
    >
        <title>{title}</title>
        <path d="M200-200v-240h80v160h160v80H200Zm480-320v-160H520v-80h240v240h-80Z" />
    </svg>
);

export default ExpandContentIcon;
