const formatHeaders = (headers: Record<string, unknown>) => {
    if (headers) {
        return Object.keys(headers).reduce((acc, currentValue) => {
            if (acc === '') {
                return `${acc} ${currentValue}: ${headers[currentValue]}`;
            }
            return `${acc}
 ${currentValue}: ${headers[currentValue]}`;
        }, '');
    }
    return '';
};

export default formatHeaders;
