import { useCallback } from 'react';
import { RenderIf, Spinner } from 'react-rainbow-components';
import { hideAppSpinner, showAppMessage, showAppSpinner } from '@rainbow-modules/app';
import useResponder from 'data/firestore/agent/llm/useOnce';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { BaseFormProps } from './types';
import FormFooter from './footer';
import Form from './form';
import { Container, Content } from './styled';

const mapResponderDataToFormValues = (data: Record<string, unknown>) => {
    const { name, description, featureSchema } = data || {};

    return {
        name,
        description,
        feature: featureSchema,
        skill: (featureSchema ? 'system' : 'open'),
    };
};

interface EditFormProps extends BaseFormProps {}

const EditForm = ({
    onRequestClose = () => {},
    agentId = '',
    ...responderInfo
}: EditFormProps) => {
    const { data, isLoading } = useResponder(
        agentId as string,
        responderInfo?.id as string,
        { disabled: !(agentId || responderInfo?.id) },
    );

    const responderData = (agentId ? data : responderInfo);

    const {
        mutateAsync: updateResponder,
    } = useHttpMutation<Record<string, unknown>, void>({
        pathname: `/agents/${agentId}/llms/${responderInfo?.id}`,
        method: 'PATCH',
        onSuccess: onRequestClose,
    });

    const handleSubmit = useCallback(async (values: Record<string, unknown>) => {
        try {
            showAppSpinner();
            await updateResponder({
                body: {
                    ...values,
                },
            });
        } catch (error) {
            // no catch
            showAppMessage({
                variant: 'error',
                message: (error as Error).message,
                timeout: 5000,
            });
        }
        hideAppSpinner();
    }, [updateResponder]);

    return (
        <Container>
            <Content>
                <RenderIf isTrue={isLoading}>
                    <Spinner size="large" variant="brand" type="arc" />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    <Form
                        id="responder-form"
                        initialValues={mapResponderDataToFormValues(
                            responderData as Record<string, unknown>,
                        )}
                        disabledFields={['skill', 'feature', 'type']}
                        hiddenFields={['skill', 'feature', 'type']}
                        onSubmit={handleSubmit}
                    />
                </RenderIf>
            </Content>
            <FormFooter
                formId="responder-form"
                submitDisabled={isLoading}
                submitButtonLabel="Update"
                onCancelClick={onRequestClose}
            />
        </Container>
    );
};

export default EditForm;
