/* eslint-disable react/jsx-no-undef */
import { Field as FinalFormField } from 'react-final-form';
import { isDefined, isEmpty } from '@rainbow-modules/validation';
import GenderPicker from 'components/GenderPicker';
import CalendarIcon from 'components/icons/calendarThin';
import InstagramIcon from 'components/icons/instagram';
import Facebook from 'components/icons/facebook';
import LanguagePicker from 'components/LanguagePicker';
import SexPicker from 'components/SexPicker';
import PronounPicker from 'components/PronounPicker';
import PhoneInput from 'components/PhoneInput';
import WorkplacePicker from 'components/WorkplacePicker';
import isValidPhoneNumber from 'data/services/phone/isValid';
import RenderIfAdmin from 'components/RenderIfAdmin';
import {
    FieldsSection,
    FieldSectionTitle,
    StyledInput,
    StyledDatePicker,
    StyledGoogleAddressLookup,
    StyledTextarea,
    StyledEntityTagSelect,
} from './styled';

const validatePhoneNumber = (
    value?: { countryCode: string, isoCode: string, phone: string },
) => {
    if (isEmpty(value?.phone)) {
        return undefined;
    }
    if (!isValidPhoneNumber(`${value?.countryCode}${value?.phone}`)) {
        return 'Invalid phone number';
    }
    return undefined;
};

const validateCustomPickValue = (
    value?: { name: string, label: string, value: string } | string,
) => {
    if (typeof value !== 'string' && isDefined(value) && value?.name === 'custom' && !value.value) {
        return 'You must enter a value';
    }
    return undefined;
};

const Field = (props: any) => <FinalFormField parse={(value) => value} {...props} />;

const Fields = ({
    agentId,
    disabledFields = [],
}: {
    agentId: string;
    disabledFields?: string[];
}) => (
    <>
        <FieldsSection>
            <FieldSectionTitle>Personal Information</FieldSectionTitle>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledInput}
                name="firstName"
                label="First Name"
                labelAlignment="left"
                placeholder="John"
                borderRadius="semi-rounded"
                autoComplete="off"
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledInput}
                name="lastName"
                label="Last Name"
                labelAlignment="left"
                placeholder="Doe"
                borderRadius="semi-rounded"
                autoComplete="off"
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledInput}
                name="alias"
                label="Alias"
                labelAlignment="left"
                placeholder="John"
                borderRadius="semi-rounded"
                autoComplete="off"
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={PhoneInput}
                name="phoneNumber"
                label="Phone Number"
                labelAlignment="left"
                placeholder=""
                borderRadius="semi-rounded"
                validate={validatePhoneNumber}
                disabled={disabledFields.includes('phoneNumber')}
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledInput}
                name="email"
                label="Email"
                type="email"
                labelAlignment="left"
                placeholder="johndoe@gmail.com"
                borderRadius="semi-rounded"
                autoComplete="off"
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledGoogleAddressLookup}
                name="address"
                label="Address"
                labelAlignment="left"
                placeholder="45 Roker Terrace"
                borderRadius="semi-rounded"
                autoComplete="off"
            />
        </FieldsSection>
        <FieldsSection>
            <FieldSectionTitle>Social Media Profile</FieldSectionTitle>
            <Field
                component={StyledInput}
                name="facebookProfileURL"
                icon={<Facebook />}
                label="Facebook"
                labelAlignment="left"
                placeholder="Enter the Facebook profile"
                borderRadius="semi-rounded"
                autoComplete="off"
            />
            <Field
                component={StyledInput}
                name="instagramProfileURL"
                icon={<InstagramIcon />}
                label="Instagram"
                labelAlignment="left"
                placeholder="Enter the Instagram profile"
                borderRadius="semi-rounded"
                autoComplete="off"
            />
        </FieldsSection>
        <FieldsSection>
            <FieldSectionTitle>Additional Information</FieldSectionTitle>
            <Field
                component={StyledTextarea}
                name="bio"
                label="Bio"
                labelAlignment="left"
                placeholder="Enter bio"
                borderRadius="semi-rounded"
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={LanguagePicker}
                name="preferredLanguage"
                label="Preferred Language"
                labelAlignment="left"
                placeholder="Select preferred language"
                borderRadius="semi-rounded"
                allowDeselect
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledDatePicker}
                name="dob"
                label="DOB"
                formatStyle="medium"
                icon={<CalendarIcon />}
                labelAlignment="left"
                placeholder="Select date"
                borderRadius="semi-rounded"
                maxDate={new Date()}
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={GenderPicker}
                name="gender"
                label="Gender"
                labelAlignment="left"
                placeholder="Select gender"
                borderRadius="semi-rounded"
                validate={validateCustomPickValue}
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={SexPicker}
                name="sex"
                label="Sex"
                labelAlignment="left"
                placeholder="Select sex"
                borderRadius="semi-rounded"
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={PronounPicker}
                name="pronoun"
                label="Pronoun"
                labelAlignment="left"
                placeholder="Select pronoun"
                borderRadius="semi-rounded"
                validate={validateCustomPickValue}
            />
            <RenderIfAdmin>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={WorkplacePicker}
                    name="workplace"
                    label="Workplace"
                    labelAlignment="left"
                    placeholder="Select Workplace"
                    borderRadius="semi-rounded"
                    agentId={agentId}
                />
                <Field
                    component={StyledEntityTagSelect}
                    name="tags"
                    label="Tags"
                    labelAlignment="left"
                    placeholder="Select tags"
                    borderRadius="semi-rounded"
                    agentId={agentId}
                    entity="customer"
                />
            </RenderIfAdmin>
        </FieldsSection>
    </>
);

export default Fields;
