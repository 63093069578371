/* eslint-disable react/jsx-no-undef */
import { isDefined } from '@rainbow-modules/validation';
import { FormContainer, StyledUniversalForm } from './styled';
import Fields from './fields';

interface FormProps {
    id?: string,
    initialValues?: Record<string, unknown>,
    onSubmit?: (values: Record<string, unknown>) => void,
}

interface FormValues extends Record<string, unknown> {
    endpoint?: string;
    description?: string;
    secret?: string;
    events?: string[];
}

const mapFormValues = async (currentValues: FormValues) => {
    const {
        endpoint,
        description,
        secret,
        events,
    } = currentValues;

    const result: Record<string, unknown> = {
        endpoint,
        description,
        secret,
        events,
    };

    return Object.keys(result).reduce((acc: Record<string, unknown>, key) => {
        if (isDefined(result[key])) {
            acc[key] = result[key];
        }
        return acc;
    }, {});
};

const Form = ({
    id,
    initialValues,
    onSubmit,
}: FormProps) => {
    const onSubmitMiddleware = async (formValues: Record<string, unknown>) => {
        if (onSubmit) onSubmit(await mapFormValues(formValues as FormValues));
    };

    return (
        <FormContainer>
            <StyledUniversalForm
                id={id}
                onSubmit={onSubmitMiddleware}
                initialValues={initialValues}
            >
                <Fields />
            </StyledUniversalForm>
        </FormContainer>
    );
};

export default Form;
