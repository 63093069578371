import { StyledUniversalForm } from './styled';
import Fields from './fields';

const mapFormValues = (formValues: Record<string, unknown>) => {
    const {
        name, description, type, feature,
    } = formValues;
    return {
        name,
        description,
        type,
        featureSchema: (feature as Record<string, string>)?.name,
    };
};

interface FormProps {
    id?: string;
    initialValues?: Record<string, unknown>;
    onSubmit?: (values: Record<string, unknown>) => void;
    disabledFields?: string[];
    hiddenFields?: string[];
}

const Form = ({
    id,
    initialValues,
    disabledFields = [],
    hiddenFields = [],
    onSubmit,
}: FormProps) => {
    const onSubmitMiddleware = async (formValues: Record<string, unknown>) => {
        if (onSubmit) onSubmit(mapFormValues(formValues));
    };

    return (
        <StyledUniversalForm
            id={id}
            onSubmit={onSubmitMiddleware}
            initialValues={initialValues}
        >
            <Fields
                disabledFields={disabledFields}
                hiddenFields={hiddenFields}
            />
        </StyledUniversalForm>
    );
};

export default Form;
