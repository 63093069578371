import Inbox from 'components/icons/inbox';
import CalendarIcon from 'components/icons/calendar';
import Phone from 'components/icons/phone';
import Sms from 'components/icons/sms';
import Note from 'components/icons/note';
import Opportunity from 'components/icons/opportunity';
import User from 'components/icons/user';
import FacebookSolid from 'components/icons/facebookSolid';
import InstagramSolid from 'components/icons/instagramSolid';
import WindowIcon from 'components/icons/window';
import { Chat } from 'data/firestore/agent/customer/chat/types';
import Email from 'components/icons/email';
import WhatsAppSolid from 'components/icons/whatsAppSolid';

const getMessageIcon = (
    message: Chat,
    role?: string,
) => {
    const { type, content } = message;

    if (type === 'call' && role === 'customer') return <Phone />;
    if (type === 'action') {
        if (content.action === 'inbox-read') return <Inbox />;
        if (content.action === 'inbox-solved') return <Inbox />;
        if (content.action === 'inbox-removed') return <Inbox />;
        if (content.action === 'inbox-reopen') return <Inbox />;
        if (content.action === 'inbox-spam') return <Inbox />;
        if (content.action === 'inbox-not-spam') return <Inbox />;
    }
    if (type === 'customer-create') return <User />;
    if (type === 'customer-update') return <User />;
    if (type === 'note') return <Note />;

    if (
        [
            'calendar-event-created',
            'calendar-event-accepted',
            'calendar-event-rejected',
            'calendar-event-removed',
            'calendar-event-rescheduled',
        ].includes(type as string)
    ) {
        return <CalendarIcon />;
    }

    if (type === 'calendar-event-sms-sent') return <Sms />;

    if (type === 'opportunity-created') return <Opportunity />;

    if (type === 'opportunity-stage-changed') return <Opportunity />;

    if (type === 'opportunity-qualified') return <Opportunity />;

    if (type === 'opportunity-unqualified') return <Opportunity />;

    if (type === 'opportunity-amount-updated') return <Opportunity />;

    if (type === 'opportunity-removed') return <Opportunity />;

    if (['opportunity-assigned', 'opportunity-unassigned'].includes(type as string)) return <Opportunity />;

    if (type === 'message-sent') {
        switch (content.channel) {
            case 'facebook':
                return <FacebookSolid />;
            case 'instagram':
                return <InstagramSolid />;
            case 'whatsapp':
                return <WhatsAppSolid />;
            case 'widget_chatbot':
                return <WindowIcon />;
            default:
                return <Sms />;
        }
    }

    if (type === 'email-sent') return <Email />;

    return null;
};

export default getMessageIcon;
