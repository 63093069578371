import { CheckboxToggle } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import Trash from 'components/icons/trash';
import Pencil from 'components/icons/pencil2';
import { Webhooks } from 'data/firestore/webhooks/types';
import { EntityGet } from 'data/firestore/types';
import { ActionsColumnContainer } from './styled';

interface ActionsColumnProps {
    row?: EntityGet<Webhooks>;
    onToggle?: (webhook: EntityGet<Webhooks>) => void;
    onEdit?: (webhook: EntityGet<Webhooks>) => void;
    onDelete?: (webhook: EntityGet<Webhooks>) => void;
}

const ActionsColumn = ({
    row, onEdit, onDelete, onToggle,
}: ActionsColumnProps) => {
    if (!row) return null;

    return (
        <ActionsColumnContainer>
            <CheckboxToggle
                className="rainbow-m-right_small"
                value={row.status}
                labelAlignment="left"
                onClick={() => onToggle?.(row)}
            />
            <ButtonIcon
                borderRadius="semi-rounded"
                icon={<Pencil />}
                tooltip="Edit Webhook"
                onClick={() => onEdit?.(row)}
            />
            <ButtonIcon
                borderRadius="semi-rounded"
                icon={<Trash />}
                tooltip="Remove Webhook"
                onClick={() => onDelete?.(row)}
            />
        </ActionsColumnContainer>
    );
};

export default ActionsColumn;
