import { EntityGet } from 'data/firestore/types';
import { CopyToClipboardButton } from '@rainbow-modules/record';
import { Webhooks } from 'data/firestore/webhooks/types';
import {
    CellWrapper, UrlContainer, UrlLink, StyledListTile,
} from './styled';

interface Props {
    row?: EntityGet<Webhooks>;
}

const Name = ({ row }: Props) => (
    <CellWrapper>
        <StyledListTile
            title={row?.description}
            subtitle={(
                <UrlContainer>
                    <UrlLink to={`${row?.id}`}>{row?.endpoint}</UrlLink>
                    <CopyToClipboardButton value={row?.endpoint || ''} size="small" variant="base" />
                </UrlContainer>
            )}
        />
    </CellWrapper>
);
export default Name;
