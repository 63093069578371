import {
  collection,
  query,
  getDocs,
  getDocsFromServer,
} from "firebase/firestore";
import { db } from "../firebase";
import { EntityGet, EntityServerGet, ListServiceOpts } from "../types";
import { Responderfeatureschema } from "./types";
import { transformMetadata, processTimestampFields } from "../utils";

export type ResponderfeatureschemaListService<T> = (
  opts?: ListServiceOpts
) => Promise<Array<T>>;

const list: ResponderfeatureschemaListService<
  EntityGet<Responderfeatureschema>
> = async (opts = {}) => {
  const collectionRef = collection(db, `/responderfeatureschema`);
  const { disableCache } = opts;
  const q =
    typeof opts.listQueryFn === "function"
      ? opts.listQueryFn(collectionRef)
      : query(collectionRef);
  const getFn = disableCache ? getDocsFromServer : getDocs;
  const querySnapshot = await getFn(q);
  return querySnapshot.docs.map((doc) => {
    return processTimestampFields(
      transformMetadata({
        ...doc.data(),
        id: doc.id,
      } as EntityServerGet<Responderfeatureschema>)
    ) as EntityGet<Responderfeatureschema>;
  });
};

export default list;
