import { useMemo } from 'react';
import { Chat } from 'data/firestore/agent/customer/chat/types';
import useCustomerChatMessages from 'data/firestore/agent/customer/chat/useCollection';
import { EntityGet, ListQueryFn } from 'data/firestore/types';

const useCustomerActivity = (
    agentId: string,
    customerId: string,
    options: {
        disabled: boolean;
        track?: Array<string | number | boolean | undefined>;
        listQueryFn?: ListQueryFn;
    },
): [EntityGet<Chat>[], boolean] => {
    const { data: messages = [], isLoading } = useCustomerChatMessages(
        agentId,
        customerId,
        { ...options },
    );

    return useMemo(
        () => [messages, isLoading],
        [messages, isLoading],
    );
};

export default useCustomerActivity;
