import { useCallback } from 'react';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { Modal } from 'react-rainbow-components';
import Button from 'components/Button';
import ModalBody from 'components/ModalBody';
import { REDELIVER_WEBHOOK_MODAL } from '../../../../constants';
import useWebhooksHttpMutation from '../../hooks/useWebhooksHttpMutation';
import RedeliverWebhookModalContent from './body';

const RedeliverModal = () => {
    const {
        endpoint,
        agentId,
        webhookId,
        logId,
        onRedelivered = () => {},
        ...connectRedeliverWebhookProps
    } = useConnectModal(REDELIVER_WEBHOOK_MODAL);
    const [,closeRedeliverWebhookModal] = useOpenModal(REDELIVER_WEBHOOK_MODAL);

    const { mutateAsync: redeliver } = useWebhooksHttpMutation({
        pathname: `/universalwebhook/webhooks/${webhookId}/logs/${logId}/redelivers`,
        group: agentId as string,
        method: 'POST',
        onSuccess: () => {
            closeRedeliverWebhookModal();
            (onRedelivered as CallableFunction)();
        },
    });

    const handleRedeliver = useCallback(async () => {
        showAppSpinner();
        try {
            await redeliver({});
            showAppNotification({
                title: 'Success',
                description: 'The payload was redelivered successfully.',
                icon: 'success',
                timeout: 5000,
            });
        } catch (error) {
            const message = (error as any)?.message || 'Something went wrong. Please try again.';
            showAppNotification({
                title: 'Error',
                description: message,
                icon: 'error',
                timeout: 5000,
            });
        }
        hideAppSpinner();
    }, [redeliver]);

    return (
        <Modal
        // eslint-disable-next-line react/jsx-props-no-spreading
            {...connectRedeliverWebhookProps}
            size="large"
            title=""
            onRequestClose={closeRedeliverWebhookModal}
            footer={(
                <div className="rainbow-flex rainbow-justify_end">
                    <Button
                        label="Cancel"
                        variant="neutral"
                        borderRadius="semi-square"
                        onClick={closeRedeliverWebhookModal}
                    />
                    <Button label="Yes, redeliver this payload" variant="brand" borderRadius="semi-square" onClick={handleRedeliver} />
                </div>
            )}
        >
            <ModalBody title="Redeliver payload?" description={<RedeliverWebhookModalContent endpoint={endpoint as string} />} />
        </Modal>
    );
};
export default RedeliverModal;
