import styled from 'styled-components';
import {
    WHITE, GREY_800, GREY_300, PURPLE_100,
} from '../../constants';

export const CardContainer = styled.div`
    background-color: ${WHITE};
    border-radius: 0.625rem;
    padding: 1.25rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    flex: 1;
    min-width: 200px;
    position: relative;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 600;
    color: ${GREY_300};
    
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    }    
`;

export const Title = styled.h3`
  font-size: 1.75rem;
  font-weight: 600;
  color: ${GREY_300};
  margin-bottom: 0.25rem;
`;

export const Subtitle = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${GREY_300};
  margin-top: 0.25rem;
`;

export const Value = styled.div`
  font-size: 1.75rem;
  font-weight: 600;
  color: ${GREY_800};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PURPLE_100};
  border-radius: 50%;
  opacity: 0.7;
`;

interface TrendIndicatorProps {
    isPositive: boolean;
}

export const TrendIndicator = styled.span<TrendIndicatorProps>`
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: ${(props) => (props.isPositive ? '#34c759' : '#ff3b30')};
  color: ${WHITE};
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
