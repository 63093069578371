/* eslint-disable react/jsx-no-undef */
import { DateTime } from 'luxon';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { isDefined } from '@rainbow-modules/validation';
import { Geometry } from 'types';
import getLocation from 'helpers/getLocation';
import resolveTimezone from 'helpers/resolveTimezone';
import getE164Number from 'data/services/phone/getE164Number';
import { FormContainer, StyledUniversalForm } from './styled';
import Fields from './fields';

interface FormProps {
    id?: string,
    initialValues?: Record<string, unknown>,
    disabledFields?: string[],
    agentId: string;
    onSubmit?: (values: Record<string, unknown>) => void,
}

interface FormValues extends Record<string, unknown> {
    firstName?: string;
    lastName?: string;
    alias?: string;
    phoneNumber: Record<string, unknown>;
    email?: string;
    address?: Record<string, unknown>;
    preferredLanguage?: string;
    dob?: Date;
    gender?: PicklistValue | string;
    sex?: string;
    pronoun?: PicklistValue | string;
    workplace?: string;
}

const getPhoneValue = (value?: {
    countryCode?: string;
    isoCode?: string;
    phone?: string;
}) => (value?.phone ? getE164Number(`${value?.countryCode}${value?.phone}`) : '');

const formatDOB = (value?: Date) => {
    if (value) return DateTime.fromJSDate(value).toISODate() as string;
    return value;
};

const mapFormValues = async (currentValues: FormValues) => {
    const {
        phoneNumber,
        address,
        gender,
        pronoun,
        workplace,
        dob,
        ...rest
    } = currentValues;

    let newAddress;
    if (address) {
        const { lat, lng } = getLocation(address?.geometry as Geometry);
        newAddress = {
            formatted: address?.formatted_address,
            timezone: await resolveTimezone(lat, lng),
        };
    }

    const result: Record<string, unknown> = {
        ...rest,
        dob: formatDOB(dob),
        address: newAddress,
        phoneNumber: getPhoneValue(phoneNumber),
        gender: typeof gender === 'string' ? gender : gender?.value,
        pronoun: typeof pronoun === 'string' ? pronoun : pronoun?.value,
        workplaceId: workplace,
    };

    return Object.keys(result).reduce((acc: Record<string, unknown>, key) => {
        if (isDefined(result[key]) || result[key] === null) {
            acc[key] = result[key];
        }
        return acc;
    }, {});
};

const Form = ({
    id,
    initialValues,
    disabledFields = [],
    agentId,
    onSubmit,
}: FormProps) => {
    const onSubmitMiddleware = async (formValues: Record<string, unknown>) => {
        if (onSubmit) onSubmit(await mapFormValues(formValues as FormValues));
    };

    return (
        <FormContainer>
            <StyledUniversalForm
                id={id}
                onSubmit={onSubmitMiddleware}
                initialValues={initialValues}
            >
                <Fields disabledFields={disabledFields} agentId={agentId} />
            </StyledUniversalForm>
        </FormContainer>
    );
};

export default Form;
