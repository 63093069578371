import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { query, where } from 'firebase/firestore';
import KanbanBoard from 'components/KanbanBoard';
import { BoardCard, BoardColumn, ColumnMoveEvent } from 'components/KanbanBoard/types';
import countOpportunities from 'data/firestore/agent/opportunity/count';
import useManagePipelineStages from 'data/hooks/useManagePipelineStages';
import CreateEditOpportunityStageForm from 'components/CreateEditOpportunityStageForm';
import ConfigOpportunityStageTrackingForm from 'components/ConfigOpportunityStageTrackingForm';
import { EditContextType } from '../types';
import ColumnTitle from './columnTitle';

const EditPipelineStages = () => {
    const { agentId = '', pipelineId = '' } = useParams();
    const { stages } = useOutletContext<EditContextType>();
    const [cards, setCards] = useState<BoardCard[]>([]);

    useEffect(() => {
        (async () => {
            const cardsCountPromises = stages.map(async (stage) => {
                const count = await countOpportunities(agentId, {
                    listQueryFn: (q) => query(q, where('stageId', '==', stage.id)),
                });
                return Array.from({ length: count }).map((_, index) => ({
                    id: `${stage.id}-${index}`,
                    column: stage.id,
                }));
            });
            const cardsCount = await Promise.all(cardsCountPromises);
            setCards(cardsCount.flat());
        })();
    }, [agentId, stages]);

    const {
        create: createPipelineStage,
        edit: editPipelineStage,
        remove: removePipelineStage,
        reorder: reorderPipelineStage,
        config: configPipelineStage,
    } = useManagePipelineStages({
        agentId,
        pipelineId,
    });

    const handleReorderPipelineStage = useCallback(
        async ({ columnId, destinationPosition }: ColumnMoveEvent) => {
            try {
                await reorderPipelineStage(columnId as string, destinationPosition);
                return true;
            } catch (error) {
                // no catch
            }
            return false;
        },
        [reorderPipelineStage],
    );

    const handleAddNewColumn = useCallback(
        () => createPipelineStage({}),
        [createPipelineStage],
    );

    const handleEditColumn = useCallback(
        (column: BoardColumn) => editPipelineStage(
            stages?.find((stage) => stage.id === column.name) as any,
        ),
        [editPipelineStage, stages],
    );

    const handleDeleteColumn = useCallback(
        (column: BoardColumn) => removePipelineStage(column.name),
        [removePipelineStage],
    );

    const handleEditStageTracking = useCallback((columnName: string) => configPipelineStage(
        stages?.find((stage) => stage.id === columnName) as any,
    ), [configPipelineStage, stages]);

    const columns = useMemo(
        () => stages?.map((stage, index) => ({
            name: stage.id,
            label: stage.name,
            removable: stage.removable && !cards.find(
                (c) => c.column === stage.id,
            ),
            draggable: stage.removable,
            editable: true,
            collapsed: true,
            position: index,
            data: stage,
        })),
        [cards, stages],
    );

    return (
        <>
            <KanbanBoard
                columnEditMode
                columns={columns}
                // cards={cards}
                onColumnMoved={handleReorderPipelineStage}
                onAddColumnClick={handleAddNewColumn}
                onRequestColumnEdit={handleEditColumn}
                onRequestColumnDelete={handleDeleteColumn}
                onSettingsClick={handleEditStageTracking}
                columnHeaderComponent={ColumnTitle}
            />
            <CreateEditOpportunityStageForm />
            <ConfigOpportunityStageTrackingForm />
        </>
    );
};

export default EditPipelineStages;
