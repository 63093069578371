import styled from 'styled-components';
import { RecordField, RecordPrimaryDetails } from '@rainbow-modules/record';
import Badge from 'components/Badge';
import { GREY_300, GREY_50 } from '../../../../constants';

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow: hidden;
    padding: 1rem 0;
`;

export const HeaderContainer = styled.span`
    display: flex;
    max-width: 100%;
    align-self: stretch;
    justify-content: stretch;
    align-items: start;
    padding: 0 1.5rem 0.5rem;
    gap: 1rem;
`;

export const HeaderTitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    gap: 0.5rem;
    display: flex;
    flex: 1;
    align-items: center;

    b {
        color: ${GREY_300};
    }

    span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const Description = styled.span`
    display: flex;
    white-space: wrap;
`;

export const StyledRecordPrimaryDetails = styled(RecordPrimaryDetails)`
    margin: 0.5rem 1.5rem 0;
    padding: 0;
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.25rem;
    border: 1opx solid red;
`;
export const WebhookSecretInfoContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: -14px;
`;

export const CopyToClipboarValue = styled.span`
    display: flex;
    margin: 0 4px 0 8px;
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    letter-spacing: 2;
`;

export const Content = styled.div`
    background: ${GREY_50};
    border-radius: 4px 14px 14px 4px;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
`;

export const EventsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: flex-start;
    gap: 0.5rem;
    
    & > span {
        margin-left: 0;
    }
`;

export const StyledBadge = styled(Badge)`
    border-radius: 0.375rem;
    margin-left: 0 !important;
    user-select: none;
    text-transform: lowercase;
`;

export const StyledRecordField = styled(RecordField)`
    min-width: fix-content;
`;
