import { Outlet, useNavigate, useParams } from 'react-router-dom';
import BasicPageLayout from 'layouts/BasicPage';
import usePipelines from 'data/firestore/agent/pipeline/useCollectionOnce';
import { useEffect } from 'react';

const OpportunitiesPage = () => {
    const { agentId = '', pipelineId = '' } = useParams();
    const navigate = useNavigate();
    const { data: pipelines } = usePipelines(agentId, {
        disabled: !agentId || !!pipelineId,
    });

    useEffect(() => {
        if (!pipelineId && pipelines.length) {
            const [firstPipeline] = pipelines;
            const defaultPipeline = pipelines.find((pipeline) => pipeline.name === 'default') || firstPipeline;
            navigate(`/${agentId}/opportunities/${defaultPipeline.id}`);
        }
    }, [pipelines, pipelineId, navigate, agentId]);

    return (
        <BasicPageLayout>
            <Outlet />
        </BasicPageLayout>
    );
};

export default OpportunitiesPage;
