/* eslint-disable react/jsx-no-undef */
import { Field as FinalFormField } from 'react-final-form';
import { validateNotEmpty } from 'data/validators';
import { FieldsContainer, StyledInput, StyledTextarea } from './styled';

const Field = (props: any) => <FinalFormField parse={(value) => value} {...props} />;

const Fields = ({
    disabledFields = [],
}: {
    disabledFields?: string[];
}) => (
    <FieldsContainer>
        <Field
            component={StyledInput}
            name="name"
            label="Tag Name"
            labelAlignment="left"
            placeholder="Enter the topic name"
            borderRadius="semi-square"
            autoComplete="off"
            required
            disabled={disabledFields.includes('name')}
            validate={validateNotEmpty('This field is required')}
        />
        <Field
            component={StyledInput}
            name="displayName"
            label="Display Name"
            labelAlignment="left"
            placeholder="Enter a short name for the topic"
            borderRadius="semi-square"
            autoComplete="off"
            required
            disabled={disabledFields.includes('displayName')}
            validate={validateNotEmpty('This field is required')}
        />
        <Field
            component={StyledTextarea}
            name="description"
            label="Description"
            labelAlignment="left"
            placeholder="Enter a description for the topic"
            borderRadius="semi-rounded"
            required
            disabled={disabledFields.includes('description')}
            validate={validateNotEmpty('This field is required')}
        />
    </FieldsContainer>
);

export default Fields;
