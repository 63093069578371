import { useOutletContext } from 'react-router-dom';
import { ViewContextType } from 'pages/Opportunities/types';
import useBoardOpportunitiesFromFirestore from './useBoardOpportunitiesFromFirestore';
import useBoardOpportunitiesFromAlgolia from './useBoardOpportunitiesFromAlgolia';

const useBoardOpportunities = (agentId: string) => {
    const {
        selectedPipeline = '',
        stages = [],
        sortBy,
        activeFiltersCount = 0,
        searchQuery,
    } = useOutletContext<ViewContextType>();

    const { isLoading, data } = useBoardOpportunitiesFromFirestore(
        agentId,
        {
            disabled: !agentId || !selectedPipeline || !stages.length,
            track: [agentId, selectedPipeline, sortBy?.order, sortBy?.sortBy],
        },
    );

    const { data: algoliaData } = useBoardOpportunitiesFromAlgolia({
        agentId,
        enabled: activeFiltersCount > 0 || Boolean(searchQuery),
    });

    return {
        isLoading,
        opportunities: algoliaData ?? data,
    };
};

export default useBoardOpportunities;
