import { RenderIf } from 'react-rainbow-components';
import { SelectAlloptionContainer, Name, StyledBadge } from './styled';

const SelectAllEventsLabel = ({ selectedAmount = 0 }: { selectedAmount?: number; }) => (
    <SelectAlloptionContainer>
        <Name>Select all events</Name>
        <RenderIf isTrue={selectedAmount > 0}>
            <StyledBadge>{selectedAmount}</StyledBadge>
        </RenderIf>
    </SelectAlloptionContainer>
);

export default SelectAllEventsLabel;
