import { Field, useFormState } from 'react-final-form';
import { RadioGroup, RenderIf } from 'react-rainbow-components';
import Dollar from 'components/icons/dollar';
import PipelinePicker from 'components/PipelinePicker';
import PipelineStagePicker from 'components/PipelineStagePicker';
import RenderIfAdmin from 'components/RenderIfAdmin';
import { validateNotEmpty, validateNumber } from 'data/validators';
import {
    FieldsContainer,
    StyledInput,
    Message,
    Row,
    StyledCustomerPicker,
    StyledMemberPicker,
    StyledSlider,
    StyledEntityTagSelect,
} from './styled';

const parseNumber = (value: string) => (
    Number.isNaN(parseFloat(value))
        ? null
        : parseFloat(value)
);

interface Params {
    agentId?: string;
    message?: string;
    disabledFields?: string[],
    mode?: 'create' | 'edit' | 'move',
}

const rateOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
];

const Fields = ({
    disabledFields = [],
    mode = 'create',
    agentId = '',
    message = '',
}: Params) => {
    const { values } = useFormState();

    return (
        <>
            <Message>{message}</Message>
            <FieldsContainer>
                <RenderIf isTrue={mode !== 'move'}>
                    <Row>
                        <Field
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            component={StyledInput}
                            name="name"
                            label="Opportunity Name"
                            labelAlignment="left"
                            placeholder="Enter a name for the opportunity"
                            borderRadius="semi-square"
                            autoComplete="off"
                            required
                            validate={validateNotEmpty('This field is required')}
                        />
                    </Row>
                    <Row>
                        <Field
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            component={StyledCustomerPicker}
                            agentId={agentId}
                            name="customer"
                            label="Customer"
                            labelAlignment="left"
                            placeholder="Find a Customer"
                            borderRadius="semi-square"
                            disabled={disabledFields.includes('customer')}
                            required
                            validate={validateNotEmpty('This field is required')}
                        />
                    </Row>
                </RenderIf>
                <RenderIf isTrue={['create', 'move'].includes(mode)}>
                    <Row>
                        <Field
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            component={PipelinePicker}
                            agentId={agentId}
                            name="pipeline"
                            label="Opportunity Pipeline"
                            labelAlignment="left"
                            borderRadius="semi-square"
                            required
                            validate={validateNotEmpty('This field is required')}
                        />
                        <Field
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            component={PipelineStagePicker}
                            agentId={agentId}
                            pipelineId={values?.pipeline?.id}
                            orderInPipeline={values?.pipeline?.stages}
                            name="stageId"
                            label="Opportunity Stage"
                            labelAlignment="left"
                            borderRadius="semi-square"
                            required
                            validate={validateNotEmpty('This field is required')}
                        />
                    </Row>
                </RenderIf>
                <RenderIf isTrue={mode !== 'move'}>
                    <Row>
                        <Field
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            component={RadioGroup}
                            name="rate"
                            label="Rate Opportunity?"
                            labelAlignment="left"
                            options={rateOptions}
                            orientation="horizontal"
                        />
                    </Row>
                    <RenderIf isTrue={values.rate === 'yes'}>
                        <Row>
                            <Field
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                component={StyledSlider}
                                name="score"
                                label="Score"
                                required
                                labelAlignment="left"
                                min={0}
                                max={100}
                            />
                        </Row>
                    </RenderIf>
                    <Row>
                        <Field
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            component={StyledMemberPicker}
                            agentId={agentId}
                            name="owner"
                            label="Owner"
                            labelAlignment="left"
                            placeholder="Find a member"
                            borderRadius="semi-square"
                        />
                    </Row>
                    <Row>
                        <Field
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            component={StyledInput}
                            name="amount"
                            label="Amount"
                            labelAlignment="left"
                            placeholder="Ex: 1,000"
                            borderRadius="semi-square"
                            autoComplete="off"
                            icon={<Dollar />}
                            iconPosition="left"
                            parse={parseNumber}
                            validate={validateNumber('Must enter a valid number')}
                        />
                    </Row>
                    <RenderIfAdmin>
                        <Row>
                            <Field
                                component={StyledEntityTagSelect}
                                name="tags"
                                label="Tags"
                                labelAlignment="left"
                                placeholder="Select tags"
                                borderRadius="semi-rounded"
                                agentId={agentId}
                                entity="opportunity"
                            />
                        </Row>
                    </RenderIfAdmin>
                </RenderIf>
            </FieldsContainer>
        </>
    );
};

export default Fields;
