import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
    FirestoreError, limit, orderBy, OrderByDirection, query, Unsubscribe, where,
} from 'firebase/firestore';
import onSnapshotCollection from 'data/firestore/agent/opportunity/onSnapshotCollection';
import { ViewContextType } from 'pages/Opportunities/types';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import { EntityGet, HookOpts } from 'data/firestore/types';
import { isEmpty } from '@rainbow-modules/validation';
import { DEFAULT_SORT_OPPORTUNITIES } from '../../contants';

const useBoardOpportunitiesFromFirestore = (
    agentId: string,
    opts: HookOpts = {},
) => {
    const {
        selectedPipeline = '',
        stages = [],
        sortBy: sort = DEFAULT_SORT_OPPORTUNITIES,
    } = useOutletContext<ViewContextType>();
    const [data, setData] = useState<Record<string, EntityGet<Opportunity>[]>>({});
    const [isLoading, setLoading] = useState<Record<string, boolean>>({});
    const [error, setError] = useState<FirestoreError | null>(null);
    const { disabled = isEmpty(stages), track = [] } = opts;

    useEffect(() => {
        let unsubs: Unsubscribe[] = [];
        if (!disabled) {
            unsubs = stages.map((stage) => {
                setLoading((prevLoading) => ({ ...prevLoading, [stage.id]: true }));
                return onSnapshotCollection(
                    agentId,
                    {
                        listQueryFn: (ref) => query(
                            ref,
                            where('pipelineId', '==', selectedPipeline),
                            where('stageId', '==', stage.id),
                            where('removed', '==', false),
                            orderBy(sort.sortBy, sort.order as OrderByDirection),
                            limit(100),
                        ),
                    },
                    (docs) => {
                        setData((prev) => ({ ...prev, [stage.id]: docs }));
                        setLoading((prevLoading) => ({ ...prevLoading, [stage.id]: false }));
                    },
                    (queryError) => {
                        setLoading((prevLoading) => ({ ...prevLoading, [stage.id]: false }));
                        setError(queryError);
                    },
                );
            });
        }
        return () => {
            unsubs.forEach((unsub) => unsub());
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled, ...track]);

    return {
        isLoading,
        data,
        error,
    };
};

export default useBoardOpportunitiesFromFirestore;
