import { EntityGet } from 'data/firestore/types';
import { Llm } from 'data/firestore/agent/llm/types';
import { Responderfeatureschema } from 'data/firestore/responderfeatureschema/types';
import ResponderCard from './ResponderCard';

interface Props {
    agentId: string;
    responders: EntityGet<Llm>[];
    features: EntityGet<Responderfeatureschema>[];
}

const ResponderCards = ({ agentId, responders, features }: Props) => responders.map((responder) => (
    <ResponderCard
        key={responder.id}
        agentId={agentId}
        responder={responder}
        feature={features.find((feature) => feature.name === responder.featureSchema)}
    />
));

export default ResponderCards;
