import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import { Stage } from 'data/firestore/agent/pipeline/stage/types';
import priceFormatter from 'data/services/price/formatter';
import { ViewContextType } from '../../types';
import { PriceLabel, SummaryContainer, SummaryLabel } from './styled';
import { Column, OpportunityCard } from './types';

const StageSummary = ({
    cards = [],
    column,
}: {
    cards?: OpportunityCard[],
    column?: Column;
}) => {
    const { activeFiltersCount, searchQuery } = useOutletContext<ViewContextType>();

    const isFilterMode = (activeFiltersCount && activeFiltersCount > 0) || Boolean(searchQuery);

    const totalAmount = useMemo(
        () => {
            if (isFilterMode) {
                const total = cards.reduce(
                    (result, card: OpportunityCard) => {
                        const { amount } = card as unknown as Opportunity;
                        return result + (amount ?? 0);
                    },
                    0,
                );
                return priceFormatter('usd').format(total / 100);
            }

            const { totalAmount: totalStageAmount = 0 } = column?.data || {} as Stage;
            return priceFormatter('usd').format(totalStageAmount / 100);
        },
        [cards, column, isFilterMode],
    );

    if (isFilterMode) {
        return (
            <SummaryContainer>
                <PriceLabel>{`+${totalAmount}`}</PriceLabel>
            </SummaryContainer>
        );
    }

    return (
        <SummaryContainer>
            <PriceLabel>{totalAmount}</PriceLabel>
            <SummaryLabel>Total Value</SummaryLabel>
        </SummaryContainer>
    );
};

export default StageSummary;
