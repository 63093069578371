import styled, { css } from 'styled-components';
import InsightSectionContainer from 'components/InsightSectionContainer';
import PipelineSwitcher from 'components/PipelineSwitcher';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
    box-sizing: border-box;
    position: relative;
`;

export const InsightsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-self: stretch;
    height: 100%;
    overflow-y: auto;
    padding: 2rem 1.5rem 3rem 1.5rem;
    margin: -0.5rem -1.5rem 0 -1.5rem;
`;

export const StyledInsightSectionContainer = styled(InsightSectionContainer)<{ height?: string }>`    
    flex: 1;
    flex-direction: column;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    h2 {
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.25rem;
    }

    ${(props) => props.height && css`
        align-items: start;
        min-height: ${props.height};
    `};

    ${(props) => !props.isLoading && css`
        & > div:last-child {
            display: flex;
            flex: 1;
            align-self: stretch;
            margin-left: -1.5rem;
            margin-right: -1.5rem;
        }
    `};
`;

export const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;

export const Header = styled.div`
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    align-items: center;
    z-index: 8;
`;

export const Title = styled.h1`
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 900;
    line-height: 2.75rem;
`;

export const Actions = styled.div`
    display: flex;
    padding: 0.25rem;
`;

export const StyledPipelineSwitcher = styled(PipelineSwitcher)`
    input {
        border-radius: 12rem;
    }
`;
