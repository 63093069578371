import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    align-self: stretch;
    gap: 1.5rem;
    flex: 1;
`;
