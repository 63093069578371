import {
    ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Option } from 'react-rainbow-components';
import useRetrievalConfigs from 'data/firestore/agent/memoryretriever/useCollection';
import { EntityGet } from 'data/firestore/types';
import { Memoryretriever } from 'data/firestore/agent/memoryretriever/types';
import {
    Container,
    StyledPicklist,
} from './styled';
import SelectedValue from './value';
import { Retriver } from './types';

interface MemoryRetrieverPickerProps {
    agentId: string;
    id?: string;
    className?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    disabled?: boolean;
    onChange?: (retriever: Retriver | null) => void;
}

const MemoryRetrieverPicker = (props: MemoryRetrieverPickerProps) => {
    const {
        agentId,
        onChange = () => {},
        id,
        className,
        name,
        label = 'Retrieval Configuration',
        placeholder = 'Select a configuration',
        error,
        required,
        disabled,
        tabIndex,
    } = props;
    const [value, setValue] = useState<Retriver | null>(null);
    const {
        data: retrievers,
        isLoading,
    } = useRetrievalConfigs(agentId);

    const [query, setQuery] = useState<string>('');

    useEffect(() => {
        if (value) {
            const retriever = retrievers.find((ret) => ret.id === value.id);
            setValue(retriever ?? null);
            onChange(retriever ?? null);
        }
    }, [onChange, retrievers, value]);

    const handleOnChange = useCallback(
        (change: any) => {
            onChange(change.value);
            setValue(change.value);
        },
        [onChange],
    );

    const handleOnSearch = useCallback((currentQuery: string) => setQuery(currentQuery), []);

    const handleClearValue = useCallback(
        () => {
            onChange(null);
            setValue(null);
            setQuery('');
        },
        [onChange],
    );

    const picklistOptions = useMemo(
        () => {
            const match = (
                retriever: EntityGet<Memoryretriever>,
                fieldName: keyof EntityGet<Memoryretriever>,
            ) => {
                const field = retriever[fieldName];
                if (typeof field === 'string') {
                    return field.toLowerCase().includes(query.toLowerCase());
                }
                return false;
            };

            const filteredRetrievers = query.length > 0
                ? retrievers.filter(
                    (retriever) => match(retriever, 'name')
                        || match(retriever, 'description'),
                ) : retrievers;

            return filteredRetrievers.map((retriever) => (
                <Option
                    key={`retriever__${retriever.id}`}
                    label={retriever.name}
                    name={retriever.id}
                    value={retriever}
                    searchableText={`${retriever.name} ${retriever.description || ''}`}
                />
            ));
        },
        [retrievers, query],
    );

    if (value) {
        return (
            <SelectedValue
                id={id}
                className={className}
                label={label}
                required={required}
                disabled={disabled}
                value={value}
                onClearValue={handleClearValue}
            />
        );
    }

    return (
        <Container id={id} className={className}>
            <StyledPicklist
                name={name}
                label={label}
                labelAlignment="left"
                tabIndex={tabIndex}
                required={required}
                placeholder={placeholder}
                onChange={handleOnChange}
                error={error}
                disabled={disabled}
                enableSearch
                onSearch={handleOnSearch}
                debounce
                isLoading={isLoading}
            >
                <Option label="Select Configuration" variant="header" />
                {picklistOptions}
            </StyledPicklist>
        </Container>
    );
};

export default MemoryRetrieverPicker;
