import { ReactNode } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import useRedirectWhenNotAdmin from 'hooks/useRedirectWhenNotAdmin';
import BasicPageLayout from 'layouts/BasicPage';
import { ADD_EDIT_WEBHOOK_DRAWER } from '../../constants';
import { StyledDrawer } from './styled';
import CreateEditWebhookForm from './CreateEditWebhookForm';

type FormOpenMode = 'edit' | 'create';

const WebhooksPage = () => {
    const { agentId = '' } = useParams();
    const connectedAddModifyProps = useConnectModal(ADD_EDIT_WEBHOOK_DRAWER);

    const [, closeDrawer] = useOpenModal(ADD_EDIT_WEBHOOK_DRAWER);
    const {
        mode,
        header,
        isOpen,
        ...formProps
    } = connectedAddModifyProps;

    useRedirectWhenNotAdmin({
        redirectTo: `/${agentId}`,
    });

    return (
        <BasicPageLayout>
            <Outlet />
            <StyledDrawer
                isOpen={isOpen}
                header={header as ReactNode}
                size="small"
                slideFrom="right"
                onRequestClose={closeDrawer}
            >
                <CreateEditWebhookForm
                    mode={mode as FormOpenMode}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onRequestClose={closeDrawer}
                    {...formProps}
                />
            </StyledDrawer>
        </BasicPageLayout>
    );
};

export default WebhooksPage;
