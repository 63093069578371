import styled from 'styled-components';
import {
    Slider, CheckboxGroup,
} from 'react-rainbow-components';
import BrainIcon from 'components/icons/brain';
import Input from 'components/Input';
import {
    GREY_600,
    GREY_800,
    WHITE,
    PURPLE_500,
    GREY_300,
} from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    gap: 1rem;
    height: calc(100vh - 161px);
`;

export const StyledBrainIcon = styled(BrainIcon)`
    width: 3.5rem;
    height: 3.5rem;
    color: ${PURPLE_500};
`;

export const Sidebar = styled.div`
    width: 320px;
    background-color: ${WHITE};
    padding: 1.5rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    height: calc(100vh - 161px);
`;

export const ContentArea = styled.div`
    flex: 1;
    padding: 0;
    padding-right: 0.5rem;
    overflow-y: auto;
    background-color: transparent;
    height: calc(100vh - 96px);
`;

export const SectionTitle = styled.h2`
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
    color: ${GREY_800};
`;

export const MemoriesSectionTitle = styled.h2`
    font-size: 16px;
    font-weight: 600;
    padding: 16px;
    color: ${GREY_800};
`;

export const FilterSection = styled.div`
    margin-bottom: 24px;
`;

export const StyledCheckboxGroup = styled(CheckboxGroup)`
    margin-bottom: 16px;
`;

export const StyledSlider = styled(Slider)`
    margin-bottom: 16px;
`;

export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
`;

export const ModalButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const StatsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
`;

export const ChartContainer = styled.div`
    background-color: ${WHITE};
    border-radius: 0.625rem;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 0;
    height: 300px;
`;

export const ChartsRow = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    
    ${ChartContainer} {
        flex: 1;
        margin-bottom: 0;
    }
`;

export const MemoryListContainer = styled.div`
    background-color: ${WHITE};
    border-radius: 0.725rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 4rem;
    overflow: hidden;
`;

export const InputGroup = styled.div`
    margin-bottom: 16px;
`;

export const InputLabel = styled.label`
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    color: ${GREY_600};
`;

export const RelativeTimeContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 16px;
    
    > * {
        width: 50%;
        align-self: flex-end;
    }
`;

export const RelativeTimeInput = styled(Input)`
    flex: 1;
`;

export const ModalContent = styled.div`
    background-color: ${WHITE};
    border-radius: 8px;
    margin-top: 16px;
`;

export const CenteredContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: ${GREY_600};
    position: relative;
`;

export const EmptyStateContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 3rem;
    height: 100%;
    max-width: 500px;
    margin: 0 auto;
`;

export const EmptyStateIcon = styled.div`
    font-size: 4rem;
    margin-bottom: 1.5rem;
    background: linear-gradient(135deg, ${PURPLE_500}, #6a82fb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    animation: pulse 2s infinite ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
        width: 4.5rem;
        height: 4.5rem;
    }
    
    @keyframes pulse {
        0% { transform: scale(1); }
        50% { transform: scale(1.05); }
        100% { transform: scale(1); }
    }
`;

export const EmptyStateTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 600;
    color: ${GREY_800};
    margin-bottom: 1rem;
    position: relative;
    
    &::after {
        content: '';
        position: absolute;
        bottom: -0.5rem;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        height: 3px;
        background: linear-gradient(90deg, ${PURPLE_500}, #6a82fb);
        border-radius: 3px;
    }
`;

export const EmptyStateMessage = styled.p`
    font-size: 1rem;
    line-height: 1.6;
    color: ${GREY_600};
    max-width: 400px;
    margin-bottom: 1.5rem;
`;

export const LoadingText = styled.h6`
    font-size: 1rem;
    line-height: 1.6;
    color: ${GREY_300};
    font-weight: 200;
    margin-top: 5rem;
`;
