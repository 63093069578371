import { UniversalPickerOption } from '@rainbow-modules/forms';
import useHttpQuery from 'data/firestore/useHttpQuery';
import { useParams } from 'react-router-dom';
import { Badge, Spinner } from 'react-rainbow-components';
import { Retriever } from 'pages/MemoryManagement/types';
import { FieldTitle, FieldDescription } from 'components/Instructions/aiModelConfigForm/styled';
import {
    OptionContainer, RetrieverHeader, RetrieverName, RetrieverDescription,
    StyledUniversalPicker,
    BrainIcon,
    NameWrapper,
    StyledCheckbox,
    VariableLabel,
} from './styled';

const RetrieverOption = (option: {
    isFocused: boolean;
    isHover: boolean;
    isSelected: boolean;
    children: React.ReactNode;
}) => {
    const {
        isFocused, isHover, isSelected, children,
    } = option;
    return (
        <OptionContainer
            isFocused={isFocused}
            isHover={isHover}
            isSelected={isSelected}
        >
            <StyledCheckbox
                value={isSelected}
            />
            {children}
        </OptionContainer>
    );
};

interface MemoryRetrieverSelectorProps {
    memoryRetrieverIds: string[];
    onChange: (value: string[]) => void;
}

const MemoryRetrieverSelector = ({
    memoryRetrieverIds, onChange,
}: MemoryRetrieverSelectorProps) => {
    const { agentId } = useParams();
    const handleChange = (value: string | string[] | undefined) => {
        onChange(value as string[]);
    };

    const { data: retrievers = [], isLoading } = useHttpQuery<Retriever[]>({
        pathname: `/agents/${agentId}/memory-retrievers`,
        method: 'GET',
    });

    if (isLoading) {
        return (
            <Spinner
                assistiveText="Loading retrievers"
                size="large"
                type="arc"
            />
        );
    }

    return (
        <>
            <FieldTitle>Memory Retrievers</FieldTitle>
            <FieldDescription>
                Select the memory retrievers that will be used to retrieve information
                from the memory.
            </FieldDescription>
            <StyledUniversalPicker
                onChange={handleChange}
                value={memoryRetrieverIds}
                multiple
            >
                {retrievers?.map((retriever: Retriever) => (
                    <UniversalPickerOption
                        key={retriever.id}
                        name={retriever.id}
                        component={RetrieverOption}
                    >
                        <RetrieverHeader>
                            <BrainIcon />
                            <NameWrapper>
                                <RetrieverName>
                                    {retriever.name}
                                    {' '}
                                    <Badge
                                        label={(
                                            <VariableLabel>
                                                {retriever.variable}
                                            </VariableLabel>
                                        )}
                                        size="small"
                                        variant="lightest"
                                    />
                                </RetrieverName>
                                {retriever.description && (
                                    <RetrieverDescription>
                                        {retriever.description}
                                    </RetrieverDescription>
                                )}
                            </NameWrapper>
                        </RetrieverHeader>
                    </UniversalPickerOption>
                ))}
            </StyledUniversalPicker>
        </>
    );
};

export default MemoryRetrieverSelector;
