import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { RenderIf } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import Plus from 'components/icons/plus';
import KanbanBoardColumns from './columns';
import { Container, DroppableContainer, StyledLoadingShape } from './styled';
import { BoardCard, BaseBoardProps, BoardColumn } from './types';
import { Provider } from './context';

interface KanbanBoardProps extends BaseBoardProps {
    cards?: Record<string, BoardCard[]>;
    columnEditMode?: boolean;
}

const KanbanBoard = ({
    className,
    isLoading = false,
    columns: columnsProp = [],
    cards = {},
    columnEditMode = false,
    cardComponent,
    columnHeaderComponent,
    columnFooterComponent,
    onAddCardClick,
    onSettingsClick,
    onColumnExpand,
    onColumnCollapse,
    onRequestColumnEdit,
    onRequestColumnDelete,
    onAddColumnClick,
    onCardMoved = async () => true,
    onColumnMoved = async () => true,
}: KanbanBoardProps) => {
    const [cardsPerColumn, setCardsPerColumn] = useState<Record<string, BoardCard[]>>({});
    const [columns, setColumns] = useState<BoardColumn[]>(columnsProp);

    useEffect(
        () => {
            setCardsPerColumn(cards);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cards],
    );

    useEffect(
        () => {
            setColumns(columnsProp.map((column) => ({
                ...column,
                collapsed: column.collapsed || columnEditMode,
                draggable: column.draggable,
                editable: column.editable,
                removable: column.removable,
                cardsCount: cardsPerColumn[column.name]?.length || 0,
            })));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cardsPerColumn, columnsProp, columnEditMode],
    );

    const handleDropColumn = useCallback(
        async (result: DropResult) => {
            if (!result.destination || !columnEditMode) {
                return;
            }

            const columnId = result.draggableId;
            const sourcePosition = result.source.index;
            const destinationPosition = result.destination.index;

            if (sourcePosition === destinationPosition) {
                return;
            }

            const newColumns = [...columns];
            [
                newColumns[sourcePosition],
                newColumns[destinationPosition],
            ] = [
                newColumns[destinationPosition],
                newColumns[sourcePosition],
            ];

            setColumns(newColumns);

            await onColumnMoved({
                columnId,
                sourcePosition,
                destinationPosition,
            });
        },
        [columnEditMode, columns, onColumnMoved],
    );

    const handleDropCard = useCallback(
        async (result: DropResult) => {
            if (!result.destination || columnEditMode) {
                return;
            }

            const sourceColumnId = result.source.droppableId;
            const destinationColumnId = result.destination.droppableId;

            if (destinationColumnId === sourceColumnId) {
                return;
            }

            // // moving between lists
            const sourceColumn = cardsPerColumn[sourceColumnId];
            const destinationColumn = cardsPerColumn[destinationColumnId];
            const card = sourceColumn[result.source.index];
            card.column = destinationColumnId;
            sourceColumn.splice(result.source.index, 1);
            destinationColumn.splice(result.destination.index, 0, card);

            setCardsPerColumn({
                ...cardsPerColumn,
                [sourceColumnId]: [...sourceColumn],
                [destinationColumnId]: [...destinationColumn],
            });

            await onCardMoved({
                cardId: card.id,
                sourceColumn: sourceColumnId,
                destinationColumn: destinationColumnId,
            });
        },
        [cardsPerColumn, onCardMoved, columnEditMode],
    );

    if (isLoading) {
        return (
            <Container className={className}>
                <StyledLoadingShape width="18.75rem" height="100%" />
                <StyledLoadingShape width="18.75rem" height="100%" />
                <StyledLoadingShape width="18.75rem" height="100%" />
                <StyledLoadingShape width="18.75rem" height="100%" />
                <StyledLoadingShape width="18.75rem" height="100%" />
            </Container>
        );
    }

    if (columnEditMode) {
        return (
            <Provider value={{ columnEditMode }}>
                <Container className={className}>
                    <DragDropContext onDragEnd={handleDropColumn}>
                        <Droppable droppableId="columns-droppable" direction="horizontal">
                            {(provided) => (
                                <DroppableContainer
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    <KanbanBoardColumns
                                        columns={columns}
                                        cards={cardsPerColumn}
                                        cardComponent={cardComponent}
                                        columnHeaderComponent={columnHeaderComponent}
                                        columnFooterComponent={columnFooterComponent}
                                        onAddCardClick={onAddCardClick}
                                        onSettingsClick={onSettingsClick}
                                        onColumnExpand={onColumnExpand}
                                        onColumnCollapse={onColumnCollapse}
                                        onRequestColumnEdit={onRequestColumnEdit}
                                        onRequestColumnDelete={onRequestColumnDelete}
                                    />
                                    {provided.placeholder}
                                </DroppableContainer>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <RenderIf isTrue={columnEditMode}>
                        <ButtonIcon
                            icon={<Plus />}
                            borderRadius="semi-rounded"
                            tooltip="Add new column"
                            onClick={onAddColumnClick}
                            variant="neutral"
                        />
                    </RenderIf>
                </Container>
            </Provider>
        );
    }

    return (
        <Provider value={{ columnEditMode }}>
            <Container className={className}>
                <DragDropContext onDragEnd={handleDropCard}>
                    <KanbanBoardColumns
                        columns={columns}
                        cards={cardsPerColumn}
                        cardComponent={cardComponent}
                        columnHeaderComponent={columnHeaderComponent}
                        columnFooterComponent={columnFooterComponent}
                        onAddCardClick={onAddCardClick}
                        onSettingsClick={onSettingsClick}
                        onColumnExpand={onColumnExpand}
                        onColumnCollapse={onColumnCollapse}
                        onRequestColumnEdit={onRequestColumnEdit}
                        onRequestColumnDelete={onRequestColumnDelete}
                    />
                </DragDropContext>
            </Container>
        </Provider>
    );
};

export default KanbanBoard;
