import { RenderIf } from 'react-rainbow-components';
import useSms from 'data/firestore/sms/useOnce';

interface SMSInformationProps {
    smsId?: string;
}

const ReminderSMSInformation = ({
    smsId = '',
}: SMSInformationProps) => {
    const { data, isLoading } = useSms(smsId);

    const { body } = data || {};

    if (isLoading) return null;

    return (
        <>
            <RenderIf isTrue={!body}>
                <div>No message content</div>
            </RenderIf>
            <div>{body}</div>
        </>
    );
};

export default ReminderSMSInformation;
