/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useMemo, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import {
    CheckboxToggle, RenderIf, Slider, Tab, Textarea,
} from 'react-rainbow-components';
import ReactJson from 'react-json-view';
import { JsonInput, UniversalPickerOption } from '@rainbow-modules/forms';
import OpenAI from 'components/icons/openai';
import RadioItem from 'components/Instructions/aiModelConfigForm/radioItem';
import isBuiltinModel from 'components/Instructions/helpers/isBuiltinModel';
import {
    FieldTitle, FieldDescription, CreativityHelpContainer,
    StyledPickerOptionContent, StyledOptionLabel, StyledUniversalPicker, StyledInput,
} from 'components/Instructions/aiModelConfigForm/styled';
import tryParseJson from 'data/services/string/parseJson';
import Input from 'components/Input';
import validateJsonSchema from './helpers/validateJsonSchema';
import {
    JSONValueContainer,
    SchemaInputContainer, StyledTabHeaderButton, StyledTabset, TabContentContainer,
} from './styled';
import TabHeader from './tabHeader';
import GPT4ModelPicker from '../../../components/GPT4ModelPicker';
import validateTools from './helpers/validateTools';
import ToolPicker from './ToolPicker';
import MemoryRetrieverSelector from './MemoryRetrieverSelector';

const defaultOutputSchemaValue = `{
    "type": "object",
    "properties": {
        "content": { "type": "string" }
    },
    "required": ["content"]
}`;

const defaultInputSchemaValue = `{
    "type": "object",
    "properties": {}
}`;

const DEFAULT_GPT4_MODEL = 'gpt-4o-2024-05-13';

const isBuiltinGpt4Model = (modelId: string) => isBuiltinModel(modelId) && modelId.startsWith('gpt-4');

const ModelConfigFields = () => {
    const { change } = useForm();
    const { values, initialValues } = useFormState();
    const {
        model,
        modelId,
        jsonMode,
        tools = [],
    } = values;
    const canEditSchemas = useMemo(
        () => Boolean(!initialValues.featureSchema),
        [initialValues.featureSchema],
    );
    const [activeTab, setActiveTab] = useState('llm');

    useEffect(() => {
        // TODO: final-form accepts a `destroyOnUnregister` prop that can be used to
        // remove field data automatically when the field is unregistered.
        // But our UniversalForm does not support this prop yet.
        if (
            !['fine-tuned', 'gpt-4'].includes(model)
            || (model === 'fine-tuned' && isBuiltinGpt4Model(modelId))
        ) {
            change('modelId', undefined);
        }
        if (model === 'gpt-4' && !isBuiltinGpt4Model(modelId)) {
            change('modelId', DEFAULT_GPT4_MODEL);
        }
    }, [change, model, modelId]);

    useEffect(() => {
        if (canEditSchemas) {
            if (!jsonMode) {
                change('outputSchema', defaultOutputSchemaValue);
            } else {
                change('outputSchema', initialValues.outputSchema || JSON.parse(defaultOutputSchemaValue));
            }
        }
    }, [change, canEditSchemas, initialValues.outputSchema, jsonMode]);

    const handleResetInputSchema = () => change('inputSchema', defaultInputSchemaValue);

    return (
        <>
            <StyledTabset
                variant="line"
                activeTabName={activeTab}
                onSelect={(e, selectedTab) => setActiveTab(selectedTab)}
            >
                <Tab name="llm" label="LLM" />
                <Tab name="input" label="Input Schema" />
                <Tab name="output" label="Output Schema" />
                <Tab name="tools" label="Tools" />
                <Tab name="memory" label="Memory" />
                <Tab name="general" label="Info" />
            </StyledTabset>
            <TabContentContainer>
                <RenderIf isTrue={activeTab === 'llm'}>
                    <FieldTitle>Creativity</FieldTitle>
                    <FieldDescription>
                        Increase the creativity setting for imaginative content or
                        reduce it for more straightforward tasks.
                    </FieldDescription>
                    <Field
                        name="temperature"
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={Slider}
                        type="number"
                        min={0}
                        max={2}
                        step={0.01}
                    />
                    <CreativityHelpContainer>
                        <span>consistent</span>
                        <span>creative</span>
                    </CreativityHelpContainer>
                    <br />
                    <FieldTitle>Select Model</FieldTitle>
                    <FieldDescription>
                        Select an AI model tailored to your needs,
                        ranging from advanced and complex to simple
                        and direct for your assistant&apos;s interactions.
                    </FieldDescription>
                    <StyledUniversalPicker
                        name="model"
                        value={model}
                        onChange={(value: any) => change('model', value)}
                        direction="vertical"
                    >
                        <UniversalPickerOption component={RadioItem} name="gpt-3.5-turbo-0125">
                            <StyledPickerOptionContent>
                                <OpenAI />
                                <StyledOptionLabel>GPT-3.5</StyledOptionLabel>
                            </StyledPickerOptionContent>
                        </UniversalPickerOption>
                        <UniversalPickerOption component={RadioItem} name="gpt-4">
                            <StyledPickerOptionContent>
                                <OpenAI />
                                <StyledOptionLabel>GPT-4</StyledOptionLabel>
                            </StyledPickerOptionContent>
                        </UniversalPickerOption>
                        <UniversalPickerOption component={RadioItem} name="fine-tuned">
                            <StyledPickerOptionContent>
                                <OpenAI />
                                <StyledOptionLabel>Fine Tuned</StyledOptionLabel>
                            </StyledPickerOptionContent>
                        </UniversalPickerOption>
                    </StyledUniversalPicker>
                    <RenderIf isTrue={model === 'fine-tuned'}>
                        <Field
                            name="modelId"
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            component={StyledInput}
                            label="Model ID"
                            labelAlignment="left"
                            borderRadius="semi-square"
                            required
                        />
                    </RenderIf>
                    <RenderIf isTrue={model === 'gpt-4'}>
                        <Field
                            name="modelId"
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            component={GPT4ModelPicker}
                            label="Select GPT-4 model"
                            labelAlignment="left"
                            borderRadius="semi-square"
                            required
                        />
                    </RenderIf>
                </RenderIf>
                <RenderIf isTrue={activeTab === 'input'}>
                    <SchemaInputContainer>
                        <TabHeader
                            title="Input Schema"
                            description="Define the the input for this responder. The variables can be used on the instructions."
                            actions={(
                                // eslint-disable-next-line max-len
                                <RenderIf isTrue={values.inputSchema !== defaultInputSchemaValue && !values.featureSchema}>
                                    <StyledTabHeaderButton
                                        label="Use Default Schema"
                                        variant="base"
                                        size="small"
                                        borderRadius="semi-square"
                                        onClick={handleResetInputSchema}
                                    />
                                </RenderIf>
                            )}
                        />
                        <RenderIf isTrue={!values.featureSchema}>
                            <Field
                                name="inputSchema"
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                component={JsonInput}
                                validate={validateJsonSchema}
                                defaultValue={defaultInputSchemaValue}
                                disabled={Boolean(values.featureSchema)}
                            />
                        </RenderIf>
                        <RenderIf isTrue={Boolean(values.featureSchema)}>
                            <JSONValueContainer>
                                <ReactJson
                                    src={values.inputSchema}
                                    displayObjectSize={false}
                                    enableClipboard={false}
                                    displayDataTypes={false}
                                />
                            </JSONValueContainer>
                        </RenderIf>
                    </SchemaInputContainer>
                </RenderIf>
                <RenderIf isTrue={activeTab === 'output'}>
                    <SchemaInputContainer>
                        <TabHeader
                            title="Output Schema"
                            description="Define the output for this responder, it can be a text, a json..."
                            actions={(
                                <RenderIf isTrue={canEditSchemas}>
                                    <Field
                                        name="jsonMode"
                                        label="JSON Mode"
                                        labelAlignment="left"
                                        // @ts-ignore
                                        component={CheckboxToggle}
                                        disabled={Boolean(values.featureSchema)}
                                    />
                                </RenderIf>
                            )}
                        />
                        <RenderIf isTrue={canEditSchemas && jsonMode}>
                            <Field
                                name="outputSchema"
                                // @ts-ignore
                                component={JsonInput}
                                labelAlignment="left"
                                defaultValue={defaultOutputSchemaValue}
                                validate={validateJsonSchema}
                            />
                        </RenderIf>
                        <RenderIf isTrue={canEditSchemas && !jsonMode}>
                            <JSONValueContainer>
                                <ReactJson
                                    src={tryParseJson(defaultOutputSchemaValue)}
                                    displayObjectSize={false}
                                    enableClipboard={false}
                                    displayDataTypes={false}
                                />
                            </JSONValueContainer>
                        </RenderIf>
                        <RenderIf isTrue={!canEditSchemas}>
                            <JSONValueContainer>
                                <ReactJson
                                    src={values.outputSchema}
                                    displayObjectSize={false}
                                    enableClipboard={false}
                                    displayDataTypes={false}
                                />
                            </JSONValueContainer>
                        </RenderIf>
                    </SchemaInputContainer>
                </RenderIf>
                <RenderIf isTrue={activeTab === 'tools'}>
                    <SchemaInputContainer>
                        <TabHeader
                            title="All Tools"
                            description="Add a descriptive text here"
                            actions={(
                                <Field
                                    name="toolsChoice"
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    component={ToolPicker}
                                    tools={tools}
                                />
                            )}
                        />
                        <Field
                            name="tools"
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            component={JsonInput}
                            validate={validateTools}
                        />
                    </SchemaInputContainer>
                </RenderIf>
                <RenderIf isTrue={activeTab === 'memory'}>
                    <MemoryRetrieverSelector
                        memoryRetrieverIds={values.memoryRetrieverIds || []}
                        onChange={(value) => change('memoryRetrieverIds', value)}
                    />
                </RenderIf>
                <RenderIf isTrue={activeTab === 'general'}>
                    <Field
                        component={Input}
                        name="name"
                        label="Name"
                        labelAlignment="left"
                        placeholder="Enter a name for the AI Persona"
                        borderRadius="semi-rounded"
                        required
                        className="rainbow-m-bottom_medium"
                    />
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={Textarea}
                        name="description"
                        label="Description"
                        labelAlignment="left"
                        borderRadius="semi-rounded"
                        placeholder="Enter a description for the AI Persona"
                        className="rainbow-m-bottom_medium"
                    />
                </RenderIf>
            </TabContentContainer>
        </>
    );
};

export default ModelConfigFields;
