import {
    useCallback, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { ButtonOption, RenderIf, Spinner } from 'react-rainbow-components';
import { DateTime } from 'luxon';
import { ResponsiveBar } from '@nivo/bar';
import useHttpQuery from 'data/firestore/useHttpQuery';
import useAgentData from 'hooks/useAgentData';
import DateFilter from 'components/DateFilter';
import { Type } from 'components/DateFilter/types';
import { Content, StyledInsightSectionContainer } from '../styled';
import {
    StyledButtonGroupPicker, FiltersContainer, TotalLabel, ReportContainer, ChartContainer,
} from './styled';
import { ORANGE_300, PIPELINE_STAGE_INSIGHTS_DETAILS_DRAWER } from '../../../constants';
import formatDate from '../../../helpers/insights/formatDate';
import formatLabel from '../../../helpers/insights/formatLabel';
import PipelineStageInsightsDetailsDrawer from './DetailsDrawer';
import usePipelineStageInsightsDetails from './hooks/usePipelineStageInsightsDetails';
import BarTooltip from './BarTooltip';

interface PlotValue {
    date: string;
    label: string;
    count: number;
    customers: string[];
}

interface PlotData {
    values: PlotValue[];
}

interface PipelineStageInsightsProps {
    title?: string;
    description?: string;
    pipelineId?: string;
    stageName?: string;
    colors?: string[];
}

const PipelineStageInsights = ({
    title = '',
    description = '',
    pipelineId,
    stageName,
    colors = [ORANGE_300],
}: PipelineStageInsightsProps) => {
    const { agentId = '' } = useParams();
    const { agent, isLoading: isLoadingAgent } = useAgentData();
    const [unit, setUnit] = useState<Type>('month');
    const [dateFilter, setDateFilter] = useState<string | Date[]>('current-year');
    const drawerId = `${PIPELINE_STAGE_INSIGHTS_DETAILS_DRAWER}__${pipelineId}__${stageName}`;
    const { open: openDetails } = usePipelineStageInsightsDetails(drawerId);

    const handleChangeUnit = useCallback((newUnit: string | string[]) => {
        setUnit(newUnit as Type);
        setDateFilter((
            newUnit === 'day'
                ? 'current-month'
                : 'current-year'
        ));
    }, []);

    const { from, to } = useMemo(
        () => {
            const timeZone = agent?.companyAddress.timezone
                || Intl.DateTimeFormat().resolvedOptions().timeZone;

            const now = DateTime.now().setZone(timeZone);

            if (Array.isArray(dateFilter)) {
                const [fromDate, toDate] = dateFilter;
                return {
                    from: DateTime.fromJSDate(fromDate).setZone(timeZone, { keepLocalTime: true }).startOf('day'),
                    to: toDate && DateTime.fromJSDate(toDate).setZone(timeZone, { keepLocalTime: true }).endOf('day'),
                };
            }

            if (dateFilter === 'current-month') {
                return {
                    from: now.startOf('month'),
                    to: now.endOf('month'),
                };
            }

            if (dateFilter === 'last-month') {
                return {
                    from: now.minus({ month: 1 }).startOf('month'),
                    to: now.minus({ month: 1 }).endOf('month'),
                };
            }

            if (dateFilter === 'current-year') {
                return {
                    from: DateTime.now().setZone(timeZone).startOf('year'),
                    to: DateTime.now().setZone(timeZone).endOf('year'),
                };
            }

            const date = DateTime.fromISO(`${dateFilter}-01-01`).setZone(timeZone);

            return {
                from: date.startOf('year'),
                to: date.endOf('year'),
            };
        },
        [agent, dateFilter],
    );

    const { data, isLoading: isLoadingData } = useHttpQuery<PlotData>({
        key: [
            'insight-opportunities-count',
            agentId,
            pipelineId,
            stageName,
            from.toJSDate().toISOString().slice(0, 10),
            to?.toJSDate().toISOString().slice(0, 10),
            unit,
        ],
        pathname: `/agents/${agentId}/insights/opportunities/counts/${pipelineId}/stage/${stageName}?&start=${encodeURIComponent(from.toISO() || '')}&end=${encodeURIComponent(to?.toISO() || '')}&unit=${unit}`,
        queryOptions: {
            enabled: Boolean(
                agent?.id
                && pipelineId
                && stageName
                && from.toISO()
                && to?.toISO(),
            ),
            retryOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            gcTime: 24 * 60 * 60 * 1000,
            staleTime: 24 * 60 * 60 * 1000,
        },
    });

    const { total, max, plotData } = useMemo(
        () => (data?.values || []).reduce(
            (result, value) => ({
                total: result.total + value.count,
                max: Math.max(result.max, value.count),
                plotData: [
                    ...result.plotData,
                    {
                        ...value,
                        label: formatLabel({
                            label: value.label,
                            unit,
                            timeZone: agent?.companyAddress.timezone,
                        }),
                        date: formatDate({
                            dateStr: value.label,
                            unit,
                            timeZone: agent?.companyAddress.timezone,
                        }),
                    },
                ],
            }),
            {
                total: 0,
                plotData: [],
                max: 0,
            } as { total: number, max: number, plotData: PlotValue[] },
        ),
        [agent, data, unit],
    );

    const yLines = Array.from(new Set(Array.from(new Array(5)).map(
        (_, index) => Math.floor((index * max) / 4),
    ).sort((a, b) => a - b)));

    const isLoading = isLoadingAgent || isLoadingData;

    return (
        <StyledInsightSectionContainer
            height="26.75rem"
            label={title}
            description={description}
            filter={(
                <FiltersContainer>
                    <StyledButtonGroupPicker
                        value={unit}
                        onChange={handleChangeUnit}
                    >
                        <ButtonOption name="day" label="Daily View" disabled={isLoading} />
                        <ButtonOption name="month" label="Monthly View" disabled={isLoading} />
                    </StyledButtonGroupPicker>
                    <DateFilter
                        type={unit}
                        value={dateFilter}
                        onChange={setDateFilter}
                        disabled={isLoading}
                    />
                </FiltersContainer>
            )}
        >
            <Content>
                <RenderIf isTrue={isLoading}>
                    <Spinner size="large" variant="brand" type="arc" />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    <ReportContainer>
                        <TotalLabel>{`Total ${title}: ${total}`}</TotalLabel>
                        <ChartContainer>
                            <ResponsiveBar
                                data={plotData as any}
                                keys={['count']}
                                indexBy="label"
                                padding={(unit === 'day' ? 0.3 : 0.6)}
                                colors={colors}
                                borderRadius={4}
                                gridYValues={yLines}
                                axisTop={null}
                                axisRight={null}
                                axisLeft={{
                                    tickSize: 0,
                                    tickValues: yLines,
                                    tickPadding: total === 0 ? undefined : 4,
                                    format: (e) => (Math.floor(e) ? e : ''),
                                }}
                                axisBottom={{
                                    tickSize: 0,
                                    tickRotation: plotData.length > 12 ? -45 : 0,
                                }}
                                margin={{
                                    top: 24,
                                    right: 0,
                                    bottom: plotData.length > 28 ? 40 : 24,
                                    left: (total > 0 ? 32 : 0),
                                }}
                                groupMode="grouped"
                                enableLabel={false}
                                enableTotals={Boolean(total)}
                                labelTextColor="black"
                                motionConfig="wobbly"
                                minValue={0}
                                maxValue={total === 0 ? 1 : undefined}
                                onClick={(value) => openDetails({
                                    data: {
                                        title,
                                        subtitle: value.data.date,
                                        customers: value.data.customers,
                                    },
                                })}
                                tooltip={BarTooltip}
                            />
                        </ChartContainer>
                    </ReportContainer>
                </RenderIf>
            </Content>
            <PipelineStageInsightsDetailsDrawer drawerId={drawerId} />
        </StyledInsightSectionContainer>
    );
};

export default PipelineStageInsights;
