import styled from 'styled-components';
import Card from 'components/Card';
import { GREY_400, GREY_800 } from '../../../../constants';

export const StyledCard = styled(Card)`
    padding: 0.5rem;
    flex: 1;
    min-width: 450px;
    min-height: 200px;
    align-self: stretch;

    header {
        overflow: hidden;
        padding-right: 1rem;
        h2 {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    header+div {
        display: flex;
        gap: 0.5rem;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-self stretch;
    padding: 0 1.5rem 1rem;
    flex: 1;
    min-height: content;
    gap: 1rem;
    height: 100%;
`;

export const Title = styled.h2`
    font-weight: 500;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    text-align: left;
    line-height: 1.25rem;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${GREY_800};
    display: flex;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const Description = styled.p`
    color: ${GREY_400};
    font-weight: 300;
    font-size: 0.8125rem;
    line-height: 1.5;
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex: 1;
`;

export const Footer = styled.footer`

`;
