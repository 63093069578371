import { useParams } from 'react-router-dom';
import { Column, MenuItem } from 'react-rainbow-components';
import { query, where } from 'firebase/firestore';
import Footer from 'components/Footer';
import Button from 'components/Button';
import ButtonMenu from 'components/ButtonMenu';
import Plus from 'components/icons/plus';
import ChevronDown from 'components/icons/chevronDown';
import Table from 'components/Table';
import CreateEditTopicDrawer from 'components/CreateEditTopicDrawer';
import useManageTopics from 'data/hooks/useManageTopics';
import useTopics from 'data/firestore/agent/topic/useCollection';
import { ADD_EDIT_TOPIC_DRAWER } from '../../constants';
import {
    Container,
    StyledCard,
    Header,
    Info,
    CardTitle,
    CardSubtitle,
    TableContainer,
    StyledButtonGroup,
} from './styled';
import ActionsColumn from './columns/actions';

const TopicsManagementPage = () => {
    const { agentId = '' } = useParams();
    const {
        create: createTopic,
        import: importTopics,
        export: exportTopics,
    } = useManageTopics({
        agentId,
        modalId: ADD_EDIT_TOPIC_DRAWER,
    });
    const { data: topics = [], isLoading } = useTopics(agentId, {
        listQueryFn: (ref) => query(ref, where('removed', '==', false)),
        disabled: !agentId,
        track: [agentId],
    });
    return (
        <Container>
            <StyledCard>
                <Header>
                    <Info>
                        <CardTitle>Topics</CardTitle>
                        <CardSubtitle>Add descriptive description here</CardSubtitle>
                    </Info>
                    <StyledButtonGroup borderRadius="semi-rounded">
                        <Button variant="brand" size="medium" borderRadius="semi-square" onClick={() => createTopic()}>
                            <Plus className="rainbow-m-right_x-small" />
                            Add Topic
                        </Button>
                        <ButtonMenu
                            buttonVariant="brand"
                            menuAlignment="right"
                            menuSize="small"
                            icon={<ChevronDown />}
                        >
                            <MenuItem label="Import" onClick={() => importTopics()} />
                            <MenuItem label="Export" onClick={() => exportTopics()} />
                        </ButtonMenu>
                    </StyledButtonGroup>
                </Header>
                <TableContainer>
                    <Table
                        keyField="id"
                        data={topics}
                        isLoading={isLoading}
                    >
                        <Column header="Name" field="name" width={300} />
                        <Column header="Display Name" field="displayName" width={200} />
                        <Column header="description" field="description" />
                        <Column header="Actions" component={ActionsColumn} width={130} agentId={agentId} />
                    </Table>
                </TableContainer>
            </StyledCard>
            <Footer />
            <CreateEditTopicDrawer drawerId={ADD_EDIT_TOPIC_DRAWER} />
        </Container>
    );
};

export default TopicsManagementPage;
