import { useMemo } from 'react';
import Label from 'react-rainbow-components/components/Input/label';
import {
    ValueContainer,
    StyledChip,
    ConfigName,
    ConfigDescription,
    InnerContainer,
} from './styled';
import { Retriver } from './types';

interface SelectedValueProps {
    id?: string;
    className?: string;
    label?: string;
    required?: boolean;
    disabled?: boolean;
    value: Retriver;
    onClearValue?: () => void;
}

const SelectedValue = ({
    id,
    className,
    label = 'Retrieval Configuration',
    required = false,
    disabled = false,
    value,
    onClearValue = () => {},
}: SelectedValueProps) => {
    const configName = useMemo(() => value.name, [value]);
    const configDescription = useMemo(() => value.description || '', [value]);

    return (
        <ValueContainer id={id} className={className}>
            <Label
                label={label}
                labelAlignment="left"
                required={required}
            />
            <InnerContainer>
                <StyledChip
                    disabled={disabled}
                    label={(
                        <div>
                            <ConfigName>{configName}</ConfigName>
                            {configDescription && (
                                <ConfigDescription>{configDescription}</ConfigDescription>
                            )}
                        </div>
                    )}
                    borderRadius="semi-square"
                    onDelete={disabled ? undefined : onClearValue}
                />
            </InnerContainer>
        </ValueContainer>
    );
};

export default SelectedValue;
