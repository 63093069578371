import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { query, where } from 'firebase/firestore';
import { Column } from 'react-rainbow-components';
import useWebhooks from 'data/firestore/webhooks/useCollection';
import Button from 'components/Button';
import Table from 'components/Table';
import DateTimeColumn from 'components/DateTimeColumn';
import { Webhooks } from 'data/firestore/webhooks/types';
import Empty from './empty';
import {
    Container,
    HeaderContainer,
    HeaderTitle,
    HeaderSubtitle,
    HeaderContent,
    StyledCard,
} from './styled';
import Name from './columns/name';
import ActionsColumn from './columns/actions';
import useManageWebhooks from '../hooks/useManageWebhooks';

const WebhooksPage = () => {
    const { agentId = '' } = useParams<{ agentId: string }>();

    const {
        enable, disable, remove, edit, create,
    } = useManageWebhooks({ agentId });

    const { data: webhooks = [], isLoading } = useWebhooks({
        disabled: !agentId,
        listQueryFn: (ref) => query(ref, where('group', '==', agentId)),
        track: [agentId],
    });

    const isEmpty = !isLoading && webhooks.length === 0;

    const handleToggleEnabled = useCallback((webhook: Webhooks) => {
        const callableFn = webhook.status ? disable : enable;
        callableFn(webhook.id);
    }, [disable, enable]);

    const handleDelete = useCallback((webhook: Webhooks) => remove(webhook.id), [remove]);

    if (isEmpty) {
        return (
            <Container>
                <Empty onClick={() => {}} />
            </Container>
        );
    }

    return (
        <StyledCard>
            <HeaderContainer>
                <HeaderContent>
                    <HeaderTitle>
                        Webhooks
                    </HeaderTitle>
                    <HeaderSubtitle>
                        Deliver real-time event data for seamless
                        automation and integration.
                    </HeaderSubtitle>
                </HeaderContent>
                <Button
                    label="Create New Webhook"
                    variant="brand"
                    size="medium"
                    borderRadius="semi-rounded"
                    onClick={() => create()}
                />
            </HeaderContainer>
            <Table
                data={isLoading ? [] : webhooks}
                isLoading={isLoading}
                keyField="id"
            >
                <Column
                    header="Webhook"
                    field="id"
                    component={Name}
                />
                <Column
                    header="Created At"
                    field="createdAt"
                    width={200}
                    component={DateTimeColumn}
                />
                <Column
                    field="id"
                    component={ActionsColumn}
                    width={200}
                    onToggle={handleToggleEnabled}
                    onEdit={edit}
                    onDelete={handleDelete}
                />
            </Table>
        </StyledCard>
    );
};

export default WebhooksPage;
