import { Monaco } from '@monaco-editor/react';
import { languages } from 'monaco-editor';
import { Variable } from './types';

let registered = false;

// Helper to build a nested structure of available path segments
const buildPathSegments = (variables: Set<Variable>): Map<string, Set<string>> => {
    const pathSegments = new Map<string, Set<string>>();

    // Add empty path as root
    pathSegments.set('', new Set());

    variables.forEach((variable) => {
        const segments = variable.value.split('.');
        let currentPath = '';

        // For each segment in the path
        segments.forEach((segment) => {
            const parentPath = currentPath;

            // Add this segment to its parent's children
            if (!pathSegments.has(parentPath)) {
                pathSegments.set(parentPath, new Set());
            }
            pathSegments.get(parentPath)?.add(segment);

            // Update current path
            currentPath = currentPath ? `${currentPath}.${segment}` : segment;
        });
    });

    return pathSegments;
};

// Find suggestions based on what user has already typed
const getSuggestionsForPath = (
    path: string,
    pathSegments: Map<string, Set<string>>,
    variables: Set<Variable>,
    monaco: Monaco,
    range: any,
    triggerCharacter?: string,
): languages.CompletionItem[] => {
    // For empty path or paths ending with dot, we want completions
    const isCompletePath = path === '' || path.endsWith('.');
    // If triggered with '{' or empty path, lookup from root, otherwise get parent path
    let lookupPath = '';
    if (isCompletePath) {
        lookupPath = path === '' ? '' : path.slice(0, -1);
    } else if (path.indexOf('.') >= 0) {
        lookupPath = path.substring(0, path.lastIndexOf('.') + 1).slice(0, -1);
    }

    // Get available segments for this path
    const segments = pathSegments.get(lookupPath) || new Set<string>();

    // Create completion items
    return Array.from(segments).map((segment) => {
        const fullPath = lookupPath ? `${lookupPath}.${segment}` : segment;

        // Determine insert text based on trigger character and position in path
        let insertText;
        if (triggerCharacter === '{' || path === '') {
            // First segment always includes opening and closing braces
            insertText = `${fullPath}`;
        } else {
            // Subsequent segments just need the segment name
            insertText = segment;
        }

        // Find the full variable this segment belongs to (for documentation)
        const matchingVar = Array.from(variables).find((v) => v.value === fullPath);

        return {
            label: segment,
            filterText: insertText,
            kind: monaco.languages.CompletionItemKind.Variable,
            insertText,
            detail: matchingVar?.label || segment,
            documentation: matchingVar?.documentation || '',
            range,
            commitCharacters: ['.'],
        };
    });
};

const registerVariables = (monaco: Monaco, variables: Set<Variable>) => {
    if (registered || !variables.size) return;

    monaco.languages.registerCompletionItemProvider(
        'handlebars',
        {
            // Only trigger on the second brace ('{' + '{') or on '.'
            triggerCharacters: ['{', '.', ' '],
            provideCompletionItems: (model, position) => {
                // Build the path segments map once
                const pathSegments = buildPathSegments(variables);

                const word = model.getWordUntilPosition(position);
                const range = {
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: word.startColumn,
                    endColumn: word.endColumn,
                };

                // Check for braces
                const currentPrefixBraces = model.getValueInRange({
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: Math.max(word.startColumn - 2, 1),
                    endColumn: word.startColumn,
                });

                // Determine the trigger character
                const lineContent = model.getLineContent(position.lineNumber);
                const textUntilPosition = lineContent.substring(0, position.column - 1);
                const textAfterPosition = lineContent.substring(position.column - 1);

                // Check if we're triggering with double braces only
                const triggerWithDoubleBraces = textUntilPosition.endsWith('{{');
                const triggerWithDot = textUntilPosition.endsWith('.');
                const triggerWithSpace = textUntilPosition.endsWith(' ');

                // Find the last opening '{{' before cursor
                const lastOpenBrace = textUntilPosition.lastIndexOf('{{');

                // For dot triggers, only continue if there's a '{{' before the cursor AND
                // there's at least one alphanumeric character between '{{' and the dot
                if (triggerWithDot) {
                    // Check if we have '{{' before the cursor
                    if (lastOpenBrace === -1) {
                        return { suggestions: [] };
                    }

                    // Check if there's at least one alphanumeric character between '{{' and the dot
                    const textBetweenBraceAndDot = textUntilPosition.substring(
                        lastOpenBrace + 2,
                        textUntilPosition.length - 1,
                    );
                    if (!textBetweenBraceAndDot || !(/[#\w]+(\s+[a-zA-Z0-9_.]+)*/.test(textBetweenBraceAndDot))) {
                        return { suggestions: [] };
                    }
                }

                // For space triggers, we need to check if we're between braces
                // Check if we have '{{' before the cursor
                if (triggerWithSpace) {
                    if (lastOpenBrace === -1) {
                        return { suggestions: [] };
                    }

                    const textBetweenBraceAndWhiteSpace = textUntilPosition.substring(
                        lastOpenBrace + 2,
                        textUntilPosition.length - 1,
                    );
                    if (!textBetweenBraceAndWhiteSpace || !(/[#\w]+(\s+[a-zA-Z0-9_.]+)*/.test(textBetweenBraceAndWhiteSpace))) {
                        return { suggestions: [] };
                    }
                }

                // Check if we're between empty braces
                const isInEmptyBraces = textUntilPosition.endsWith('{{') && textAfterPosition.startsWith('}}');

                // Only continue if we have double braces or a valid dot position
                if (
                    !triggerWithSpace
                    && !triggerWithDoubleBraces
                    && !triggerWithDot
                    && !isInEmptyBraces
                ) {
                    return { suggestions: [] };
                }

                const hasPrefixBraces = currentPrefixBraces === '{{';
                if (hasPrefixBraces) {
                    range.startColumn = word.startColumn;
                }

                const currentSuffixBraces = model.getValueInRange({
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: word.endColumn,
                    endColumn: Math.min(
                        word.endColumn + 2,
                        model.getLineMaxColumn(position.lineNumber),
                    ),
                });

                const hasSuffixBraces = currentSuffixBraces === '}}';
                if (hasSuffixBraces) {
                    range.endColumn = word.endColumn;
                }

                let triggerCharacter;
                if (triggerWithDoubleBraces || isInEmptyBraces) {
                    triggerCharacter = '{';
                } else if (triggerWithDot) {
                    triggerCharacter = '.';
                } else {
                    triggerCharacter = undefined;
                }

                // Get the current path - either after '{{' or between '{{' and cursor
                let currentPath = '';

                if (triggerWithDoubleBraces) {
                    // Just triggered with '{{', so path is empty
                    currentPath = '';
                } else {
                    // Extract path from text - now also working when closing braces are present
                    const lineBeforeCursor = lineContent.substring(0, position.column - 1);

                    // Find the last opening '{{' before cursor
                    const openBracePosition = lineBeforeCursor.lastIndexOf('{{');
                    const lastWhitespacePosition = lineBeforeCursor.lastIndexOf(' ');

                    if (lastWhitespacePosition !== -1) {
                        // Get text between last {{ and cursor position
                        const textBetween = lineBeforeCursor.substring(lastWhitespacePosition);
                        // Remove any closing braces that might exist
                        currentPath = textBetween.trim();
                    } else if (openBracePosition !== -1) {
                        // Get text between last {{ and cursor position
                        const textBetweenBraces = lineBeforeCursor.substring(openBracePosition + 2);
                        // Remove any closing braces that might exist
                        currentPath = textBetweenBraces.replace(/}}$/, '').trim();
                    }
                }

                return {
                    suggestions: getSuggestionsForPath(
                        currentPath,
                        pathSegments,
                        variables,
                        monaco,
                        range,
                        triggerCharacter,
                    ),
                } as languages.ProviderResult<languages.CompletionList>;
            },
        },
    );
    registered = true;
};

export default registerVariables;
