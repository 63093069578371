import Footer from 'components/Footer';
import AIMessagesSentInChannels from './AIMessagesSentInChannels';
import { Container } from './styled';

const SocialInsights = () => (
    <Container>
        <AIMessagesSentInChannels
            channels={['facebook', 'instagram', 'whatsapp']}
            title="AI-Sent Responses on Meta"
            description="View the AI-sent message volume on Facebook and Instagram for the selected time range."
            colors={['#1877F2', '#DD2A7B', '#25D366']}
        />
        <Footer />
    </Container>
);

export default SocialInsights;
