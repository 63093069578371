import { useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import context from 'context';
import useAlgoliaIndex from 'data/algolia/useIndex';
import { isEmpty } from '@rainbow-modules/validation';
import { ViewContextType } from 'pages/Opportunities/types';
import { EntityGet } from 'data/firestore/types';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import useGenerateSearchKey from 'data/hooks/useGenerateSearchKey';
import {
    ALGOLIA_INDEX_OPPORTUNITIES,
    ALGOLIA_INDEX_OPPORTUNITIES_UPDATED_AT_ASC, ALGOLIA_INDEX_OPPORTUNITIES_UPDATED_AT_DESC,
    ALGOLIA_INDEX_OPPORTUNITIES_CREATED_AT_ASC, ALGOLIA_INDEX_OPPORTUNITIES_CREATED_AT_DESC,
    ALGOLIA_INDEX_OPPORTUNITIES_SCORE_ASC, ALGOLIA_INDEX_OPPORTUNITIES_SCORE_DESC,
} from '../../../../../constants';
import buildAlgoliaFilters from '../../helpers/buildAlgoliaFilters';
import { DEFAULT_SORT_OPPORTUNITIES } from '../../contants';

interface AlgoliaError {
    status: number;
    message: string;
}

const indexMap: Record<
string,
    typeof ALGOLIA_INDEX_OPPORTUNITIES_UPDATED_AT_DESC |
    typeof ALGOLIA_INDEX_OPPORTUNITIES_UPDATED_AT_ASC |
    typeof ALGOLIA_INDEX_OPPORTUNITIES_CREATED_AT_ASC |
    typeof ALGOLIA_INDEX_OPPORTUNITIES_CREATED_AT_DESC |
    typeof ALGOLIA_INDEX_OPPORTUNITIES_SCORE_ASC |
    typeof ALGOLIA_INDEX_OPPORTUNITIES_SCORE_DESC
> = {
    updatedAt_desc: ALGOLIA_INDEX_OPPORTUNITIES_UPDATED_AT_DESC,
    updatedAt_asc: ALGOLIA_INDEX_OPPORTUNITIES_UPDATED_AT_ASC,
    createdAt_desc: ALGOLIA_INDEX_OPPORTUNITIES_CREATED_AT_DESC,
    createdAt_asc: ALGOLIA_INDEX_OPPORTUNITIES_CREATED_AT_ASC,
    score_desc: ALGOLIA_INDEX_OPPORTUNITIES_SCORE_DESC,
    score_asc: ALGOLIA_INDEX_OPPORTUNITIES_SCORE_ASC,
};

const useBoardOpportunitiesFromAlgolia = ({
    agentId,
    enabled = false,
}: {
    agentId: string;
    enabled: boolean;
}) => {
    const {
        selectedPipeline = '',
        stages = [],
        sortBy: sort = DEFAULT_SORT_OPPORTUNITIES,
        filters,
        searchQuery = '',
    } = useOutletContext<ViewContextType>();
    const [data, setData] = useState<Record<string, EntityGet<Opportunity>[]> | null>(null);
    const [isLoading, setLoading] = useState<Record<string, boolean>>({});
    const { setAlgoliaSecureKeys } = useContext(context);
    const { mutateAsync } = useGenerateSearchKey();

    const indexName = indexMap[`${sort.sortBy}_${sort.order}`] ?? ALGOLIA_INDEX_OPPORTUNITIES;
    const algoliaIndex = useAlgoliaIndex(indexName);

    useEffect(
        () => {
            (async () => {
                if (enabled) {
                    if (algoliaIndex) {
                        const { stage: stageFilterValue } = filters || {};
                        const selectedStages = ((stageFilterValue ?? '') as string).split(',').filter((stageId) => !!stageId);
                        await Promise.all(
                            stages.map(async (stage) => {
                                if (
                                    !isEmpty(selectedStages)
                                    && !selectedStages.includes(stage.id)
                                ) {
                                    setData((prev) => ({
                                        ...prev,
                                        [stage.id]: [],
                                    }));
                                    return;
                                }
                                setLoading((prevLoading) => ({ ...prevLoading, [stage.id]: true }));
                                try {
                                    const {
                                        hits,
                                    } = await algoliaIndex.search<EntityGet<Opportunity>>(
                                        searchQuery,
                                        {
                                            page: 0,
                                            hitsPerPage: 100,
                                            filters: buildAlgoliaFilters({
                                                agentId,
                                                stage: stage.id,
                                                pipeline: selectedPipeline,
                                                filters,
                                            }),
                                            cacheable: false,
                                        },
                                    );
                                    setData((prev) => ({
                                        ...prev,
                                        [stage.id]: hits.map((hit) => ({
                                            ...hit,
                                            id: hit.objectID,
                                        })),
                                    }));
                                } catch (err) {
                                    if ((err as AlgoliaError)?.status === 400 && (err as AlgoliaError)?.message.includes('validUntil')) {
                                        const { algoliaSecureKey } = await mutateAsync({
                                            body: {
                                                indexName: ALGOLIA_INDEX_OPPORTUNITIES,
                                            },
                                        });
                                        setAlgoliaSecureKeys((prevKeys) => ({
                                            ...prevKeys,
                                            opportunities: algoliaSecureKey,
                                        }));
                                    }
                                }
                                setLoading(
                                    (prevLoading) => ({
                                        ...prevLoading,
                                        [stage.id]: false,
                                    }),
                                );
                            }),
                        );
                    }
                } else {
                    setData(null);
                }
            })();
        },
        [
            searchQuery,
            mutateAsync,
            setAlgoliaSecureKeys,
            enabled,
            stages,
            agentId,
            selectedPipeline,
            filters,
            algoliaIndex,
        ],
    );

    return { isLoading, data };
};

export default useBoardOpportunitiesFromAlgolia;
