import Button from 'components/Button';
import {
    EmptyMessageDescription, EmptyMessageTitle, EmptyMessageEmoji, EmptyMessageContainer,
} from './styled';

interface Props {
    onClick: () => void;
}

const EmptyApiKey = ({ onClick }: Props) => (
    <EmptyMessageContainer>
        <EmptyMessageEmoji>
            🔗
        </EmptyMessageEmoji>
        <EmptyMessageTitle>
            Start creating your Webhooks
        </EmptyMessageTitle>
        <EmptyMessageDescription>
            Delivers data about the events to your applications.
        </EmptyMessageDescription>
        <Button variant="brand" label="Create New Webhook" borderRadius="semi-rounded" onClick={onClick} />
    </EmptyMessageContainer>
);

export default EmptyApiKey;
