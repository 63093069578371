import FormFooter from './footer';
import Form from './form';
import { Container, Content } from './styled';
import { BaseFormProps } from './types';

interface CreateWebhookFormProps extends BaseFormProps {}

const CreateWebhookForm = ({
    submitButtonLabel,
    initialValues,
    onSubmit,
    onRequestClose = () => {},
}: CreateWebhookFormProps) => (
    <Container>
        <Content>
            <Form
                id="webhooks-form"
                initialValues={initialValues}
                onSubmit={onSubmit}
            />
        </Content>
        <FormFooter
            submitButtonLabel={submitButtonLabel}
            formId="webhooks-form"
            onCancelClick={onRequestClose}
        />
    </Container>
);

export default CreateWebhookForm;
