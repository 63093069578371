import styled, { css } from 'styled-components';
import Clock from 'components/icons/clock';
import AlertCircle from 'components/icons/alertCircle';
import { Drawer, ProgressCircular, Tab } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import SendNoteForm from 'components/SendNoteForm';
import {
    GREY_100,
    GREY_400,
    GREY_800,
    WHITE,
} from '../../constants';

export const CloseButton = styled(ButtonIcon)`
    background: ${GREY_100};
    color: ${GREY_800};
`;

export const DetailsContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow: hidden;
`;

export const DetailsContent = styled.div`
    display: flex;
    align-self: stretch;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    padding: 0;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5rem;
    gap: 1rem;
`;

export const HeaderActions = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
`;

export const OpportunityTitle = styled.h1`
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1.75rem;
`;

export const OpportunityDescriptionBold = styled.p`
    font-size: 1.125rem;
    font-weight: 600;
    color: ${GREY_400};
`;

export const Price = styled.span`
    font-weight: 700;
`;

export const Row = styled.div<{ minHeight?: number | string }>`
    display: flex;
    flex-direction: column;
    ${(props) => props.minHeight && css`
        min-height: ${props.minHeight};
    `}
`;

export const DetailsHeader = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
`;

export const StyledProgressCircular = styled(ProgressCircular)`
    min-width: 4.25rem;
    width: 4.25rem;
    height: 4.25rem;
    flex-shrink: 0;
    margin: 3px;

    svg {
        width: 4.25rem;
        height: 4.25rem;
        overflow: visible !important;
        
        circle {
            stroke-width: 4px;
        }
    }

    & > h1 {
        font-size: 1.4105rem;
        color: ${WHITE};
        &::after {
            color: ${GREY_800};
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 1.4105rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.375rem;

            ${(props) => props.variant && css`
                content: '${props.value}';

            `}
        }
    }
`;

export const OpportunityDescriptionContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-top: 0.5rem;
`;

export const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const CustomerContainer = styled.div`
    display: flex;
    background: ${GREY_100};
    border-radius: 100px;
    padding: 0.5rem;
    margin-bottom: 1rem;
`;

export const SummaryContainer = styled.div`
    display: flex;
    padding-left: 1rem;
    margin-bottom: 1.5rem;
    margin-right: 1rem;
`;

export const NotesContainer = styled.div`
    background: ${WHITE};
    width: 100%;
    padding: 1rem 1rem 1.75rem 1rem;
    display: flex;
    position: relative;
    bottom: 2px;
    z-index: 1001;
`;

export const StyledSendNoteForm = styled(SendNoteForm)`
    flex-grow: 1;
    z-index: 1;
    margin: 0;
`;

export const StyledTab = styled(Tab)`
    display: flex;
    width: 9.75rem;
    height: 3.4rem;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    color: ${GREY_800};

    button::before {
        border-radius: 0;
        height: 2px;
    }
`;

export const TabLabel = styled.span`
    display: flex;
    padding: 0.625rem 1.25rem;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    letter-spacing: 0.02688rem;
`;

export const TabsetContainer = styled.div`
    padding: 0;
    position: sticky;
    top: 0;
    z-index: 1000;
    background: white;
`;

export const StyledDrawer = styled(Drawer)`
    width: 50vw;
    border-radius: 1.5rem;
    margin: 1.5rem;
    box-sizing: border-box;
    height: calc(100% - 3rem);
    min-height: calc(100% - 3rem);
    max-height: calc(100% - 3rem);
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.07);

    > div {
        padding-bottom: 0;
        box-sizing: border-box;
    }
`;

export const Gradient = styled.div`
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 1) 90%);
    height: 6rem;
    position: absolute;
    top: -6rem;
    left: 0;
    width: calc(100% - 1rem);
    z-index: 1000;
`;

export const DrawerGradient = styled.div`
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 1) 90%);
    height: 5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-right: 24px;
    width: 100%;
    z-index: 1000;
    border-radius: 0 0 1.5rem 1.5rem;
`;

export const OpportunityUpdateDateContainer = styled.div`
    display: flex;
    height: 1.5rem;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem; 
    margin-top: 0.5rem;
    color: #A5A2AD;
`;

export const StyledAlertCircle = styled(AlertCircle)`
    transform: rotate(180deg);
    color: #C18245;
`;

export const StyledClockIcon = styled(Clock)`
    width: 1.5rem !important;
    height: 1.5rem !important;
    color: #88888D;
`;

export const ActivityContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-bottom: 3rem;
`;
