import BasicPageLayout from 'layouts/BasicPage';
import { Container, Content } from './styled';
import Sidebar from './Sidebar';
import ContentArea from './ContentArea';
import useMemories from './hooks/useMemories';
import useQueryFilters from './hooks/useQueryFilters';
import { Memory } from './types';

const MemoryManagement = () => {
    const filters = useQueryFilters();
    const {
        memories, isLoading, hits, totalMemories,
    } = useMemories({
        filters,
    });

    return (
        <BasicPageLayout>
            <Container>
                <Content>
                    <Sidebar
                        filters={filters}
                    />
                    <ContentArea
                        selectedCustomer={filters.customerId}
                        memories={memories as Memory[]}
                        totalMemories={totalMemories}
                        filteredMemories={hits}
                        isLoading={isLoading}
                    />
                </Content>
            </Container>
        </BasicPageLayout>
    );
};

export default MemoryManagement;
