import {
    EventElementContainer,
    EventName,
    EventDescription,
} from './styled';

const EventElement = ({
    name,
    description,
}: {
    name: string;
    description: string;
}) => (
    <EventElementContainer>
        <EventName>
            {name}
        </EventName>
        <EventDescription>
            {description}
        </EventDescription>
    </EventElementContainer>
);

export default EventElement;
