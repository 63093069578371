import { useState } from 'react';
import { Modal } from 'react-rainbow-components';
import ExpandContentIcon from 'components/icons/expandContent';
import {
    Container,
    ContentWrapper,
    Header,
    Field,
    Label,
    Value,
    Body,
    ExpandButton,
} from './styled';

interface EmailDetailsProps {
    subject: string;
    body: string;
    from: string;
    to: string;
}

const EmailDetails = ({
    subject, body, from, to,
}: EmailDetailsProps) => (
    <ContentWrapper>
        <Header>
            <Field>
                <Label>From:</Label>
                <Value>{from}</Value>
            </Field>
            <Field>
                <Label>To:</Label>
                <Value>{to}</Value>
            </Field>
            <Field>
                <Label>Subject:</Label>
                <Value>{subject}</Value>
            </Field>
        </Header>
        <Body>
            {body}
        </Body>
    </ContentWrapper>
);

const EmailContent = ({
    subject, body, from, to,
}: EmailDetailsProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <Container>
                <ExpandButton
                    onClick={() => setIsModalOpen(true)}
                    icon={<ExpandContentIcon />}
                />
                <EmailDetails subject={subject} body={body} from={from} to={to} />
            </Container>

            <Modal
                title="Email Details"
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                size="large"
            >
                <EmailDetails subject={subject} body={body} from={from} to={to} />
            </Modal>
        </>
    );
};

export default EmailContent;
