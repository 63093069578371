import { useCallback } from 'react';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import useResponder from 'data/firestore/agent/llm/useOnce';
import useHttpMutation from 'data/firestore/useHttpMutation';
import AiModelConfigForm from 'components/Instructions/aiModelConfigForm';
import ModelConfigFields from 'pages/LLMResponders/ResponderDetails/configFields';
import mapLLMValues from 'pages/LLMResponders/ResponderDetails/helpers/mapLLMValues';

interface EditFormProps {
    agentId: string;
    onRequestClose: () => void;
    [key: string]: unknown;
}

const EditForm = ({
    agentId,
    onRequestClose = () => {},
    ...responderInfo
}: EditFormProps) => {
    const { data: responder = {} } = useResponder(
        agentId as string,
        responderInfo?.id as string,
        { disabled: !(agentId || responderInfo?.id) },
    );

    const responderData = (agentId ? responder : responderInfo);

    const {
        mutateAsync: updateResponder,
    } = useHttpMutation<Record<string, unknown>, void>({
        pathname: `/agents/${agentId}/llms/${responderInfo?.id}`,
        method: 'PATCH',
        onSuccess: onRequestClose,
    });

    const handleUpdateModelConfig = useCallback(
        async (values: Record<string, unknown>) => {
            let success = false;
            showAppSpinner();
            try {
                await updateResponder({
                    pathname: `/agents/${agentId}/llms/${responderInfo?.id}`,
                    body: {
                        ...(responderInfo || {}),
                        ...mapLLMValues(values),
                    },
                });
                showAppNotification({
                    title: 'Success',
                    description: 'Model config was successfully updated',
                    icon: 'success',
                    timeout: 5000,
                });
                success = true;
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
            return success;
        },
        [updateResponder, agentId, responderInfo],
    );

    return (
        <AiModelConfigForm
            initialValues={responderData}
            onSubmit={handleUpdateModelConfig}
            fieldsComponent={ModelConfigFields}
            onRequestClose={onRequestClose}
        />
    );
};

export default EditForm;
