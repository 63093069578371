import { useMemo } from 'react';
import { RenderIf, HelpText } from 'react-rainbow-components';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import { EntityGet } from 'data/firestore/types';
import { Customer } from 'data/firestore/agent/customer/types';
import TaskSummary from 'components/TasksInCategory/Task/Summary';
import OpportunityStuckBadge from 'components/OpportunityStuckBadge';
import { formatRelativeDate } from 'data/services/date/formatter';
import CustomerInformation from './CustomerInformation';
import getVariantFromScore from './helpers/getVariantFromScore';
import {
    DetailsContent,
    Row,
    OpportunityTitle,
    OpportunityDescriptionContainer,
    StyledProgressCircular,
    OpportunityDescriptionBold,
    DetailsHeader,
    CustomerContainer,
    MainContent,
    SummaryContainer,
    OpportunityUpdateDateContainer,
    StyledClockIcon,
    StyledAlertCircle,
} from './styled';
import getScoreRange from './helpers/getScoreRange';
import { opportunityScoreLabels } from './labels';
import { WorkplaceDetailsCard } from './WorkplaceInformation';

const OpportunityDetailsWidget = ({
    agentId,
    data,
    isLoadingSummary,
    summary,
    onRequestClose = () => {},
}: {
    agentId: string;
    data?: EntityGet<Opportunity>;
    isLoadingSummary?: boolean;
    summary?: {
        summary: string;
        updatedAt: string;
    };
    onRequestClose?: () => void;
}) => {
    const { id: customerId = '' } = data?.customer ?? {};

    const scoreLabels = useMemo(
        () => {
            if (typeof data?.score === 'number') {
                const range = getScoreRange(data.score);
                return opportunityScoreLabels[range as string];
            }
            return undefined;
        },
        [data?.score],
    );

    const summaryUpdatedAt = useMemo(
        () => {
            if (!summary?.updatedAt) return undefined;
            return new Date(summary.updatedAt);
        },
        [summary],
    );

    const lastUpdate = useMemo(
        () => data && `Updated ${formatRelativeDate(data?.updatedAt || data?.createdAt)}`,
        [data],
    );

    const isStuck = useMemo(
        () => data?.stuck?.condition === 'stalled',
        [data],
    );
    return (
        <DetailsContent>
            <DetailsHeader>
                <StyledProgressCircular
                    variant={getVariantFromScore(data?.score)}
                    value={data?.score || 0}
                />
                <Row>
                    <OpportunityTitle>
                        {data?.name}
                    </OpportunityTitle>
                    <OpportunityDescriptionContainer>
                        <OpportunityDescriptionBold>
                            <RenderIf isTrue={scoreLabels?.title}>
                                {scoreLabels?.title}
                            </RenderIf>
                            <RenderIf isTrue={!scoreLabels?.title}>
                                Assessment Pending
                            </RenderIf>
                        </OpportunityDescriptionBold>
                        <HelpText
                            text={(
                                <p>
                                    <RenderIf isTrue={scoreLabels?.description}>
                                        {scoreLabels?.description}
                                    </RenderIf>
                                    <RenderIf isTrue={!scoreLabels?.description}>
                                        Opportunity exists, but their engagement and
                                        potential remain unclear.
                                    </RenderIf>
                                </p>
                            )}
                        />
                    </OpportunityDescriptionContainer>
                    <OpportunityUpdateDateContainer>
                        <RenderIf isTrue={!isStuck}>
                            <StyledClockIcon />
                        </RenderIf>
                        <RenderIf isTrue={isStuck}>
                            <StyledAlertCircle />
                        </RenderIf>
                        {lastUpdate}
                        <OpportunityStuckBadge stuck={data?.stuck} />
                    </OpportunityUpdateDateContainer>
                </Row>
            </DetailsHeader>
            <MainContent>
                <CustomerContainer>
                    <CustomerInformation
                        agentId={agentId}
                        customerId={customerId}
                        data={data?.customer as EntityGet<Customer>}
                    />
                </CustomerContainer>
                <RenderIf isTrue={data}>
                    <WorkplaceDetailsCard
                        agentId={agentId}
                        customerId={customerId as string}
                        onNavigateToWorkplace={() => onRequestClose()}
                    />
                </RenderIf>
                <SummaryContainer>
                    <TaskSummary
                        header="Sara's got the overview for you"
                        summary={summary?.summary}
                        date={summaryUpdatedAt}
                        showDate
                        isLoading={isLoadingSummary}
                        hideDetailsLink
                    />
                </SummaryContainer>
            </MainContent>
        </DetailsContent>
    );
};

export default OpportunityDetailsWidget;
