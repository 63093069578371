import { ReactNode } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import {
    Container,
    Content,
    FormContainer,
    StyledDrawer,
    StyledUniversalForm,
} from './styled';
import Fields from './fields';
import FormFooter from './footer';

const CreateEditTopicDrawer = ({ drawerId }: { drawerId: string }) => {
    const connectedAddModifyProps = useConnectModal(
        drawerId,
        { size: 'small', slideFrom: 'right' },
    );

    const [, closeDrawer] = useOpenModal(drawerId);
    const {
        isOpen,
        title,
        disabledFields,
        submitButtonLabel,
        ...rest
    } = connectedAddModifyProps;
    return (
        <StyledDrawer
            isOpen={isOpen}
            header={title}
            onRequestClose={closeDrawer}
            slideFrom="right"
        >
            <RenderIf isTrue={isOpen}>
                <Container>
                    <Content>
                        <FormContainer>
                            <StyledUniversalForm id="topic-form" {...rest}>
                                <Fields disabledFields={disabledFields as string[]} />
                            </StyledUniversalForm>
                        </FormContainer>
                    </Content>
                    <FormFooter
                        formId="topic-form"
                        submitButtonLabel={submitButtonLabel as ReactNode}
                        onCancelClick={closeDrawer}
                    />
                </Container>
            </RenderIf>
        </StyledDrawer>
    );
};

export default CreateEditTopicDrawer;
