import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { CheckboxToggle } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import Trash from 'components/icons/trash';
import Pencil from 'components/icons/pencil2';
import ListTile from 'components/ListTile';
import { EntityGet } from 'data/firestore/types';
import { Webhooks } from 'data/firestore/webhooks/types';
import formatter from 'data/services/date/formatter';
import useManageWebhooks from '../../hooks/useManageWebhooks';
import {
    DetailsContainer, HeaderContainer, HeaderTitle, StyledRecordPrimaryDetails,
    Actions, StyledRecordField, Description,
} from './styled';
import WebHookSecret from './secret';
import WebHookEvents from './events';

const WebhookDetailedInfo = ({
    isLoading,
    data,
}: {
    isLoading?: boolean;
    data?: EntityGet<Webhooks> | null;
}) => {
    const { agentId = '' } = useParams();
    const {
        enable, disable, remove, edit,
    } = useManageWebhooks({ agentId });

    const toggleEnabled = useCallback(() => {
        if (!data?.id) return;
        const callableFn = data.status ? disable : enable;
        callableFn(data.id);
    }, [data, disable, enable]);

    return (
        <DetailsContainer>
            <HeaderContainer>
                <ListTile
                    title={(
                        <HeaderTitle>
                            <b>POST</b>
                            <span title={data?.endpoint}>{data?.endpoint}</span>
                        </HeaderTitle>
                    )}
                    subtitle={<Description>{data?.description}</Description>}
                />
                <Actions>
                    <CheckboxToggle
                        className="rainbow-m-right_small"
                        value={data?.status}
                        labelAlignment="left"
                        onClick={toggleEnabled}
                    />
                    <ButtonIcon
                        borderRadius="semi-rounded"
                        icon={<Pencil />}
                        size="small"
                        tooltip="Edit Webhook"
                        onClick={() => edit(data as Webhooks)}
                    />
                    <ButtonIcon
                        borderRadius="semi-rounded"
                        size="small"
                        icon={<Trash />}
                        tooltip="Remove Webhook"
                        onClick={() => remove(data?.id as string)}
                    />
                </Actions>
            </HeaderContainer>
            <StyledRecordPrimaryDetails>
                <StyledRecordField
                    label="Created At"
                    value={formatter.format(data?.createdAt)}
                    isLoading={isLoading}
                />
                <StyledRecordField
                    label="Secret"
                    value={data?.secret}
                    component={WebHookSecret}
                    isLoading={isLoading}
                />
            </StyledRecordPrimaryDetails>
            <StyledRecordPrimaryDetails>
                <StyledRecordField
                    isLoading={isLoading}
                    label="Events"
                    value={data?.events}
                    component={WebHookEvents}
                />
            </StyledRecordPrimaryDetails>
        </DetailsContainer>
    );
};

export default WebhookDetailedInfo;
