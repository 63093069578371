import {
  doc,
  onSnapshot,
  FirestoreError,
  Unsubscribe,
} from "firebase/firestore";
import { db } from "../firebase";
import { EntityGet, EntityServerGet, OnSnapshotDocService } from "../types";
import { Session } from "./types";
import { transformMetadata, processTimestampFields } from "../utils";

export type SessionOnSnapshotDocService<T> = (
  id: string,
  callback: (doc: T) => void,
  onError?: (error: FirestoreError) => void
) => Unsubscribe;

const onSnapshotDoc: SessionOnSnapshotDocService<EntityGet<Session>> = (
  id,
  callback,
  onError
) => {
  const docRef = doc(db, `/session/${id}`);
  return onSnapshot(
    docRef,
    (docSnap) => {
      if (docSnap.exists()) {
        callback(
          processTimestampFields(
            transformMetadata(
              {
                ...docSnap.data(),
                id: docSnap.id,
              } as EntityServerGet<Session>,
              docSnap.metadata
            )
          ) as EntityGet<Session>
        );
      }
    },
    onError
  );
};

export default onSnapshotDoc;
