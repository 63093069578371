import { CellWrapper } from './styled';
import ResponseStatusBadge from '../ResponseStatusBadge';

interface Props {
    value?: string;
}

const ReponseStatusColumn = ({ value }: Props) => (
    <CellWrapper>
        <ResponseStatusBadge value={`${value}`} />
    </CellWrapper>
);
export default ReponseStatusColumn;
