import { CodeContainer, StyledCodeBlock } from './styled';

const RequestResponseDetails = ({
    header, body, headerLabel, bodyLabel,
}: {
    header?: string;
    body?: string;
    headerLabel?: string;
    bodyLabel?: string;
}) => (
    <CodeContainer>
        <StyledCodeBlock
            label={headerLabel}
            language="text"
            value={header}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            theme="dark"
        />
        <StyledCodeBlock
            label={bodyLabel}
            language="json"
            value={body}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            theme="dark"
        />
    </CodeContainer>
);

export default RequestResponseDetails;
