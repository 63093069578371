export default new Set([
    {
        value: 'customer.alias',
        label: 'Customer Alias',
        documentation: 'Use this template to insert the customer alias here. This is the alias of the customer that is currently in call.',
    },
    {
        value: 'customer.firstName',
        label: 'Customer First Name',
        documentation: 'Use this template to insert the customer firstname here. This is the firstname of the customer that is currently in call.',
    },
    {
        value: 'customer.lastName',
        label: 'Customer Last Name',
        documentation: 'Use this template to insert the customer lastname here. This is the lastname of the customer that is currently in call.',
    },
    {
        value: 'customer.email',
        label: 'Customer Email',
        documentation: 'Use this template to insert the customer email here. This is the email of the customer that is currently in call.',
    },
    {
        value: 'customer.phoneNumber',
        label: 'Customer phone number',
        documentation: 'Use this template to insert the customer phone number here. This is the phone number of the customer that is currently in call.',
    },
    {
        value: 'customer.bio',
        label: 'Customer bio',
        documentation: 'Use this template to insert the customer bio here. This is the bio of the customer that is currently in call.',
    },
    {
        value: 'company.name',
        label: 'Company name',
        documentation: 'Use this template to insert the company name here.',
    },
    {
        value: 'company.address',
        label: 'Company address',
        documentation: 'Use this template to insert the company address here.',
    },
    {
        value: 'language.tag',
        label: 'Language tag',
        documentation: 'Use this template to insert the language tag of the call. Can be es, en.',
    },
    {
        value: 'language.inEnglish',
        label: 'Language in English',
        documentation: 'Use this template to insert the language of the call when system message is in English. Can be English or Spanish.',
    },
    {
        value: 'language.inSpanish',
        label: 'Language in Spanish',
        documentation: 'Use this template to insert the language of the call when system message is in Spanish. Can be Inglés or Español.',
    },
    {
        value: 'currentDate.year',
        label: 'Current year',
        documentation: 'Use this template to insert the current year here.',
    },
    {
        value: 'currentDate.month',
        label: 'Current month',
        documentation: 'Use this template to insert the current month here.',
    },
    {
        value: 'currentDate.day',
        label: 'Current day',
        documentation: 'Use this template to insert the current day here.',
    },
    {
        value: 'currentDate.hour',
        label: 'Current hour',
        documentation: 'Use this template to insert the current hour here.',
    },
    {
        value: 'currentDate.minute',
        label: 'Current minutes',
        documentation: 'Use this template to insert the current minutes here.',
    },
    {
        value: 'currentDate.second',
        label: 'Current seconds',
        documentation: 'Use this template to insert the current seconds here.',
    },
    {
        value: 'currentDate.dayOfWeek',
        label: 'Current day of week',
        documentation: 'Use this template to insert the current day of week here. E.g Monday, Tuesday, etc.',
    },
    {
        value: 'currentDate.weekOfYear',
        label: 'Current week of year',
        documentation: 'Use this template to insert the current week of year here.',
    },
    {
        value: 'conversation',
        label: 'List of conversation messages',
        documentation: 'Use this template to get the list of conversation messages',
    },
    {
        value: 'outputLanguage.tag',
        label: 'AI output language tag',
        documentation: 'Use this template to insert the language tag of the AI output language. Can be es, en.',
    },
    {
        value: 'outputLanguage.inEnglish',
        label: 'AI output language in English',
        documentation: 'Use this template when system message is in English. Can be English or Spanish.',
    },
    {
        value: 'outputLanguage.inSpanish',
        label: 'AI output language in Spanish',
        documentation: 'Use this template when system message is in Spanish. Can be Inglés or Español.',
    },
    {
        value: 'numAgentTurns',
        label: 'Number of times the agent spoke',
        documentation: 'Use this template to insert the number of times the agent spoke',
    },
    {
        value: 'numUserTurns',
        label: 'Number of times the user spoke',
        documentation: 'Use this template to insert the number of times the user spoke',
    },
]);
