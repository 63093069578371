import { Column } from 'react-rainbow-components';
import { useOutletContext, useParams } from 'react-router-dom';
import { ViewContextType } from 'pages/Opportunities/types';
import DateTimeColumn from 'components/DateTimeColumn';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import UserColumn from './columns/user';
import OpportunityColumn from './columns/opportunity';
import StageColumn from './columns/stage';
import ActionsColumn from './columns/actions';
import {
    TableContainer,
    PaginationContainer,
    EntriesText,
} from '../styled';
import useListOpportunities from './hooks/useListOpportunities';

const OpportunitiesViewList = () => {
    const { agentId = '' } = useParams();
    const {
        isLoading: isLoadingPipelineData,
    } = useOutletContext<ViewContextType>();

    const {
        isLoading: isLoadingOpportunities,
        opportunities = [],
        totalHits,
        activePage,
        firstItemOfActivePage,
        lastItemOfActivePage,
        pages,
        changePage,
    } = useListOpportunities(agentId);

    const isLoading = isLoadingPipelineData || isLoadingOpportunities;

    return (
        <TableContainer>
            <Table isLoading={isLoading} keyField="id" data={opportunities}>
                <Column
                    header="Opportunity"
                    field="name"
                    component={OpportunityColumn}
                    sortable
                />
                <Column
                    header="Customer"
                    component={UserColumn}
                />
                <Column
                    header="Stage"
                    field="stageId"
                    component={StageColumn}
                    defaultWidth={200}
                />
                <Column
                    header="Created At"
                    field="createdAt"
                    component={DateTimeColumn}
                    defaultWidth={210}
                    showRelativeDate
                    sortable
                />
                <Column
                    header="Last Updated"
                    field="updatedAt"
                    component={DateTimeColumn}
                    defaultWidth={210}
                    showRelativeDate
                    sortable
                />
                <Column
                    header="Actions"
                    agentId={agentId}
                    component={ActionsColumn}
                    defaultWidth={120}
                />
            </Table>
            <PaginationContainer>
                <EntriesText>
                    Showing
                    {' '}
                    {firstItemOfActivePage}
                    {' '}
                    to
                    {' '}
                    {lastItemOfActivePage}
                    {' '}
                    of
                    {' '}
                    {totalHits}
                    {' '}
                    entries.
                </EntriesText>
                <Pagination
                    activePage={activePage}
                    pages={pages}
                    onChange={changePage}
                />
            </PaginationContainer>
        </TableContainer>
    );
};

export default OpportunitiesViewList;
