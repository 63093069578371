import { Memory as MemoryType, TYPE_COLORS, TYPE_LABELS } from '../../types';
import {
    MemoryCard,
    MemoryHeader,
    TypeBadge,
    ImportanceBadge,
    MemoryContent,
    MemoryFooter,
    FooterItem,
    SegmentTag,
    TimeStamp,
    SourceBadge,
} from './styled';

interface MemoryProps {
    value: MemoryType;
}

const formatTimestamp = (timestamp: number): string => {
    const date = new Date(timestamp);
    return date.toLocaleString();
};

const Memory = ({ value }: MemoryProps) => {
    const {
        type,
        content,
        importance,
        source,
        segment,
        timeMark,
        inputs,
    } = value;

    const typeColor = TYPE_COLORS[type as keyof typeof TYPE_COLORS];
    const typeLabel = TYPE_LABELS[type as keyof typeof TYPE_LABELS];

    return (
        <MemoryCard>
            <MemoryHeader>
                <div>
                    <TypeBadge color={typeColor}>{typeLabel}</TypeBadge>
                    <ImportanceBadge>
                        Importance:
                        {' '}
                        {importance}
                    </ImportanceBadge>
                </div>
                <TimeStamp>{formatTimestamp(timeMark)}</TimeStamp>
            </MemoryHeader>

            <MemoryContent>{content}</MemoryContent>

            <MemoryFooter>
                {segment && (
                    <FooterItem>
                        <SegmentTag>{segment}</SegmentTag>
                    </FooterItem>
                )}

                {source && (
                    <FooterItem>
                        <SourceBadge isExternal={source.type === 'external'}>
                            {source.type}
                        </SourceBadge>
                    </FooterItem>
                )}

                {inputs && inputs.length > 0 && (
                    <FooterItem>
                        Inputs:
                        {' '}
                        {inputs.join(', ')}
                    </FooterItem>
                )}
            </MemoryFooter>
        </MemoryCard>
    );
};

export default Memory;
