import {
    CardContainer, Value, TrendIndicator, Subtitle,
} from './styled';

interface StatCardProps {
    title: string;
    value: string | number;
    subtitle?: string;
    trend?: {
        value: number;
        label?: string;
    };
}

const StatCard = ({
    title, value, subtitle, trend,
}: StatCardProps) => (
    <CardContainer>
        <Value>
            {value}
            {trend && (
                <TrendIndicator isPositive={trend.value >= 0}>
                    {trend.value >= 0 ? '+' : '-'}
                    {Math.abs(trend.value)}
                    %
                </TrendIndicator>
            )}
        </Value>
        <Subtitle>{subtitle || title.toUpperCase()}</Subtitle>
    </CardContainer>
);

export default StatCard;
