import {
    ReactNode,
    useCallback, useMemo, useRef, useState,
} from 'react';
import { useOutsideClick, useWindowResize } from '@rainbow-modules/hooks';
import { isEmpty } from '@rainbow-modules/validation';
import { InternalOverlay } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import Close from 'components/icons/close';
import Filter from 'components/icons/filter';
import {
    DropDown, Header, StyledButton, StyledCheckBoxGroup,
} from './styled';
import positionResolver from './resolver';

interface TypeFilterProps {
    value?: string[] | null;
    onChange?: (values: string[] | null) => void;
    borderRadius?: 'square' | 'semi-square' | 'semi-rounded' | 'rounded';
}

const typeOptions = [
    {
        name: 'llm',
        label: 'LLM',
        value: 'llm',
    },
    {
        name: 'agent',
        label: 'Agent',
        value: 'agent',
    },
];

const ResponderTypeFilter = ({
    value: valueInProps = [],
    onChange = () => {},
    borderRadius = 'rounded',
}: TypeFilterProps) => {
    const buttonRef = useRef();
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = useState(false);

    useWindowResize(() => setOpen(false), isOpen);
    useOutsideClick(dropdownRef, () => setOpen(false), isOpen);

    const toggle = useCallback(() => setOpen(!isOpen), [isOpen]);

    const close = useCallback(() => setOpen(false), []);

    const selectedTypes = useMemo(() => {
        if (!valueInProps || isEmpty(valueInProps)) return 'All';
        const values = valueInProps.map(
            (typeName) => typeOptions.find(
                (typeOption) => typeOption.value === typeName,
            )?.label ?? typeName,
        ).map((typeName) => <b>{typeName}</b>);
        if (valueInProps.length < 3) {
            return values.reduce(
                (acc, value, index) => {
                    if (index > 0) {
                        return [...acc, ', ', value];
                    }

                    return [value];
                },
                [] as ReactNode[],
            );
        }
        return (
            <>
                {values[0]}
                {'  and '}
                {valueInProps.length - 1}
                {' '}
                others
            </>
        );
    }, [valueInProps]);

    const value = useMemo(
        () => {
            if (valueInProps) return valueInProps;
            return [];
        },
        [valueInProps],
    );

    const handleOnChange = useCallback(
        (newValues: string[]) => {
            onChange(newValues);
        },
        [onChange],
    );

    return (
        <>
            <StyledButton
                ref={buttonRef}
                variant="base"
                onClick={toggle}
                borderRadius={borderRadius}
            >
                <Filter className="rainbow-m-right_xx-small" />
                <span className="rainbow-m-right_xx-small">Type:</span>
                <span>{selectedTypes}</span>
            </StyledButton>
            <InternalOverlay
                isVisible={isOpen}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                triggerElementRef={() => buttonRef.current.htmlElementRef}
                positionResolver={positionResolver}
            >
                <DropDown ref={dropdownRef}>
                    <Header>
                        Filter by type
                        <ButtonIcon
                            icon={<Close />}
                            size="small"
                            onClick={close}
                        />
                    </Header>
                    <StyledCheckBoxGroup
                        hideLabel
                        options={typeOptions}
                        value={value}
                        onChange={handleOnChange}
                    />
                </DropDown>
            </InternalOverlay>
        </>
    );
};

export default ResponderTypeFilter;
