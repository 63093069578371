import styled, { css } from 'styled-components';
import Badge from 'components/Badge';
import {
    RED_100, RED_500, WARNING, WHITE,
} from '../../../../../constants';

export const BadgeContainer = styled.div`
    display: flex;
    justify-content: stretch;
    width: 100%;
    height: 100%;
`;

export const StyledBadge = styled(Badge)`
    min-width: 50px;
    width: 100%;
    padding: 0;
    font-size: 0.75rem;
    font-weight: normal;

    ${(props) => props.variant === 'error' && css`
            color: ${RED_100};
            background-color: ${RED_500};
    `}
    
    ${(props) => props.variant === 'warning' && css`
        color: ${WHITE};
        background-color: ${WARNING};
    `}
`;
