import { useSearchParams } from 'react-router-dom';
import { useMemo, useCallback } from 'react';
import { MemoryFilter, DEFAULT_FILTER } from '../types';

/**
 * Hook to read memory filter values from URL query parameters
 * @returns {MemoryFilter} The filter object constructed from URL parameters
 */
const useQueryFilters = (): MemoryFilter => {
    const [searchParams] = useSearchParams();

    // Parse the types parameter (comma-separated list)
    const getTypesFromParams = useCallback((): MemoryFilter['types'] => {
        const typesParam = searchParams.get('types');
        if (!typesParam) return DEFAULT_FILTER.types;

        return typesParam
            .split(',')
            .filter(
                (type) => ['observation', 'reflection', 'plan', 'fact'].includes(type),
            ) as MemoryFilter['types'];
    }, [searchParams]);

    // Parse numeric parameters with fallbacks to defaults
    const getNumericParam = useCallback((key: string, defaultValue: number): number => {
        const value = searchParams.get(key);
        if (value === null) return defaultValue;

        const parsed = parseFloat(value);
        return Number.isNaN(parsed) ? defaultValue : parsed;
    }, [searchParams]);

    // Parse relative time parameters
    const getRelativeTimeFromParams = useCallback(() => {
        const value = getNumericParam('releative-time-value', DEFAULT_FILTER.relativeTime?.value ?? 1);
        const unitParam = searchParams.get('releative-time-unit');
        const unit = (unitParam && ['days', 'weeks', 'months', 'years'].includes(unitParam))
            ? unitParam as 'days' | 'weeks' | 'months' | 'years'
            : DEFAULT_FILTER.relativeTime?.unit ?? 'months';

        return { value, unit };
    }, [getNumericParam, searchParams]);

    // Construct the filter object from URL parameters
    return useMemo<MemoryFilter>(() => ({
        customerId: searchParams.get('customer') || DEFAULT_FILTER.customerId,
        types: getTypesFromParams(),
        minImportance: getNumericParam('minImportance', DEFAULT_FILTER.minImportance ?? 0),
        maxImportance: getNumericParam('maxImportance', DEFAULT_FILTER.maxImportance ?? 10),
        relativeTime: getRelativeTimeFromParams(),
        query: searchParams.get('query') || DEFAULT_FILTER.query,
        segmentPrefix: searchParams.get('segment') || DEFAULT_FILTER.segmentPrefix,
        limit: getNumericParam('limit', DEFAULT_FILTER?.limit ?? 1000),
    }), [getNumericParam, getRelativeTimeFromParams, getTypesFromParams, searchParams]);
};

export default useQueryFilters;
