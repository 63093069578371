import { useConnectModal } from '@rainbow-modules/hooks';
import FormModal from 'components/FormModal';
import { Customer } from 'data/firestore/agent/customer/types';
import { ItemUser } from 'data/firestore/globals';
import { Pipeline } from 'data/firestore/agent/pipeline/types';
import { Stage } from 'data/firestore/agent/pipeline/stage/types';
import { ADD_EDIT_OPPORTUNITY_MODAL } from '../../constants';
import Fields from './fields';

const mapFormValues = (values: Record<string, unknown>) => {
    const {
        customer, pipeline, stageId, owner, rate, score, amount, ...rest
    } = values;

    return {
        ...rest,
        pipelineId: (pipeline as Pipeline).id,
        stageId: typeof stageId === 'string' ? stageId : (stageId as Stage).id,
        customerId: (customer as Customer).id,
        ownerId: (owner as ItemUser)?.uid || null,
        score: rate === 'yes' ? Number(score) : null,
        amount: typeof amount === 'number' ? amount * 100 : null,
    };
};

const CreateEditOpportunityForm = () => {
    const { onSubmit, ...connectedModalProps } = useConnectModal(ADD_EDIT_OPPORTUNITY_MODAL);

    const onSubmitMiddleware = (formValues: Record<string, unknown>) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (onSubmit) onSubmit(mapFormValues(formValues));
    };

    return (
        <FormModal
            {...connectedModalProps}
            borderRadius="semi-square"
            size="medium"
            fields={Fields}
            onSubmit={onSubmitMiddleware}
        />
    );
};

export default CreateEditOpportunityForm;
