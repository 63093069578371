import { useParams, useNavigate } from 'react-router-dom';
import ButtonIcon from 'components/ButtonIcon';
import { StyledBreadcrumb, StyledBreadcrumbs } from 'components/Breadcrumbs';
import ArrowLeftIcon from 'components/icons/arrowLeft';
import useWebhook from 'data/firestore/webhooks/use';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import { Container, HeaderContainer, StyledCard } from './styled';
import WebhookDetailedInfo from './Details';
import WebhookLogs from './Logs';

const WebhookDetails = () => {
    const { agentId = '', webhookId = '' } = useParams();
    const navigate = useNavigate();
    const navigateWithQuery = useNavigateWithQuery();

    const { data, isLoading } = useWebhook(webhookId);

    return (
        <Container>
            <HeaderContainer>
                <ButtonIcon
                    icon={<ArrowLeftIcon />}
                    onClick={() => navigateWithQuery(`/${agentId}/developer/webhooks2`)}
                    size="small"
                />
                <StyledBreadcrumbs>
                    <StyledBreadcrumb
                        label="Webhooks"
                        onClick={() => navigate(`/${agentId}/developer/webhooks2`)}
                    />
                    <StyledBreadcrumb label="Webhook Details" />
                </StyledBreadcrumbs>
            </HeaderContainer>
            <StyledCard marginBottom="1.5rem">
                <WebhookDetailedInfo data={data} isLoading={isLoading} />
            </StyledCard>
            <StyledCard>
                <WebhookLogs />
            </StyledCard>
        </Container>
    );
};

export default WebhookDetails;
