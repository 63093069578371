import { ReactNode } from 'react';
import Button from 'components/Button';
import { Footer } from './styled';

interface FormFooterProps {
    formId?: string;
    submitButtonLabel?: ReactNode;
    submitDisabled?: boolean;
    onCancelClick?: () => void;
}

const FormFooter = ({
    formId,
    submitButtonLabel = 'Add',
    submitDisabled = false,
    onCancelClick = () => {},
}: FormFooterProps) => (
    <Footer>
        <Button
            label="Close"
            variant="outline-brand"
            borderRadius="semi-square"
            className="rainbow-m-right_medium"
            onClick={onCancelClick}
        />
        <Button
            label={submitButtonLabel}
            variant="brand"
            form={formId}
            type="submit"
            borderRadius="semi-square"
            disabled={submitDisabled}
        />
    </Footer>
);

export default FormFooter;
