import {
  doc,
  onSnapshot,
  FirestoreError,
  Unsubscribe,
} from "firebase/firestore";
import { db } from "../../../firebase";
import {
  EntityGet,
  EntityServerGet,
  OnSnapshotDocService,
} from "../../../types";
import { Stage } from "./types";
import { transformMetadata, processTimestampFields } from "../../../utils";

export type StageOnSnapshotDocService<T> = (
  agentId: string,
  pipelineId: string,
  id: string,
  callback: (doc: T) => void,
  onError?: (error: FirestoreError) => void
) => Unsubscribe;

const onSnapshotDoc: StageOnSnapshotDocService<EntityGet<Stage>> = (
  agentId,
  pipelineId,
  id,
  callback,
  onError
) => {
  const docRef = doc(
    db,
    `/agent/${agentId}/pipeline/${pipelineId}/stage/${id}`
  );
  return onSnapshot(
    docRef,
    (docSnap) => {
      if (docSnap.exists()) {
        callback(
          processTimestampFields(
            transformMetadata(
              {
                ...docSnap.data(),
                id: docSnap.id,
              } as EntityServerGet<Stage>,
              docSnap.metadata
            )
          ) as EntityGet<Stage>
        );
      }
    },
    onError
  );
};

export default onSnapshotDoc;
