import { PositionResolverOpts } from 'react-rainbow-components/components/InternalOverlay';

const positionResolver = (opts: PositionResolverOpts) => {
    const { trigger } = opts;

    const desiredPos = {
        top: trigger.leftUpAnchor.y + trigger.height + 5,
        left: trigger.leftUpAnchor.x,
    };

    return desiredPos;
};

export default positionResolver;
