import {
    ChangeEvent, useCallback, useMemo, useState,
} from 'react';
import { filterByFields } from '@rainbow-modules/listview';
import { useReduxForm } from '@rainbow-modules/hooks';
import { Input, RenderIf } from 'react-rainbow-components';
import { EventsSelectInputProps } from './types';
import { Container, StyledCheckBoxGroup, StyledInputSearch } from './styled';
import EventElement from './label';
import SelectAllEventsLabel from './selectAllLabel';

const events = [
    {
        name: 'call.processed',
        description: 'Call processed event',
    },
    {
        name: 'opportunity.created',
        description: 'A new opportunity was created.',
    },
    {
        name: 'opportunity.updated',
        description: 'An existing opportunity has been updated.',
    },
    {
        name: 'opportunity.removed',
        description: 'An opportunity was removed.',
    },
    {
        name: 'opportunity.won',
        description: 'An opportunity was won.',
    },
    {
        name: 'opportunity.lost',
        description: 'An opportunity was lost.',
    },
    {
        name: 'opportunity.stuck',
        description: 'An opportunity was stuck and did not progress.',
    },
    {
        name: 'reminder.due',
        description: 'A reminder is due.',
    },
];

const EventsSelectInput = ({
    enableSearch = false,
    ...props
}: EventsSelectInputProps) => {
    const {
        value: valueInProps = [],
        onChange = () => {},
        ...rest
    } = useReduxForm(props);
    const [search, setSearch] = useState<string | undefined>('');

    const isAllOptionsChecked = valueInProps.length === events.length;

    const filteredData = useMemo(
        () => filterByFields({
            data: events,
            query: search || '',
            fields: ['name'],
        }),
        [search],
    );

    const optionsList = useMemo(() => filteredData.map((event) => ({
        value: event.name,
        label: <EventElement name={event.name} description={event.description} />,
    })), [filteredData]);

    const handleSelectAll = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        onChange(checked ? events.map(({ name }) => name) : []);
    }, [onChange]);

    return (
        <Container>
            <RenderIf isTrue={enableSearch}>
                <StyledInputSearch variant="realtime" placeholder="Search..." value={search} onChange={setSearch} />
            </RenderIf>
            <RenderIf isTrue={!search}>
                <Input
                    type="checkbox"
                    label={<SelectAllEventsLabel selectedAmount={valueInProps.length} />}
                    checked={isAllOptionsChecked}
                    onChange={handleSelectAll}
                />
            </RenderIf>
            <StyledCheckBoxGroup
                {...rest}
                hideLabel
                options={optionsList}
                value={valueInProps}
                onChange={onChange}
            />
        </Container>
    );
};

export default EventsSelectInput;
