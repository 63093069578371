/* eslint-disable react/no-unused-prop-types */
import {
    ReactNode, useCallback, useMemo,
} from 'react';
import { query, where } from 'firebase/firestore';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option, RenderIf } from 'react-rainbow-components';
import Setting from 'components/icons/setting';
import usePipelines from 'data/firestore/agent/pipeline/useCollection';
import { Pipeline } from 'data/firestore/agent/pipeline/types';
import { OptionDivider, StyledOption, StyledPicklist } from './styled';

interface PipelineSwitcherProps {
    id?: string;
    className?: string;
    agentId?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    value?: string | PicklistValue;
    onChange?: (value: string) => void;
    hideManageAction?: boolean;
}

const PipelineSwitcher = (props: PipelineSwitcherProps) => {
    const {
        value: valueInProps,
        agentId = '',
        hideManageAction = false,
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
    } = useReduxForm(props);

    const { data: pipelines = [], isLoading } = usePipelines(agentId, {
        listQueryFn: (ref) => query(ref, where('removed', '==', false)),
        disabled: !agentId,
        track: [agentId],
    });

    const pickListvalue = useMemo(() => {
        if (!isLoading) {
            if (valueInProps) {
                const valueId = (
                    typeof valueInProps === 'string'
                        ? valueInProps
                        : (valueInProps as Pipeline).id
                );
                const found = pipelines.find((pipeline) => pipeline.id === valueId);
                if (found) {
                    return {
                        label: found.name,
                        name: found.id,
                        value: found,
                        searchableText: found.name,
                    };
                }
            }
        }
        return undefined;
    }, [isLoading, pipelines, valueInProps]);

    const handleOnChange = useCallback(
        (newValue: PicklistValue) => {
            const value = typeof newValue.value === 'string' ? newValue.value : newValue.value.id;
            onChange(value);
        },
        [onChange],
    );

    const pickListOptions = useMemo(
        () => {
            const valueId = (
                typeof valueInProps === 'string'
                    ? valueInProps
                    : (valueInProps as Pipeline)?.id
            );
            return pipelines.map(
                (pipeline) => (
                    <StyledOption
                        key={`pipeline__${pipeline.id}`}
                        label={pipeline.name}
                        name={pipeline.id}
                        value={pipeline}
                        isBuiltIn={!pipeline.removable}
                        isSelected={valueId === pipeline.id}
                        searchableText={pipeline.name}
                    />
                ),
            );
        },
        [pipelines, valueInProps],
    );

    const isSelectedBuiltIn = typeof pickListvalue?.value?.removable === 'boolean' && !(pickListvalue?.value as Pipeline)?.removable;

    return (
        <StyledPicklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            isLoading={isLoading}
            value={pickListvalue}
            onChange={handleOnChange}
            error={error}
            isSelectedBuiltIn={isSelectedBuiltIn}
        >
            {pickListOptions}
            <RenderIf isTrue={!hideManageAction}>
                <OptionDivider variant="header" />
                <Option label="Manage Pipelines" name="manage" value="manage" icon={<Setting />} />
            </RenderIf>
        </StyledPicklist>
    );
};

export default PipelineSwitcher;
